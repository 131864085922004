import React from "react";

function InputSingle(props) {
  const { label, name, value, setValue, index } = props;
  const type = props.type ?? "text";
  const required = props.isRequired ?? false;
  const placeholder = props.placeholder ?? '';
  const margin = props.margin ?? "m-0";
  const border = props.border ?? "border-gray-300";
  const text = props.text ?? "sm:text-sm";
  const labelTextStyle = props.labelTextStyle ?? "text-accent";
  const isLabelHidden = props.isLabelHidden ?? false;
  const isDisabled = props.isDisabled ?? false;
  const isAutoFocus = props.isAutoFocus ?? false;
  const exampleText = props.exampleText ?? "";
  

  const handleSetValue = (e) => {
    if (index !== undefined) {
      setValue(e.target.value, index);
    } else {
      setValue(e.target.value);
    }
  };

  return (
    <div className={margin}>
      {!isLabelHidden && (
        <label
          htmlFor={name}
          className={`block text-sm font-medium text-left pl-2 ${labelTextStyle}`}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      {exampleText !== "" && (
        <p className="text-xs italic">{exampleText}</p>
      )}
      <div className="mt-1">
        <input
          disabled={isDisabled}
          aria-label={label}
          placeholder={placeholder}
          required={required}
          autoFocus={isAutoFocus}
          value={value}
          onChange={(e) => handleSetValue(e)}
          type={type}
          name={name}
          id={name}
          className={`block w-full rounded-md focus:border-accent-100 focus:ring-accent-100 ${border} ${text}`}
        />
      </div>
    </div>
  );
}

export default InputSingle;
