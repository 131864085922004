import { useNavigate } from "react-router-dom";
import Button from "../buttons/Button";
import convertTimestampToFormattedDate from "../../utils/getDateTime";
import Pill from "../pills/Pill";

export default function TableProjects(props) {
  const { heading, description, handleAdd, action, handleSelect } = props;
  const items = props.projects ?? [];
  const navigate = useNavigate();

  const handleGoToProject = (projectId) => {
    const project = items.find((item) => item.id === projectId);
    navigate(`/scope/${projectId}/${project.workspaceId}`);
  };
  const getProjectStatus = (item) => { 
    if(item.scopeStatus?.state === 'pending'){
      return <Pill fullWidth={false} type={"secondary"} message={`Sent ${convertTimestampToFormattedDate(item.scopeStatus.timestamp)}`}/>;
    }

    if(item.scopeStatus?.state === 'comment-not-approved'){
      return <Pill fullWidth={false} type={"tertiary"} message={`Comment ${convertTimestampToFormattedDate(item.scopeStatus.timestamp)}`}/>
    }

    if(item.scopeStatus?.state === 'approved-no-comment'){
      return <Pill fullWidth={false} type={"success"} message={`Approved ${convertTimestampToFormattedDate(item.scopeStatus.timestamp)}`}  /> ;
    }
    return <Pill fullWidth={false} type={"tertiary"} message={'Draft'} /> 
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8 my-10">
      {heading && (
        <div className="sm:flex sm:items-center text-left">
          <div className="sm:flex-auto">
            <h1 className="text-xl lg:text-2xl font-semibold text-gray-900">
              {heading}
            </h1>
            {description && (
              <p className="mt-2 text-sm text-gray-700">{description}</p>
            )}
          </div>
          {action && handleAdd && (
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <Button
                bg={"tertiary"}
                handleClick={handleAdd}
                message={action}
              />
            </div>
          )}
        </div>
      )}
      <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Project
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
              >
                Client
              </th>
              {/*}
              <th
                scope="col"
                className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Main Contact
              </th> */}
              <th
                scope="col"
                className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Quote
              </th>
              <th
                scope="col"
                className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Current Budget
              </th>
              <th
                scope="col"
                className="hidden sm:table-cell px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                STATUS
              </th>
              <th
                scope="col"
                className="relative py-3.5 pl-3 pr-4 sm:pr-6 justify-center flex"
              >
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {items.map((item) => {
              return (
                <tr key={item.id}>
                  <td
                    role="button"
                    aria-label={`Go to ${item.title}`}
                    onClick={() => handleGoToProject(item.id, "scope")}
                    className="cursor-pointer w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 text-left"
                  >
                    {item.title}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Client Name</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {item.clientName}
                      </dd>
                      <dt className="sr-only sm:hidden">Main Contact</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {item.leadName}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell text-left">
                    {item.clientName}
                  </td>
                  {/*
                  <td className="hidden px-3 py-4 text-sm text-gray-500 text-left sm:table-cell">
                    {item.leadName}
                  </td>
                  */}
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell text-left">
                    {item.quote} {item.quote !== item.max && `- ${item.max}`}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 text-left sm:table-cell">
                    {item.budget}
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 text-left sm:table-cell">
                    {getProjectStatus(item)}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-center text-sm font-medium sm:pr-6">
                    <button
                      className="cursor-pointer"
                      onClick={() => handleSelect(item.id, "edit")}
                    >
                      <span className="text-primary-700 hover:text-primary-800">
                        Edit
                      </span>
                      {/*<CogIcon className="h-4 w-4 text-gray-500" />*/}
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
