import { db, firebase } from "../firebase";

export function createValueAdd(entityObj, obj, userInfo) {
  const { workspaceId, projectId } = entityObj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
    name: userInfo.name,
  };
  const { title, description, cost, ref, max } = obj;
  const labels = obj.labels ?? [];
  const attachmentLink = obj.attachmentLink ?? "";
  const isRecurring = obj.isRecurring ?? false;
  //Keep in sync with template service
  return db.collection("valueAdds").add({
    title,
    description,
    cost,
    max: max ?? 0,
    ref,
    projectId,
    workspaceId,
    attachmentLink,
    labels,
    isRecurring,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
    owner: creator,
    addedBy: creator,
  });
}

export function createScopeValueAdd(obj, userInfo) {
  const creator = {
    uid: userInfo.uid,
    id: userInfo.id,
    name: userInfo.name,
  };

  return db.collection("scopeVAs").add({
    ...obj,
    isCancelled: false,
    requested: firebase.firestore.FieldValue.serverTimestamp(),
    requestedTimestamp: Date.now(),
    addedBy: creator,
  });
}
