import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InputCombo(props) {
  const { query, setQuery, value, options, handleSelect } = props;
  const isCustomPossible = props.isCustomPossible ?? true;

  const filteredItems =
    query === ""
      ? options
      : options.filter((item) => {
          return item.title?.toLowerCase().includes(query.toLowerCase());
        });

  if (!options.some((item) => item.title === query) && query !== "" && isCustomPossible) {
    filteredItems.push({ id: 0, title: query });
  }

  const labelTextStyle = props.labelTextStyle ?? "";
  const label = props.label ?? "";

  return (
    <Combobox as="div" value={value} onChange={handleSelect}>
      {label && (
        <Combobox.Label
          className={`block text-sm text-left ml-2 font-medium text-gray-700 ${labelTextStyle}`}
        >
          {label}
        </Combobox.Label>
      )}
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-accent-100 focus:outline-none focus:ring-1 focus:ring-accent-100 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(value) => value?.title}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map((item) => (
              <Combobox.Option
                key={item.id}
                value={item}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-accent-100/20 text-accent" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {item.title}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-primary"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
}
