export function getThreeDigitNumber(num) {
  if (num < 10) {
    return `00${num}`;
  } else if (num < 100) {
    return `0${num}`;
  } else {
    return num;
  }
}

export function getTwoDigitNumber(num) {
  if (num < 10) {
    return `0${num}`;
  } else {
    return num;
  }
}

export function getLetterFromNumber(num) {
  return String.fromCharCode(97 + num).toUpperCase();
}
