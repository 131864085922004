import InputSelection from "../../components/inputs/InputSelection";
import Button from "../../components/buttons/Button";
import TemplateFrame from "../../images/template_crop.png";
import ScratchFrame from "../../images/scratch_crop.png";
import { ActionHeading } from "../../components/tables/TableCreateScope";
import { useContext, useState } from "react";
import { FunctionContext } from "../../store/function-context";

export const ScopeStartOption = ({
  templates,
  projectId,
  handleCreateScopeFromTemplate,
}) => {
  const templateOptions = templates.map((template) => ({
    value: template.id,
    label: template.title,
  }));

  const { handleUpdateItem } = useContext(FunctionContext);

  const handleCreate = (templateId) => {
    handleCreateScopeFromTemplate(templateId);
  };

  const handleStartFromScratch = () => {
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "isFromScratch", value: true };
    const feedbackObj = { message: "Project created from scratch" };
    handleUpdateItem(itemObj, obj, feedbackObj);
  };

  return (
    <div className="min-h-screen pt-10 px-20 bg-primary-100">
      <ActionHeading
        title={"Create your project scope"}
        description={
          "Choose whether you want to start from scratch or if you’d like to use an existing template. Any project you create can be saved as a template to save you time for your future projects."
        }
      />
      <div className="flex md:space-x-5 flex-col md:flex-row mt-10">
        <StartFromScratchButton handleCreate={handleStartFromScratch} />
        <CreateTemplateButton
          options={templateOptions}
          handleCreate={handleCreate}
        />
      </div>
    </div>
  );
};

const CreateTemplateButton = ({ options, handleCreate }) => {
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [template, setTemplate] = useState(
    options.length > 0 ? options[0].value : ""
  );
  const handleToggle = () => {
    if (options.length > 0) {
      setIsTemplateOpen(!isTemplateOpen);
    }
  };

  return (
    <div className="flex flex-1 my-2 justify-center items-center cursor-pointer rounded-2xl shadow bg-white hover:bg-tertiary-50">
      <div className="text-center flex items-center flex-1 h-full">
        {!isTemplateOpen ? (
          <button onClick={() => handleToggle()} className="flex-1 h-full">
            <p className="text-accent font-medium text-lg">
              Use a saved template
            </p>
            {options.length < 1 && (
              <p className="text-red-400 uppercase text-xs">
                You have no saved templates
              </p>
            )}
          </button>
        ) : (
          <div className="flex-1 flex flex-col items-center px-1">
            <div className="space-y-1">
              <InputSelection
                value={template}
                setValue={setTemplate}
                label="Use template"
                options={options}
              />
              <Button
                handleClick={() => handleCreate(template)}
                message="Create template"
              />
            </div>
          </div>
        )}
        <button onClick={() => handleToggle()}>
          <img
            className="w-72"
            alt="Create from template"
            src={TemplateFrame}
          />
        </button>
      </div>
    </div>
  );
};

const StartFromScratchButton = ({ handleCreate }) => {
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  return (
    <div className="flex my-2 flex-1 justify-center items-center cursor-pointer rounded-2xl shadow bg-white hover:bg-accent-50">
      <div className="text-center flex items-center flex-1 h-full">
        {!isTemplateOpen ? (
          <button
            onClick={() => setIsTemplateOpen(!isTemplateOpen)}
            className="flex-1 h-full"
          >
            <p className="text-accent font-medium text-lg">
              Start from scratch
            </p>
          </button>
        ) : (
          <div className="flex-1 flex flex-col items-center px-1">
            <Button
              handleClick={() => handleCreate()}
              message="Confirm start from scratch"
            />
          </div>
        )}
        <button onClick={() => setIsTemplateOpen(!isTemplateOpen)}>
          <img
            className="w-72 max-w-2/3"
            alt="Start from scratch"
            src={ScratchFrame}
          />
        </button>
      </div>
    </div>
  );
};
