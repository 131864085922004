import React from "react";

export default function ScopeDashboardLoading(props) {
  return (
    <div className="min-h-screen flex w-full">
      <div className="flex-1 bg-primary-100 min-h-screen border-r-1 border-primary"></div>
      <div className="flex-1 min-h-screen"></div>
    </div>
  );
}
