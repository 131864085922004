import { createContext, useState } from "react";

export const ExampleContext = createContext({
  exampleId: false,
  setExampleId: (id) => {},
  imageURL: "",
});

export function ExampleContextProvider(props) {
  const [exampleId, setExampleId] = useState(false);

  function getImageURL(id) {
    switch (id) {
      case "project-scope":
        return "https://i.ibb.co/YT8kFfb/Project-scope.jpg";
      case "va-library":
        return "https://i.ibb.co/28kP8nn/Upsell-library.jpg";
      default:
        return null;
    }
  }

  const contextValue = {
    exampleId,
    setExampleId,
    imageURL: getImageURL(exampleId),
  };

  return (
    <ExampleContext.Provider value={contextValue}>
      {props.children}
    </ExampleContext.Provider>
  );
}
