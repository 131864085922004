import React, { useContext } from "react";
import TablePeople from "../../components/tables/TablePeople";
import { ModalContext } from "../../store/modal-context";

function ProjectSectionTeam(props) {
  const members = props.members ?? [];
  const { activeModal } = useContext(ModalContext);
  return (
    <section className="max-w-3xl w-full mx-auto px-2">
      <TablePeople
        title={"Our Team"}
        action={"Add Member"}
        handleAdd={() => activeModal("invite")}
        people={members}
      />
    </section>
  );
}

export default ProjectSectionTeam;
