import React, { useContext } from "react";
import { useState } from "react";
import ModalEditScopeApprovers from "../../components/modals/ModalEditScopeApprovers";
import TableClients from "../../components/tables/TableClients";
import { ModalContext } from "../../store/modal-context";

export default function WorkspaceSectionClients(props) {
  const { isWorkspaceOwner, setItemToDelete, handleUpdateItem } = props;
  let clients = props.clients ?? [];
  clients = clients.map((client) => ({
    title: client.title,
    id: client.id,
    mainContact: client.mainContact ?? client.scopeApprovers[0],
    approverCount: client.scopeApprovers.length,
  }));
  const [activeClientId, setActiveClientId] = useState(false);
  const { setActiveModal } = useContext(ModalContext);

  const handleDeleteClient = (obj) => {
    setItemToDelete(obj);
  };

  return (
    <>
      <ModalEditScopeApprovers
        handleClose={() => setActiveClientId(false)}
        isOpen={activeClientId !== false}
        handleUpdateItem={handleUpdateItem}
        id={activeClientId}
        client={
          activeClientId
            ? props.clients.find((client) => client.id === activeClientId)
            : []
        }
      />
      <section>
        <TableClients
          title={"Our Clients"}
          action={"Add Client"}
          handleAdd={() => setActiveModal("client")}
          handleDelete={handleDeleteClient}
          clients={clients}
          isWorkspaceOwner={isWorkspaceOwner}
          handleUpdateItem={handleUpdateItem}
        />
      </section>
    </>
  );
}
