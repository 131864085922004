import { db, firebase } from "../firebase";

export function createTemplate(entityObj, scopeObj, obj, userInfo) {
  const { workspaceId, scopeId } = entityObj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  //Clients are configured in project, when a user sends scope, each of them get an email
  const { phases, items, currency } = scopeObj;
  const { title } = obj;
  const description = obj.description ?? "";
  return db.collection("templates").add({
    title,
    description,
    phases,
    items,
    currency,
    attachments: [],
    workspaceId,
    scopeId,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
    owner: creator,
  });
}

export async function createFromTemplate(templateId, entityObj, userInfo) {
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  //Fetch template
  const template = await fetchTemplate(templateId);
  //Define phases, items, valueAdds, currency
  console.log(template);
  const { phases, items } = template;

  //Batch create phases
  const phaseRefs = await createBatchPhases(phases, entityObj, creator);
  //Create items, linked to correct phase
  await createBatchItems(items, entityObj, creator, phaseRefs);
  const projectId = entityObj.projectId;
  //Create valueAdds TODO
  //await createBatchValueAdds(valueAdds, entityObj, creator);
  return db.collection("projects").doc(projectId).update({
    isGeneratedFromTemplate: true,
    templateId,
  });
}

async function fetchTemplate(templateId) {
  try {
    const templateRef = await db.collection("templates").doc(templateId).get();
    const template = templateRef.data();
    return template;
  } catch (err) {
    console.error(err);
  }
}

export function createBatchPhases(phases, entityObj, creator) {
  let phaseRefs = [];
  const batch = db.batch();
  const { workspaceId, projectId } = entityObj;
  phases.forEach((phase) => {
    const { title, order, code } = phase;
    const description = phase.description ?? "";
    const docRef = db.collection("phases").doc();
    const doc = {
      title,
      description,
      workspaceId,
      projectId,
      order,
      code,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    };
    batch.set(docRef, doc);
    const phaseRef = {
      newPhaseId: docRef.id,
      originalPhaseId: phase.originalPhaseId,
    };
    phaseRefs.push(phaseRef);
  });
  return batch.commit().then(() => {
    return phaseRefs;
  });
}

export function createBatchItems(items, entityObj, creator, phaseRefs) {
  const batch = db.batch();
  const { workspaceId, projectId } = entityObj;
  items.forEach((item) => {
    const { title, description, cost, quantity, ref, order, max } = item;
    const docRef = db.collection("items").doc();
    const phaseId = phaseRefs.find(
      (ref) => item.phaseId === ref.originalPhaseId
    ).newPhaseId;
    const doc = {
      title,
      description,
      cost,
      max,
      quantity,
      attachments: [],
      variations: [],
      selectedVariationIndex: 0,
      order,
      ref,
      phaseId,
      projectId,
      workspaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      owner: creator,
    };
    batch.set(docRef, doc);
  });
  return batch.commit();
}

export function createBatchValueAdds(valueAdds, entityObj, creator, phaseRefs) {
  const batch = db.batch();
  const { workspaceId, projectId } = entityObj;
  valueAdds.forEach((item) => {
    const { title, description, cost, ref } = item;
    const docRef = db.collection("valueAdds").doc();
    const doc = {
      title,
      description,
      cost,
      ref,
      projectId,
      workspaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      owner: creator,
    };
    batch.set(docRef, doc);
  });
  return batch.commit();
}
