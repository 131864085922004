function Avatar(props) {
  const { name, message } = props;
  const firstLetter = name?.charAt(0).toUpperCase();
  const margin = props.margin || "";
  const size = props.size ?? "w-8 h-8";
  const handleClick = props.handleClick || (() => {});
  const label = props.label || "";
  return (
    <div className="flex items-center">
      <div
        onClick={handleClick}
        role={handleClick ? "button" : ""}
        aria-label={label}
        className={`rounded-full flex justify-center items-center shadow-sm bg-lightAccent text-accent font-medium ${margin} ${size}`}
      >
        {firstLetter}
      </div>
      {message && (
        <span className="text-white text-sm font-bold ml-2">{message}</span>
      )}
    </div>
  );
}

export default Avatar;
