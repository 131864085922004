import { useState } from "react";

export default function InputCheckboxSingle(props) {
  const { label, value, setValue, description } = props;
  const disabled = props.disabled ?? false;
  const name = props.name ?? label;
  const boxHeight = props.boxHeight ?? "h-4 w-4";
  const divHeight = props.divHeight ?? "h-5";
  const isLabelVisible = props.isLabelVisible ?? true;
  const fontSize = props.fontSize ?? "text-sm";
  const itemAlign = props.itemAlign ?? "items-start";
  const textColor = props.textColor ?? "text-gray-700";
  const color = props.color ?? "text-tertiary-500";
  const bg = props.bg ?? "bg-white";
  const hoverMessage = props.hoverMessage ?? "";
  const [hover, setHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ opacity: disabled ? 0.4 : 1, transition: "all .5s" }}
      className={`relative flex ${itemAlign}`}
    >
      <div className={`flex items-center ${divHeight}`}>
        <input
          disabled={disabled}
          checked={value}
          onChange={setValue}
          id={name}
          aria-describedby={name}
          aria-label={label}
          aria-checked={value}
          name={name}
          type="checkbox"
          className={`${boxHeight} rounded border-gray-300 focus:ring-tertiary-600 cursor-pointer ${color} ${bg}`}
        />
      </div>
      <div className={`ml-1 ${fontSize}`}>
        {isLabelVisible && (
          <label htmlFor={name} className={textColor}>
            {label}
          </label>
        )}
        {description && (
          <span id={`${name}-description`} className="text-gray-500">
            <span className="sr-only">{label} </span>
            {description}
          </span>
        )}
        {hover && hoverMessage && <HoverBanner hoverMessage={hoverMessage} />}
      </div>
    </div>
  );
}

export const HoverBanner = (props) => {
  const { hoverMessage } = props;
  const top = props.top ?? "-top-10";
  const left = props.left ?? "-left-20";
  return (
    <div
      className={`absolute bg-[#EAF0FF] text-[#5E7ED0] text-xs focus:ring-blue-300 uppercase text-gray-700 text-sm z-50 shadow p-2 ${top} ${left}`}
    >
      <span className="whitespace-nowrap">{hoverMessage}</span>
    </div>
  );
};
