import { CheckIcon } from "@heroicons/react/20/solid";

export const UpdateForm = ({ handleSubmit, setValue, value, type }) => {
  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-1 justify-end items-center font-bold pr-4 relative"
    >
      <label htmlFor="cost">
        <span className="sr-only">{`Update ${type}`}</span>
        <input
          value={value}
          onChange={(e) => setValue(e.target.value)}
          name="cost"
          type="number"
          className="text-right border rounded-lg border-gray-300"
        />
      </label>
      <button
        className="bg-accent py-1 px-5 rounded-lg absolute top-12"
        type="submit"
      >
        <CheckIcon className="w-4 text-white" />
        <span className="sr-only">{`Submit ${type}`}</span>
      </button>
    </form>
  );
};
