import { createContext, useState } from "react";

export const ModalContext = createContext({
  activeModal: false,
  setActiveModal: (bool) => {},
  confirmObj: false,
  setConfirmObj: (obj) => {},
  activeAddPhase: null,
  setActiveAddPhase: (phase) => {},
});

export function ModalContextProvider(props) {
  const [activeModal, setActiveModal] = useState(false);
  const [confirmObj, setConfirmObj] = useState(false);
  const [activeAddPhase, setActiveAddPhase] = useState(null);

  const contextValue = {
    activeModal,
    setActiveModal,
    confirmObj,
    setConfirmObj,
    activeAddPhase,
    setActiveAddPhase,
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {props.children}
    </ModalContext.Provider>
  );
}
