import React, { useContext, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import { db } from "../../firebase";
import { UserContext } from "../../store/user-context";
import { WorkspaceContext } from "../../store/workspace-context";
import WorkspaceDashboard from "./WorkspaceDashboard";
import { sortByDateDesc } from "../../utils/sortArray";

export default function WorkspaceDashboardData(props) {
  let { workspaceId, view } = useParams();
  const [isWorkspaceIdSet, setIsWorkspaceIdSet] = useState(false); //eslint-disable-line
  const [activeView, setActiveView] = useState(view);
  const userInfoCtx = useContext(UserContext);
  const { userInfo, authLoading } = userInfoCtx;
  let valueAdds = [];
  let scopeVAs = [];
  let requests = [];
  let clients = [];
  let items = [];
  let labels = [];
  const {
    privateData,
    workspace,
    setWorkspaceId,
    invites,
    isWorkspaceLoading,
    setIsWorkspaceLoading,
    isPrivateDataLoading,
    setIsPrivateDataLoading,
    isWorkspaceOwner,
  } = useContext(WorkspaceContext);

  useEffect(() => {
    if (workspaceId && !isWorkspaceIdSet) {
      setWorkspaceId(workspaceId);
      setIsWorkspaceIdSet(true);
    }
    //eslint-disable-next-line
  }, [workspaceId]);

  const [valueAddsValue, valueAddsLoading] = useCollection(
    db
      .collection("valueAdds")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (valueAddsValue && !valueAddsLoading) {
    //eslint-disable-next-line
    valueAddsValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "valueAdd";
      valueAdds.push(item);
    });
  }

  const [scopeVAsValue, scopeVAsLoading] = useCollection(
    db
      .collection("scopeVAs")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (scopeVAsValue && !scopeVAsLoading) {
    //eslint-disable-next-line
    scopeVAsValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "scopeVA";
      scopeVAs.push(item);
    });
  }

  const [requestValue, requestLoading] = useCollection(
    db
      .collection("requests")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (requestValue && !requestLoading) {
    //eslint-disable-next-line
    requestValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "request";
      requests.push(item);
    });
  }

  const [clientValue, clientLoading] = useCollection(
    db
      .collection("clients")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (clientValue && !clientLoading) {
    //eslint-disable-next-line
    clientValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "client";
      clients.push(item);
    });
  }

  const [itemValue, itemLoading] = useCollection(
    db
      .collection("items")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (itemValue && !itemLoading) {
    //eslint-disable-next-line
    itemValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = item.type ?? "item";
      items.push(item);
    });
  }

  const [labelValue, labelLoading] = useCollection(
    db
      .collection("labels")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (labelValue && !labelLoading) {
    //eslint-disable-next-line
    labelValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "label";
      labels.push(item);
    });
  }

  const isLoading =
    (authLoading ||
      isWorkspaceLoading ||
      isPrivateDataLoading ||
      valueAddsLoading ||
      scopeVAsLoading ||
      requestLoading ||
      itemLoading ||
      clientLoading ||
      labelLoading) &&
    userInfo;

  return isLoading ? (
    <PageShellLoading />
  ) : (
    <WorkspaceDashboard
      isLoading={isLoading}
      clients={clients}
      valueAdds={valueAdds}
      scopeVAs={scopeVAs}
      items={sortByDateDesc(items)}
      workspace={workspace}
      userInfo={userInfo}
      privateData={privateData}
      requests={requests}
      invites={invites}
      labels={labels}
      isWorkspaceOwner={isWorkspaceOwner}
      workspaceId={workspaceId}
      setWorkspaceId={setWorkspaceId}
      activeView={activeView}
      setActiveView={setActiveView}
      isWorkspaceLoading={isWorkspaceLoading}
      setIsWorkspaceLoading={setIsWorkspaceLoading}
      setIsPrivateDataLoading={setIsPrivateDataLoading}
    />
  );
}
