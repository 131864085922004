import { useState } from "react";
import Button from "../buttons/Button";
import { useContext } from "react";
import { ModalContext } from "../../store/modal-context";
import { FeedbackContext } from "../../store/feedback-context";
import templateIcon from "../../images/template-icon.svg";
import Avatar from "../avatars/Avatar";
import Tooltip from "../tooltips/Tooltip";
import DownloadIcon from "../../images/Download.svg";
import { getCSVData } from "../../utils/getCSVData";
import { CSVLink } from "react-csv";
import { BlackPortal } from "../portal/PortalProvider";



export const ClientBanner = ({
    project,
    phases,
    items,
    projectName,
    approvers,
    isContractor,
    currency,
}) => {
    const { setActiveModal } = useContext(ModalContext);
    const { autoSaveTime } = useContext(FeedbackContext);
    const formattedDate = new Date(autoSaveTime).toLocaleTimeString("en-UK", {
        hour: "2-digit",
        minute: "2-digit",
    });
    return (
        <>
            <div className="bg-primary-500 text-white py-2 font-bold text-lg drop-shadow fixed top-0 left-0 w-full pl-16 hidden md:flex items-center z-[1]">
                <div className="flex-1 text-center md:text-left ml-2">{projectName}</div>
                <div className="mr-2 flex items-center relative">
                    <ul className="flex items-center justify-center mr-2 space-x-1">
                        {approvers.map((item) => {
                            return (
                                <li className="shadow-sm">
                                    <Avatar size={"h-6 w-6 text-xs"} name={item.name} />
                                </li>
                            );
                        })}
                    </ul>
                    {isContractor && formattedDate !== "01:00" && (
                        <span className="text-xs text-white/80 mr-2">
                            Draft autosaved: {formattedDate}
                        </span>
                    )}
                    {isContractor && <TemplateButton handleClick={setActiveModal} />}
                    <Button
                        handleClick={() => setActiveModal("invite-scope")}
                        padding="px-6 py-1"
                        bg="white"
                        message="Share"
                    />
                </div>
            </div>
        </>
    );
};

const TemplateButton = ({ handleClick }) => {
    const [hover, setHover] = useState(false);
    return (
        <div
            onClick={() => handleClick("create-template")}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
            className="relative flex items-center"
        >
            <button>
                <img
                    alt="Save as template"
                    className="w-5 h-5 text-indigo-900 hover:text-indigo-800 mr-3 ml-1"
                    src={templateIcon}
                />
            </button>
            {hover && (
                <Tooltip message={"Save scope as a Template"} />
            )}
        </div>
    );
};


const CSVDownloadButton = ({ project, phases, items, currency }) => {
    const [hover, setHover] = useState(false);
    const { title } = project;

    return (
        <>
            {items.length !== 0 ? (
                <CSVLink
                    data={getCSVData(project, phases, items, currency.symbol)}
                    filename={`clientName-${title}`}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    className="relative flex items-center mr-3"
                >
                    <img className="w-5 h-5" src={DownloadIcon} alt="Download Icon" />
                    {hover && <Tooltip message={"Export as CSV"} />}
                </CSVLink>
            ) : (
                <button
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                    className="relative flex items-center mr-3"
                >
                    <svg
                        className="w-5 h-5 cursor-not-allowed"
                        width="31"
                        height="31"
                        viewBox="0 0 31 31"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillOpacity="0.5"
                            d="M15.5 23.25L5.8125 13.5625L8.525 10.7531L13.5625 15.7906V0H17.4375V15.7906L22.475 10.7531L25.1875 13.5625L15.5 23.25ZM3.875 31C2.80937 31 1.89714 30.6206 1.13828 29.8617C0.379427 29.1029 0 28.1906 0 27.125V21.3125H3.875V27.125H27.125V21.3125H31V27.125C31 28.1906 30.6206 29.1029 29.8617 29.8617C29.1029 30.6206 28.1906 31 27.125 31H3.875Z"
                            fill="#422163"
                        />
                    </svg>

                    {hover && <Tooltip message={"Export as CSV"} />}
                </button>
            )}
        </>
    );
};