import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { UserContext } from "./user-context";

export const WorkspaceContext = createContext({
  workspace: {},
  invites: [],
  isWorkspaceLoading: true,
  setIsWorkspaceLoading: () => {},
  setWorkspaceId: () => {},
  products: [],
  handleSetProducts: () => [],
  privateData: {},
  setPrivateData: () => {},
  isContractor: false,
  isWorkspaceOwner: false,
  workspacePrivateData: {},
});

export function WorkspaceContextProvider(props) {
  const [workspaceId, setWorkspaceId] = useState(false);
  const [workspace, setWorkspace] = useState({
    isFetched: false,
  });
  const [loadedWorkspaces, setLoadedWorkspaces] = useState([]);
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const [products, setProducts] = useState([]);
  const [privateData, setPrivateData] = useState(false);
  const [isContractor, setIsContractor] = useState(false);
  const [isWorkspaceOwner, setIsWorkspaceOwner] = useState(false);
  const [isPrivateDataLoading, setIsPrivateDataLoading] = useState(true);
  const { userInfo } = useContext(UserContext);

  const hasWorkspaceLoaded = loadedWorkspaces.some(
    (item) => item.id === workspaceId
  );

  const handleSetProducts = (docs) => {
    const data = docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.entityType = "product";
      return item;
    });
    setProducts(data);
  };

  const getCurrentWorkspace = async () => {
    if (!workspaceId) {
      return;
    }
    if (hasWorkspaceLoaded) {
      const loadedWorkspace = loadedWorkspaces.find(
        (item) => item.id === workspaceId
      );
      setWorkspace(loadedWorkspace);
      setIsWorkspaceLoading(false);
    } else {
      fetchWorkspace();
    }
  };

  const fetchWorkspace = async () => {
    if (!isWorkspaceLoading) {
      return;
    }
    try {
      db.collection("workspaces")
        .doc(workspaceId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          const workspaces = loadedWorkspaces.map((item) => [...item, data]);
          setLoadedWorkspaces(workspaces);
          setWorkspace(data);
          setIsWorkspaceLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspacePrivateData = async () => {
    if (!isPrivateDataLoading) {
      return;
    }
    try {
      if (!workspaceId) {
        return;
      }
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .onSnapshot((doc) => {
          const data = doc.data();
          console.log(data);
          data.id = doc.id;
          let workspaceInvites = [];
          const allWorksaceEmails = data.users.map((item) => item.email);
          workspaceInvites = data.invites?.filter(
            (item) =>
              item.status === "pending" &&
              !allWorksaceEmails.includes(item.invited)
          );
          setInvites(workspaceInvites);
          setPrivateData(data);
          if (userInfo) {
            const isUserContractor = data.users.some(
              (item) => item.userId === userInfo.id
            );
            setIsContractor(isUserContractor);
            const isWorkspaceOwner = workspace?.owner?.userId === userInfo.id;
            setIsWorkspaceOwner(isWorkspaceOwner);
          }
          setIsPrivateDataLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userInfo && isPrivateDataLoading) {
      fetchWorkspacePrivateData();
    }
    //eslint-disable-next-line
  }, [userInfo, isPrivateDataLoading]);

  useEffect(() => {
    getCurrentWorkspace();
    //eslint-disable-next-line
  }, [isWorkspaceLoading, workspaceId]);

  const isLoading = isWorkspaceLoading;

  const contextValue = {
    workspace,
    fetchedWorkspaceId: workspaceId,
    isWorkspaceLoading: isLoading,
    setIsWorkspaceLoading,
    invites,
    setWorkspaceId,
    products,
    handleSetProducts,
    privateData,
    isContractor,
    isWorkspaceOwner,
    workspacePrivateData: privateData,
  };

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {props.children}
    </WorkspaceContext.Provider>
  );
}
