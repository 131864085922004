import React, { useContext, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import Alert from "../../components/alerts/Alert";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ModalAddApprover from "../../components/modals/ModalAddApprover";
import Toast from "../../components/toasts/Toast";
import { db } from "../../firebase";
import { createScopeInvite } from "../../services/ScopeService";
import { sendEmailFromObj } from "../../services/SharedService";
import { FeedbackContext } from "../../store/feedback-context";
import { ModalContext } from "../../store/modal-context";
import { UserContext } from "../../store/user-context";
import { getEmailTemplate } from "../../utils/emailStrings";
import { sortArrayByOrder } from "../../utils/sortArray";
import ProjectSectionSettings from "../project-dashboard/ProjectSectionSettings";
import { EmailContext } from "../../store/email-context";
import ModalInviteScope from "../../components/modals/ModalInviteScope";
import PageShell from "../../components/page-shells/PageShellLoading";

function WorkspaceSectionProject(props) {
  const {
    projectId,
    handleUpdateItem,
    isProjectLoading,
    setIsProjectLoading,
    clients,
    newProjectClientId,
    setNewProjectClientId,
  } = props;
  const userInfoCtx = useContext(UserContext);
  const { userInfo, authLoading } = userInfoCtx;
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const [project, setProject] = useState(false);
  const [client, setClient] = useState(false);
  const [isClientLoading, setIsClientLoading] = useState(true);
  const {
    alert,
    setAlert,
    toast,
    setToast,
    isSpinning,
    setIsSpinning,
    isNavigating,
  } = useContext(FeedbackContext);
  const { emailInfo } = useContext(EmailContext);
  let phases = [];
  let items = [];
  let scopes = [];

  useEffect(() => {
    setNewProjectClientId(project.clientId);
    //eslint-disable-next-line
  }, [project]);

  const fetchProject = async () => {
    if (!isProjectLoading) {
      return;
    }
    try {
      db.collection("projects")
        .doc(projectId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setProject(data);
          setIsProjectLoading(false);
          if (data.clientId) {
            fetchClient(data.clientId);
          } else {
            setIsClientLoading(false);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchClient = async (clientId) => {
    if (!isClientLoading) {
      return;
    }
    try {
      db.collection("clients")
        .doc(clientId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setClient(data);
          setIsClientLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [itemValue, itemLoading] = useCollection(
    db
      .collection("items")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (itemValue && !itemLoading) {
    //eslint-disable-next-line
    itemValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = item.type ?? "item";
      items.push(item);
    });
  }

  const [phaseValue, phaseLoading] = useCollection(
    db
      .collection("phases")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (phaseValue && !phaseLoading) {
    //eslint-disable-next-line
    phaseValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "phase";
      phases.push(item);
    });
    phases = sortArrayByOrder(phases);
  }

  const [scopeValue, scopeLoading] = useCollection(
    db
      .collection("scopes")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (scopeValue && !scopeLoading) {
    //eslint-disable-next-line
    scopeValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "scope";
      scopes.push(item);
    });
  }

  useEffect(() => {
    fetchProject();
    //eslint-disable-next-line
  }, [isProjectLoading]);

  useEffect(() => {
    setIsSpinning(false);
    if (alert) {
      setTimeout(() => {
        setAlert("");
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast("");
      }, 3000);
    }
  }, [toast]);

  const isLoading =
    authLoading || isProjectLoading || phaseLoading || isClientLoading;

  const handleFeedback = (message, setClose) => {
    setToast(message);
    if (setClose) {
      setClose();
    }
    return;
  };

  const handleToggleApprover = (email) => {
    const approvers = project.scopeApprovers ?? [];
    let updatedApprovers = [];
    const isPresent = approvers.includes(email);
    if (isPresent) {
      updatedApprovers = approvers.filter((item) => item !== email);
    } else {
      updatedApprovers = [...approvers, email];
    }
    const itemObj = {
      collection: "projects",
      itemId: projectId,
    };
    const obj = {
      prop: "scopeApprovers",
      value: updatedApprovers,
    };
    return handleUpdateItem(itemObj, obj).then(() => {
      const emailInfoWEmail = emailInfo;
      emailInfoWEmail.email = email;
      const emailTemp = getEmailTemplate(
        "contractor",
        emailInfoWEmail,
        "ADDED_SCOPE_APPROVER"
      );
      if (isPresent) {
        return sendEmailFromObj(emailTemp);
      } else {
        return;
      }
    });
  };

  const handleInviteApprover = (email, valueObj) => {
    const obj = { email, project, valueObj };
    try {
      return createScopeInvite(userInfo, obj).then(() =>
        handleFeedback("Approver Invited")
      );
    } catch {
      setAlert("There has been an error with this request");
    }
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : isNavigating ? (
    <PageShell />
  ) : (
    <div className={`w-full min-h-screen max-w-7xl mx-auto`}>
      {toast && <Toast message={toast} />}
      {alert && <Alert isTop={false} message={alert} />}
      {isSpinning && <LoadingSpinner />}
      <ModalAddApprover
        isOpen={activeModal === "approver"}
        setIsOpen={setActiveModal}
        projectId={project.id}
        approvers={project.scopeApprovers}
        handleAdd={handleToggleApprover}
      />
      <ModalInviteScope
        setIsOpen={setActiveModal}
        isOpen={activeModal === "invite-scope"}
        approvers={project.scopeApprovers}
        invites={project.scopeInvites}
        projectId={project.id}
        handleInviteApprover={handleInviteApprover}
      />
      <>
        <ProjectSectionSettings
          project={project}
          clients={clients}
          clientId={newProjectClientId}
          setClientId={setNewProjectClientId}
        />
      </>
    </div>
  );
}

export default WorkspaceSectionProject;

/*
const ScopeOption = ({ projectId, scope, status }) => {
  const { id, title } = scope;
  const pillColor = status === "Active" ? "success" : "warning";
  return (
    <div>
      <Link to={`/scope/${id}/${projectId}`}>
        <div className="flex items-center px-5 mt-10 mb-2 mx-auto max-w-5xl cursor-pointer">
          <div>
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex justify-end items-center ml-3">
            <Pill type={pillColor} message={status} />
          </div>
          <div className="flex flex-1 justify-end items-center">
            <Link to={`/scope/${id}/${projectId}`}>
              <Button message="Go to full scope" />
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
};
*/
