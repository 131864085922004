import { db, firebase } from "../firebase";
import { getLetterFromNumber } from "../utils/getCode";

export function createPhase(entityObj, userInfo, obj) {
  const { title, description, order } = obj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  const { workspaceId, projectId } = entityObj;
  const code = getLetterFromNumber(order);
  //Keep batch phases in sync from TemplateService
  const phase = {
    title,
    description,
    workspaceId,
    projectId,
    order,
    code,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
  };
  return db
    .collection("phases")
    .add({
      ...phase,
    })
    .then((docRef) => {
      phase.id = docRef.id;
      return phase;
    });
}

export const createPhaseForScope = (entityObj, userInfo, obj, scopeObj) => {
  return createPhase(entityObj, userInfo, obj).then((phase) => {
    const updatedPhases = scopeObj.phases;
    phase.created = Date.now();
    updatedPhases.push({
      ...phase,
    });
    return db.collection("scopes").doc(scopeObj.id).update({
      phases: updatedPhases,
    });
  });
};
