import { db, firebase } from "../firebase";
import { URL } from "../utils/constants";
import { getInitialsFromEmail } from "../utils/getUserInfo";
import { createValueAdd } from "./ValueAddService";

export function createWorkspace(userInfo, workspaceObj) {
  const userId = userInfo.id;
  const userObj = {
    userId,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
    type: "Admin",
    tag: getInitialsFromEmail(userInfo.email),
  };

  const ownerObj = {
    userId,
    name: userInfo.name,
    email: userInfo.email,
    createdTimestamp: Date.now(),
  };
  const currency = workspaceObj.currency ?? "USD";
  const taxLevel = workspaceObj.taxLevel ?? 0;
  const doesExcludeTax = workspaceObj.doesExcludeTax ?? false;
  let title = workspaceObj.title ?? "";
  const description = workspaceObj.description ?? "";
  let docId;
  return db
    .collection("workspaces")
    .add({
      title,
      description,
      currency,
      taxLevel,
      doesExcludeTax,
      templates: [],
      projectOrderRefs: [],
      valueAdds: [],
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      owner: ownerObj,
      creator: ownerObj,
      biller: ownerObj,
    })
    .then((doc) => {
      docId = doc.id;
      return createWorkspacePrivateData(docId, [userObj]);
    })
    .then(() => {
      const entityObj = { workspaceId: docId, projectId: "" };
      const vaObj = {
        title: "Project setup",
        cost: 30,
        ref: "VA-01",
        description: "Initial fee for Scopey dashboard setup",
      };
      return createValueAdd(entityObj, vaObj, userInfo);
    })
    .then(() => {
      const workspaceObj = {
        title,
        workspaceId: docId,
        createdTimestamp: Date.now(),
      };
      return db
        .collection("users")
        .doc(userId)
        .update({
          workspaces: firebase.firestore.FieldValue.arrayUnion(workspaceObj),
        })
        .then(() => {
          return docId;
        });
    });
}

function createWorkspacePrivateData(workspaceId, users) {
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("private")
    .doc("data")
    .set({
      users,
      invites: [],
      projectInvites: [],
      projects: [],
      usersRemoved: [],
    });
}

export function completeWorkspaceOnboarding(userInfo, emails) {
  if (emails.length > 0) {
    for (let i = 0; i < emails.length; i++) {
      const workspaceObj = userInfo.workspaces[0];
      const email = emails[i];
      const inviteObj = {
        email,
        type: "Admin",
      };
      createWorkspaceInvite(workspaceObj, userInfo, inviteObj);
    }
  }
  return db.collection("users").doc(userInfo.id).update({
    isOnboarded: true,
    isOnboardedSkipped: true,
  });
}

export function completeApproverOnboarding(userInfo) {
  return db.collection("users").doc(userInfo.id).update({
    isOnboarded: true,
  });
}

export function createWorkspaceInvite(workspaceObj, userInfo, obj) {
  const { title, workspaceId } = workspaceObj;
  const { email, type } = obj;
  return db
    .collection("invites")
    .doc(email)
    .collection("invites")
    .add({
      workspaceTitle: title,
      workspaceId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      type,
      inviter: {
        name: userInfo.name,
        email: userInfo.email,
      },
      status: "pending",
      isAccepted: false,
    })
    .then((doc) => {
      const inviteObj = {
        invited: email,
        status: "pending",
        createdTimestamp: Date.now(),
        type,
        inviter: {
          name: userInfo.name,
          email: userInfo.email,
        },
        inviteId: doc.id,
      };
      return db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          invites: firebase.firestore.FieldValue.arrayUnion(inviteObj),
        })
        .then(() => {
          return db.collection("mail").add({
            to: email,
            message: {
              subject: `${userInfo.name} would like you to join their workspace in Scopey`,
              html: `<h1>${userInfo.name} would like for you to join Scopey</h1><p>Exciting things ahead. You've been asked to join a workspace in Scopey. In order to join please follow the link below in and create an account with this address to access the space.</p><p><a href=${URL}>Click me to go to Scopey</a></p>`,
            },
          });
        });
    });
}

export function acceptWorkspaceInvite(userInfo, inviteObj) {
  const { inviteId, type, workspaceId, workspaceTitle } = inviteObj;
  const userId = userInfo.id;
  return db
    .collection("invites")
    .doc(userInfo.email)
    .collection("invites")
    .doc(inviteId)
    .update({
      isAccepted: true,
      status: "accepted",
    })
    .then(() => {
      const userObj = {
        userId,
        userUid: userInfo.uid,
        email: userInfo.email,
        name: userInfo.name,
        createdTimestamp: Date.now(),
        type,
        tag: getInitialsFromEmail(userInfo.email),
      };
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(userObj),
        });
    })
    .then(() => {
      const workspaceObj = {
        title: workspaceTitle,
        workspaceId,
        createdTimestamp: Date.now(),
      };
      db.collection("users")
        .doc(userId)
        .update({
          workspaces: firebase.firestore.FieldValue.arrayUnion(workspaceObj),
        });
    });
}

export function updateWorkspaceInviteStatus(
  userInfo,
  obj,
  workspaceId,
  invites
) {
  const { inviteId, invitedEmail, status } = obj;
  const updateObj = {
    status,
    updater: {
      id: userInfo.id,
      name: userInfo.name,
      updatedTimestamp: Date.now(),
    },
  };
  return db
    .collection("invites")
    .doc(invitedEmail)
    .collection("invites")
    .doc(inviteId)
    .update({
      status,
      updateObj,
    })
    .then(() => {
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          invites,
        });
    });
}

export function updateWorkspaceForm(workspaceId, userInfo, obj) {
  const { title, description } = obj;
  const userId = userInfo.id;
  const updateMessage =
    obj.updateMessage ??
    `New title: ${title}, New description: ${description} `;
  const updatedObj = {
    type: updateMessage,
    updated: Date.now(),
    updaterId: userId,
    updaterName: userInfo.name,
  };
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .update({
      title,
      description,
      updated: firebase.firestore.FieldValue.arrayUnion(updatedObj),
    });
}

export async function removeUserFromWorkspace(obj) {
  const { workspaceId, users, userId, userEmail } = obj;
  const userWorkspaces = await getUserWorkspaces(userId);
  const updatedUsers = users.filter((user) => user.userId !== userId);
  const updatedWorkspaces = userWorkspaces.filter(
    (workspace) => workspace.workspaceId !== workspaceId
  );
  return db
    .collection("workspaces")
    .doc(workspaceId)
    .collection("private")
    .doc("data")
    .update({
      users: updatedUsers,
      usersRemoved: firebase.firestore.FieldValue.arrayUnion(userEmail),
    })
    .then(() => {
      db.collection("users").doc(userId).update({
        workspaces: updatedWorkspaces,
      });
    });
}

const getUserWorkspaces = async (userId) => {
  return db
    .collection("users")
    .doc(userId)
    .get()
    .then((doc) => {
      const { workspaces } = doc.data();
      return workspaces;
    });
};
