export default function Tooltip ({message}){

    return (
        <div className="absolute top-10 left-1/2 -translate-x-1/2  flex flex-col items-center">
            <div className="bg-primary-100 text-indigo-900 h-4 w-4 shadow -rotate-45 transform -mb-2"></div>
            <div className="bg-primary-100 z-10 text-indigo-900 whitespace-nowrap text-xs p-2 shadow rounded">
          {message}
        </div>
      </div>
    )
}