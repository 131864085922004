import { URL } from "./constants";

export function getEmailTemplate(type, emailInfo, id) {
  let email =
    type === "client" ? emailInfo.clientEmail : emailInfo.contractorEmail;
  emailInfo.email = emailInfo.email ?? email;
  switch (id) {
    case "ADDED_COMMENT_CONTRACTOR":
      return ADDED_COMMENT_CONTRACTOR(emailInfo);
    case "ADDED_COMMENT_CLIENT":
      return ADDED_COMMENT_CLIENT(emailInfo);
    case "ADDED_REQUEST_CONTRACTOR":
      return ADDED_REQUEST_CONTRACTOR(emailInfo);
    case "ADDED_REQUEST_CLIENT":
      return ADDED_REQUEST_CLIENT(emailInfo);
    case "APPROVE_ITEM_CLIENT":
      return APPROVE_ITEM_CLIENT(emailInfo);
    case "APPROVE_ITEM_CONTRACTOR":
      return APPROVE_ITEM_CONTRACTOR(emailInfo);
    case "QUOTE_ADDED_CONTRACTOR":
      return QUOTE_ADDED_CONTRACTOR(emailInfo);
    case "SCOPE_CREATED":
      return SCOPE_CREATED(emailInfo);
    case "SCOPE_APPROVED":
      return SCOPE_APPROVED(emailInfo);
    case "ADDED_SCOPE_APPROVER":
      return ADDED_SCOPE_APPROVER(emailInfo);
    default:
      return null;
  }
}

const SCOPEY_IMAGE = `<a style="width:'100%'; display:'flex'; justify-content:'center'; align-items:'center'" href="https://ibb.co/2FVrwBS"><img style="max-width: 100px; text-align:'center'; margin: 'auto'" src="https://i.ibb.co/2FVrwBS/Scopey-icon-rev-s.png" alt="Scopey-icon-rev-s" border="0"></a>`;
const SCOPEY_LINK = (word) =>
  `<a style="text-decoration: none; background-color: #422163; color: #fff; padding: 10px 20px; border-radius: 4px;" href='${URL}'>${word}</a>`;
const NEW_LINK = (word, link) =>
  `<a style="text-decoration: none; background-color: #422163; color: #fff; padding: 10px 20px; border-radius: 4px;" href='${link}'>${word}</a>`;

//For client
export const ADDED_SCOPE_APPROVER = ({
  projectName,
  contractorName,
  email,
}) => ({
  to: email,
  subject: `New approver added to ${projectName} - Scopey`,
  html: `<body><p>Hi there,</p><p>You have been invited to collaborate on a new project scope for <strong>${projectName}</strong> from <strong>${contractorName}</strong>.</p><p>To view your new scope and have full visibility into the project details, please click ${SCOPEY_LINK(
    "here"
  )}.</p><p>Thank you for joining us in this collaboration!</p>
  <p>Thanks!<br> The Scopey Team</p>
  </body>`,
});

//For client
export const ADDED_COMMENT_CONTRACTOR = ({
  contractorName,
  projectName,
  clientName,
  email,
}) => ({
  to: email,
  subject: `New comment by ${contractorName} in ${projectName} - Scopey`,
  html: `<body>
    <div style="font-family: Arial, sans-serif; padding: 20px;">
      ${SCOPEY_IMAGE}
      <h2>Hey ${clientName},</h2>
      <p>Just a quick heads-up, there’s a new comment in the ${projectName} for you.</p>
      <p>${SCOPEY_LINK("Log in to Scopey to review 👀")}</p>
      <p>Please take a moment to review the comment and provide any necessary feedback or clarification.</p>
      <p>Thanks for being such a great collaborator! Let's keep the communication flowing so we can deliver exactly what you need!</p>
      <p>Thanks, and talk soon.</p>
      <p>${contractorName}</p>
      <p>This email was intended for ${clientName}, we send notifications like these to keep you informed about your projects.</p>
      <p>© Scopey Pty Ltd 2023.</p>
    </div>
  </body>`,
});

//For contractor
export const ADDED_COMMENT_CLIENT = ({
  contractorName,
  projectName,
  clientName,
  email,
}) => {
  return {
    to: email,
    subject: `New comment by ${clientName} in ${projectName} - Scopey`,
    html: `<body>
    <div style="font-family: Arial, sans-serif; padding: 20px;">
    ${SCOPEY_IMAGE}
      <h2>Hey,</h2>
      <p>Just a quick heads-up, ${clientName} has added a new comment in the Scopey portal.</p>
      <p>${SCOPEY_LINK("Log in to Scopey to review 👀")}</p>
      <p>We’ll leave it to you – you’re doing great!</p>
      <p>Scopey</p>
      <p>© Scopey Pty Ltd 2023.</p>
    </div>
  </body>`,
  };
};

//For contractor
export const APPROVE_ITEM_CLIENT = ({
  contractorName,
  projectName,
  clientName,
  itemTitle,
  email,
}) => ({
  to: email,
  subject: `Request quote approved in ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Great news! ${clientName} has approved the quote for their recent request: ${itemTitle} on ${projectName}.</p>
    <p>${SCOPEY_LINK("View the updated scope 👀")}</p>
    <p>Remember, if you need to clarify anything simply comment on the request in the Scopey portal. It’ll keep all communication in one place and everyone on the same page.</p>
    <p>Keep doing what you do best!</p>
    <p>Scopey</p>
    <p>This email was intended for contractors working on the project: ${projectName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For client
export const APPROVE_ITEM_CONTRACTOR = ({
  contractorName,
  projectName,
  clientName,
  itemTitle,
  itemRef,
  email,
}) => ({
  to: email,
  subject: `Item added to your scope in ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Just a heads up! ${contractorName} has approved the quote for their recent request for ${itemTitle} (${itemRef}) on ${projectName}.</p>
    <p>${SCOPEY_LINK("View the updated scope 👀")}</p>
    <p>Remember, if you need to clarify anything simply comment on the request in the Scopey portal. It’ll keep all communication in one place and everyone on the same page.</p>
    <p>Keep doing what you do best!</p>
    <p>Scopey</p>
    <p>This email was intended for contractors working on the project: ${projectName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For client
export const QUOTE_ADDED_CONTRACTOR = ({
  contractorName,
  projectName,
  clientName,
  requestTitle,
  email,
}) => ({
  to: email,
  subject: `New quote for ${requestTitle} in ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Good news! We've added a quote for your recent request on ${projectName}.</p>
    <p>To review the quote and provide your feedback or approval, please log in to the Scopey portal using the link below:</p>
    <p>${SCOPEY_LINK("View quote 👀")}</p>
    <p>If you have any questions or need further clarification, you can add a comment directly to the request.</p>
    <p>Thanks, and talk soon.</p>
    <p>${contractorName}</p>

    <p>This email was intended for contractors working on the project: ${projectName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For contractor
export const ADDED_REQUEST_CLIENT = ({
  contractorName,
  projectName,
  clientName,
  requestTitle,
  requestRef,
  email,
}) => ({
  to: email,
  subject: `New request added for ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Exciting news! ${clientName} has added a new request: ${requestTitle} (${requestRef}) for ${projectName}.</p>
    <p>To review the details and provide a quote, please log in to the Scopey portal.</p>
    <p>${SCOPEY_LINK("View request 👀 in Scopey")}</p>
    <p>If you need to clarify details with your client, you can add a comment directly to their request in Scopey!</p>
    <p>Keep up the great work!</p>
    <p>Scopey</p>

    <p>This email was intended for ${contractorName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For client
export const ADDED_REQUEST_CONTRACTOR = ({
  contractorName,
  projectName,
  clientName,
  requestTitle,
  requestRef,
  email,
}) => ({
  to: email,
  subject: `New request added for ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Just a heads up! ${contractorName} has added a new request: ${requestTitle} (${requestRef}) for on your behalf for ${projectName}.</p>
    <p>To review the details and approve this request, please log in to the Scopey portal.</p>
    <p>${SCOPEY_LINK("View request 👀 in Scopey")}</p>
    <p>If you need to clarify details with your client, you can add a comment directly to their request in Scopey!</p>
    <p>Keep up the great work!</p>
    <p>Scopey</p>
    <p>This email was intended for contractors working on the project: ${projectName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For client
export const SCOPE_CREATED = ({
  contractorName,
  projectName,
  clientName,
  email,
}) => ({
  to: email,
  subject: `A new Scope for ${projectName} has been issued - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>Thanks for the opportunity to quote ${projectName} for you!</p>
    <p>Your scope of work is now ready, please click the link below to review it.</p>
    <p>${SCOPEY_LINK("View scope 👀")}</p>
    <p>The scope outlines the specifications, deliverables, and costs to deliver ${projectName}.</p>
    <p>If you have any questions, you can add them in the portal.</p>
    <p>If you’re happy to get started, simply approve the scope and we’ll do the rest!</p>
    <p>We’re looking forward to working with you.</p>
    <p>${contractorName}</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

//For contractor
export const SCOPE_APPROVED = ({
  contractorName,
  projectName,
  clientName,
  email,
}) => ({
  to: email,
  subject: `Scope approved for ${projectName} - Scopey`,
  html: `<body>
  <div style="font-family: Arial, sans-serif; padding: 20px;">
  ${SCOPEY_IMAGE}
    <h2>Hey,</h2>
    <p>We've got fantastic news! ${clientName} has given their approval on the scope for ${projectName}.</p>
    <p>${SCOPEY_LINK("Go to Scopey 👀")}</p>
    <p>Now, you can collaborate with your client on this project in the Scopey portal. We’ll let you know when they add questions or make additional requests.</p>
    <p>And you can focus on what you do best!</p>
    <p>We’ll keep you posted,</p>
    <p>Scopey</p>
    <p>This email was intended for ${contractorName}, we send notifications like these to keep you informed about your projects.</p>
    <p>© Scopey Pty Ltd 2023.</p>
  </div>
</body>`,
});

export const SCOPE_INVITE = ({ projectName, clientName, link, email }) => {
  return {
    to: email,
    subject: `You've been invited as an approver to a new project scope ${projectName} - Scopey`,
    html: `<body>
    <div style="font-family: Arial, sans-serif; padding: 20px;">
    ${SCOPEY_IMAGE}
      <h2>Hey,</h2>
      <p>Just a quick heads-up, ${clientName} has added you as an approver for a new project scope.</p>
      <p>${NEW_LINK("Log in to Scopey to review 👀", link)}</p>
      <p>We’ll leave it to you – you’re doing great!</p>
      <p>Scopey</p>
      <p>© Scopey Pty Ltd 2023.</p>
    </div>
  </body>`,
  };
};
