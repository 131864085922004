import { createContext, useContext } from "react";
import {
  makeItemOptional,
  updateCollectionItem,
  updateCollectionItemMultiple,
} from "../services/SharedService";
import { FeedbackContext } from "./feedback-context";
import { createLabel } from "../services/LabelService";
import { UserContext } from "./user-context";
import { createScopeValueAdd } from "../services/ValueAddService";

export const FunctionContext = createContext({
  handleUpdateItem: (itemObj, obj) => {},
  handleCreateLabel: (obj) => {},
  handleMakeItemOptional: (obj) => {},
  handleAddValueAdd: (valueAddId) => {},
  handleUpdateItemMultiple: (itemObj, obj) => {},
});

export function FunctionContextProvider(props) {
  const { handleFeedback, setAlert } = useContext(FeedbackContext);
  const { userInfo } = useContext(UserContext);

  const handleUpdateItem = (itemObj, obj, feedbackObj) => {
    feedbackObj = feedbackObj ?? { message: "Item updated successfully" };
    const { message, callback } = feedbackObj;
    const { collection, itemId } = itemObj;
    try {
      return updateCollectionItem(collection, itemId, obj).then(() =>
        handleFeedback(message, callback)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateItemMultiple = (itemObj, obj, feedbackObj) => {
    feedbackObj = feedbackObj ?? { message: "Item updated successfully" };
    const { message, callback } = feedbackObj;
    const { collection, itemId } = itemObj;
    try {
      return updateCollectionItemMultiple(collection, itemId, obj).then(() =>
        handleFeedback(message, callback)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateLabel = (obj) => {
    const message = "Tag created successfully";
    try {
      return createLabel(userInfo, obj).then(() => handleFeedback(message));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleMakeItemOptional = (obj) => {
    try {
      return makeItemOptional(obj, userInfo).then(() =>
        handleFeedback("Item made optional")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleAddValueAdd = (valueAddObj) => {
    try {
      return createScopeValueAdd(valueAddObj, userInfo).then(() =>
        handleFeedback("Optional service added")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const contextValue = {
    handleUpdateItem,
    handleCreateLabel,
    handleMakeItemOptional,
    handleAddValueAdd,
    handleUpdateItemMultiple,
  };

  return (
    <FunctionContext.Provider value={contextValue}>
      {props.children}
    </FunctionContext.Provider>
  );
}
