import React, { useContext, useEffect, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import Alert from "../../components/alerts/Alert";
import Button from "../../components/buttons/Button";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ModalAddApprover from "../../components/modals/ModalAddApprover";
import PageShell from "../../components/page-shells/PageShellSidebar";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import Toast from "../../components/toasts/Toast";
import { db } from "../../firebase";
import { createPhase } from "../../services/PhaseService";
import {
  createItem,
  createItemFromRequest,
  createScopeInvite,
  updatePhaseRow,
} from "../../services/ScopeService";
import { sendEmailFromObj } from "../../services/SharedService";
import { createValueAdd } from "../../services/ValueAddService";
import { UserContext } from "../../store/user-context";
import { getNaviagtionItems } from "../../utils/getNavigationItems";
import { sortArrayByOrder } from "../../utils/sortArray";
import ProjectSectionBuilder from "./ProjectSectionBuilder";
import ProjectSectionClient from "./ProjectSectionClient";
import ProjectSectionSettings from "./ProjectSectionSettings";
import ProjectSectionTeam from "./ProjectSectionTeam";
import Pill from "../../components/pills/Pill";
import { getEmailTemplate } from "../../utils/emailStrings";
import { EmailContext } from "../../store/email-context";
import { ModalContext } from "../../store/modal-context";
import ModalInviteScope from "../../components/modals/ModalInviteScope";
//import ProjectSectionScope from "./ProjectSectionScope";
//import WorkspaceSectionProjects from "./WorkspaceSectionProjects";

function ProjectDashboard(props) {
  const {
    workspace,
    projectId,
    handleUpdateItem,
    valueAdds,
    projects,
    setActiveView,
    activeView,
    setSelectedProjectId,
    selectedProjectId,
  } = props;
  const userInfoCtx = useContext(UserContext);
  const { userInfo, authLoading } = userInfoCtx;
  const [project, setProject] = useState(false);
  const [client, setClient] = useState(false);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [isClientLoading, setIsClientLoading] = useState(true);
  const { alert, setAlert, toast, setToast, isSpinning } = props;
  const emailCtx = useContext(EmailContext);
  const { handleSetEmailInfo, emailInfo } = emailCtx;
  const { activeModal, setActiveModal } = useContext(ModalContext);

  let phases = [];
  let items = [];
  let scopes = [];
  let templates = [];

  const handleSelectProject = (projectId) => {
    setIsProjectLoading(true);
    setActiveView("Projects");
    setSelectedProjectId(projectId);
  };

  const fetchProject = async () => {
    if (!isProjectLoading) {
      return;
    }
    try {
      db.collection("projects")
        .doc(projectId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setProject(data);
          setIsProjectLoading(false);
          fetchClient(data.clientId);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchClient = async (clientId) => {
    if (!isClientLoading) {
      return;
    }
    try {
      db.collection("clients")
        .doc(clientId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setClient(data);
          setIsClientLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const [itemValue, itemLoading] = useCollection(
    db
      .collection("items")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (itemValue && !itemLoading) {
    //eslint-disable-next-line
    itemValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = item.type ?? "item";
      items.push(item);
    });
  }

  const [phaseValue, phaseLoading] = useCollection(
    db
      .collection("phases")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (phaseValue && !phaseLoading) {
    //eslint-disable-next-line
    phaseValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "phase";
      phases.push(item);
    });
    phases = sortArrayByOrder(phases);
  }

  const [scopeValue, scopeLoading] = useCollection(
    db
      .collection("scopes")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (scopeValue && !scopeLoading) {
    //eslint-disable-next-line
    scopeValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "scope";
      scopes.push(item);
    });
  }

  const [templateValue, templateLoading] = useCollection(
    db
      .collection("templates")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspace.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (templateValue && !templateLoading) {
    //eslint-disable-next-line
    templateValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "template";
      templates.push(item);
    });
  }

  useEffect(() => {
    fetchProject();
    //eslint-disable-next-line
  }, [isProjectLoading]);

  const isLoading =
    authLoading ||
    isProjectLoading ||
    phaseLoading ||
    isClientLoading ||
    templateLoading;

  useEffect(() => {
    if (!isLoading) {
      handleSetEmailInfo(project, userInfo.name);
    }
    //eslint-disable-next-line
  }, [isLoading]);

  const handleCreatePhase = () => {
    const order = phases.length;
    const obj = { title: `Phase ${order + 1}`, description: "", order };
    const entityObj = { workspaceId: workspace.id, projectId: projectId };
    try {
      return createPhase(entityObj, userInfo, obj).then(() =>
        handleFeedback("Phase created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateValueAdd = (obj) => {
    const entityObj = { workspaceId: workspace.id, projectId: projectId };
    try {
      return createValueAdd(entityObj, obj, userInfo).then(() =>
        handleFeedback("Optional Service created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateItem = (phaseId, obj) => {
    const entityObj = {
      workspaceId: workspace.id,
      projectId: projectId,
      phaseId,
    };
    try {
      return createItem(entityObj, userInfo, obj).then(() =>
        handleFeedback("Item created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
      console.log(error);
    }
  };

  const handleUpdatePhaseRow = (itemId, obj) => {
    try {
      return updatePhaseRow(itemId, obj).then(() =>
        handleFeedback("Item updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateScope = () => {
    const obj = { prop: "isInViewMode", value: true };
    const itemObj = { collection: "projects", itemId: projectId };
    try {
      return handleUpdateItem(itemObj, obj);
    } catch (error) {
      setAlert("There has been an error with this request");
      console.log(error);
    }
  };

  const handleInviteApprover = (email, valueObj) => {
    const obj = { email, project, valueObj };
    try {
      return createScopeInvite(userInfo, obj).then(() =>
        handleFeedback("Approver Invited")
      );
    } catch {
      setAlert("There has been an error with this request");
    }
  };

  const handleAddItemFromRequest = (obj) => {
    const phaseId = obj.phaseId ?? phases[phases.length - 1].id;
    obj.phaseId = phaseId;
    try {
      return createItemFromRequest(userInfo, obj).then(() =>
        handleFeedback("Item Added")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
      console.log(error);
    }
  };

  const getActiveView = () => {
    //Only one active view now for project
    switch ("Scope") {
      case "Scope":
        return project.scopeObj ? (
          <>
            <ScopeOption
              workspaceId={project.workspaceId}
              scope={project.scopeObj}
              isFromProject={true}
              projectId={project.id}
            />
            <ProjectSectionClient
              approvers={project.scopeApprovers}
              handleToggle={handleToggleApprover}
            />
            <ProjectSectionSettings project={project} />
          </>
        ) : (
          <ProjectSectionBuilder
            phases={phases}
            handleCreateScope={handleCreateScope}
            handleCreateItem={handleCreateItem}
            handleCreatePhase={handleCreatePhase}
            handleUpdateItem={handleUpdateItem}
            handleUpdateRow={handleUpdatePhaseRow}
            handleCreateValueAdd={handleCreateValueAdd}
            handleAddItemFromRequest={handleAddItemFromRequest}
            items={items.filter((item) => item.projectId === project.id)}
            valueAdds={valueAdds}
            projectValueAdds={project.valueAdds ?? []}
            projectTitle={project.title}
            projectId={project.id}
            approvers={project.scopeApprovers}
            scopeObj={project.scopeObj}
            currency={project.currency}
            templates={templates}
            workspaceId={project.workspaceId}
          />
        );
      case "Team":
        return <ProjectSectionTeam members={project.users} />;
      case "Client":
        return (
          <ProjectSectionClient
            approvers={client.scopeApprovers}
            projectApprovers={project.scopeApprovers}
            handleToggle={handleToggleApprover}
          />
        );
      case "History":
        return <h1>History</h1>;
      case "Settings":
        return <ProjectSectionSettings project={project} />;
      default:
        return null;
    }
  };

  const handleFeedback = (message, setClose) => {
    setToast(message);
    if (setClose) {
      setClose();
    }
    return;
  };

  const handleToggleApprover = (email) => {
    const approvers = project.approvers;
    let updatedApprovers = [];
    const isPresent = updatedApprovers.includes(email);
    if (isPresent) {
      updatedApprovers = approvers.filter((item) => item !== email);
    } else {
      updatedApprovers = [...approvers, email];
    }
    const itemObj = {
      collection: "projects",
      itemId: projectId,
    };
    const obj = {
      prop: "approvers",
      value: updatedApprovers,
    };
    return handleUpdateItem(itemObj, obj).then(() => {
      const emailInfoWEmail = emailInfo;
      emailInfoWEmail.email = email;
      const emailTemp = getEmailTemplate(
        "contractor",
        emailInfoWEmail,
        "ADDED_SCOPE_APPROVER"
      );
      if (isPresent) {
        return sendEmailFromObj(emailTemp);
      } else {
        return;
      }
    });
  };

  return isLoading ? (
    <PageShellLoading />
  ) : (
    <div className={`w-full min-h-screen max-w-7xl mx-auto`}>
      {toast && <Toast message={toast} />}
      {alert && <Alert isTop={false} message={alert} />}
      {isSpinning && <LoadingSpinner />}
      <PageShell
        userInfo={userInfo}
        mainView={getActiveView()}
        activeView={activeView}
        setActiveView={setActiveView}
        navigationItems={getNaviagtionItems("project")}
        type={"project"}
        isHeadingHidden
        viewPadding={project.scopeObj ? "p-0" : false}
        maxViewWidth={project.scopeObj ? "max-w-none" : false}
        isWorkspaceDropdownVisible={true}
        currentWorkspace={workspace}
        projects={projects}
        handleSelectProject={handleSelectProject}
        selectedProject={selectedProjectId}
      />
      <ModalAddApprover
        isOpen={activeModal === "approver"}
        projectId={project.id}
        approvers={project.scopeApprovers}
        handleAdd={handleToggleApprover}
      />
      <ModalInviteScope
        setIsOpen={setActiveModal}
        isOpen={activeModal === "invite-scope"}
        approvers={project.scopeApprovers}
        invites={project.scopeInvites}
        projectId={project.id}
        handleInviteApprover={handleInviteApprover}
      />
    </div>
  );
}

export default ProjectDashboard;

const ScopeOption = ({ projectId, scope, status, workspaceId }) => {
  const { title } = scope;
  const pillColor = status === "Active" ? "success" : "warning";
  return (
    <div>
      <Link to={`/scope/${projectId}/${workspaceId}`}>
        <div className="flex items-center px-5 mt-10 mb-2 mx-auto max-w-5xl cursor-pointer">
          <div>
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex justify-end items-center ml-3">
            <Pill type={pillColor} message={status} />
          </div>
          <div className="flex flex-1 justify-end items-center">
            <Link to={`/scope/${projectId}/${workspaceId}`}>
              <Button message="Go to full scope" />
            </Link>
          </div>
        </div>
      </Link>
    </div>
  );
};
