import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export default function Pill(props) {
  const { message, handleClick } = props;
  const padding = props.padding ?? "py-1 px-3";
  const colors = getColors(props.type ?? "primary");
  const textSize = props.textSize ?? "text-xs";
  const uppercase = props.isLower ? "lowercase" : "uppercase";
  const fullWidth = props.fullWidth ?? true;
  return (
    <div
      className={`rounded-xl flex items-center font-medium ${textSize} ${fullWidth ? padding : 'p-0'}  ${ fullWidth ? colors : ''} ${uppercase}`}
    >
      <p className={!fullWidth && `rounded-xl flex items-center font-medium  ${padding} ${textSize} ${colors}`}>
        {message}
      </p>
      {handleClick && (
        <button type="button" aria-label={`Remove ${message}`} onClick={handleClick}>
          <span className="sr-only">Remove</span>
          <XMarkIcon className="h-3 w-3 text-gray-700 ml-1 hover:text-primary-800" />
        </button>
      )}
    </div>
  );
}

function getColors(type) {
  switch (type) {
    case "primary":
      return "bg-[#FFF0F1] text-[#F96E74] focus:ring-tertiary-500";
    case "secondary":
      return "bg-[#FFECDE] text-[#D86525] focus:ring-violet-500";
    case "tertiary":
      return "bg-[#EAF0FF] text-[#5E7ED0] focus:ring-blue-300";
    case "members":
      return "bg-primary-300 text-gray-700 focus:ring-tertiary-500";
    case "success":
      return "bg-[#EEFFFB] text-[#05AA83] focus:ring-green-500";
    case "purple":
      return "bg-[#D3B7F2] text-[#422163] focus:ring-[#422163]";
    default:
      return "bg-[#FFF0F1] text-[#F96E74] focus:ring-tertiary-500";
  }
}
