import { db, firebase } from "../firebase";

export function createLabel(userInfo, obj) {
  const { title, workspaceId } = obj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };

  return db.collection("labels").add({
    title,
    workspaceId,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
    owner: creator,
  });
}
