import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alerts/Alert";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import ModalClientCreate from "../../components/modals/ModalClientCreate";
import ModalContactAdd from "../../components/modals/ModalContactAdd";
import ModalInviteCreate from "../../components/modals/ModalInviteCreate";
import ModalWorkspaceCreate from "../../components/modals/ModalWorkspaceCreate";
import PageShell from "../../components/page-shells/PageShellSidebar";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import Toast from "../../components/toasts/Toast";
import {
  addClientContacts,
  createClient,
  updateClientMember,
} from "../../services/ClientService";
import { createProject } from "../../services/ProjectService";
import { updateCollectionItem } from "../../services/SharedService";
import {
  createWorkspace,
  createWorkspaceInvite,
  removeUserFromWorkspace,
} from "../../services/WorkspaceService";
import { UserContext } from "../../store/user-context";
import { getSidebarNavigationItems } from "../../utils/getNavigationItems";
import WorkspaceSectionNewProject from "./WorkspaceSectionNewProject";
import WorkspaceSectionProjects from "./WorkspaceSectionProjects";
import WorkspaceSectionTeam from "./WorkspaceSectionTeam";
import UserSectionInfo from "../user-dashboard/UserSectionInfo";
import { updateUserForm } from "../../services/UserService";
import WorkspaceSectionProject from "./WorkspaceSectionProject";
import { createFromTemplate } from "../../services/TemplateService";
import ModalConfirm from "../../components/modals/ModalConfirm";
import WorkspaceSectionClients from "./WorkspaceSectionClients";
import WorkspaceSectionValueAdds from "./WorkspaceSectionValueAdds";
import ModalValueAdd from "../../components/modals/ModalAddValueAdd";
import { createValueAdd } from "../../services/ValueAddService";
import { ModalContext } from "../../store/modal-context";
import { FeedbackContext } from "../../store/feedback-context";
import { useEffect } from "react";
//import WorkspaceSectionProjects from "./WorkspaceSectionProjects";

function WorkspaceDashboard(props) {
  const [selectedProjectId, setSelectedProjectId] = useState(false);
  const [selectedProjectStatus, setSelectedProjectStatus] = useState("scope");
  const [newProjectClientId, setNewProjectClientId] = useState("");
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [userToDelete, setUserToDelete] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(false);
  const userInfoCtx = useContext(UserContext);
  const { setIsUserUpdated, projects, scopes, workspaceScopes } = userInfoCtx;
  const {
    workspaceId,
    setWorkspaceId,
    valueAdds,
    scopeVAs,
    clients,
    items,
    labels,
    privateData,
    workspace,
    invites,
    isWorkspaceOwner,
    userInfo,
    activeView,
    setActiveView,
    setIsWorkspaceLoading,
    setIsPrivateDataLoading,
    isLoading,
  } = props;
  const navigate = useNavigate();
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const {
    alert,
    setAlert,
    toast,
    setToast,
    isSpinning,
    isNavigating,
    setIsNavigating,
  } = useContext(FeedbackContext);

  const handleSelectProject = (projectId, status) => {
    if (projectId === selectedProjectId) {
      return;
    }
    const newStatus = status === "edit" ? "edit" : "scope";
    setSelectedProjectStatus(newStatus);
    setIsProjectLoading(true);
    setSelectedProjectId(projectId);
    setActiveView("Project");
  };

  const handleSelectView = (view) => {
    if (view === activeView) {
      return;
    }
    setSelectedProjectId(false);
    setActiveView(view);
  };

  const handleUpdateUserForm = (obj) => {
    return updateUserForm(userInfo.id, obj).then(() =>
      setToast("Profile updated")
    );
  };

  console.log(projects)

  const getActiveView = () => {
    switch (activeView) {
      case "Projects":
        return (
          <WorkspaceSectionProjects
            projects={projects}
            setActiveView={setActiveView}
            workspaceId={workspaceId}
            handleSelect={handleSelectProject}
            members={privateData.users}
            clients={clients}
            scopes={scopes}
            items={items}
            workspaceScopes={workspaceScopes}
            workspace={workspace}
          />
        );
      case "Project":
        return (
          <WorkspaceSectionProject
            handleUpdateItem={handleUpdateItem}
            projectId={selectedProjectId}
            isProjectLoading={isProjectLoading}
            setIsProjectLoading={setIsProjectLoading}
            clients={clients}
            setNewProjectClientId={setNewProjectClientId}
            newProjectClientId={newProjectClientId}
          />
        );
      case "New Project":
        return (
          <WorkspaceSectionNewProject
            handleCreate={handleCreateProject}
            setAlert={setAlert}
          />
        );
      case "Workspace":
        return (
          <WorkspaceSectionTeam
            invites={invites}
            members={privateData.users}
            membersRemoved={privateData.usersRemoved}
            userInfo={userInfo}
            workspaceId={workspaceId}
            workspace={workspace}
            handleUpdateItem={handleUpdateItem}
            isWorkspaceOwner={isWorkspaceOwner}
            setUserToDelete={setUserToDelete}
          />
        );
      case "Clients":
        return (
          <WorkspaceSectionClients
            clients={clients}
            userInfo={userInfo}
            isWorkspaceOwner={isWorkspaceOwner}
            handleUpdateItem={handleUpdateClient}
            setItemToDelete={setItemToDelete}
          />
        );
      case "Account":
        return (
          <UserSectionInfo
            handleUpdateForm={handleUpdateUserForm}
            user={userInfo}
          />
        );
      case "Service Library":
        return (
          <WorkspaceSectionValueAdds
            user={userInfo}
            workspace={workspace}
            valueAdds={valueAdds}
            scopeVAs={scopeVAs}
            handleUpdateItem={handleUpdateItem}
            labels={labels}
          />
        );
      default:
        return null;
    }
  };

  const handleFeedback = (message, setClose) => {
    setToast(message);
    if (setClose) {
      setClose();
    }
  };

  const handleGoToCreatedScope = (id) => {
    //const uri = isBlank ? "scope" : "scope";
    setIsNavigating(true);
    setIsUserUpdated(true);
    navigate(`/scope/${id}/${workspaceId}`);
    setIsNavigating(false);
  };

  const handleCreateProject = (obj, templateObj) => {
    const workspaceObj = { workspaceId, currency: workspace.currency };
    try {
      return createProject(workspaceObj, userInfo, obj, templateObj).then(
        (id) =>
          handleFeedback("Project Created", () => handleGoToCreatedScope(id))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateClientFeedback = (clientId) => {
    setNewProjectClientId(clientId);
    handleFeedback("Client Created", () => setActiveModal(false));
  };

  const handleCreateClient = (obj) => {
    try {
      return createClient(workspaceId, userInfo, obj).then((id) =>
        handleCreateClientFeedback(id)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateInvite = (obj) => {
    const workspaceObj = { title: workspace.title, workspaceId: workspace.id };
    try {
      return createWorkspaceInvite(workspaceObj, userInfo, obj).then(() =>
        handleFeedback("Project Created", () => setActiveModal(false))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const goToWorkspace = (id, isCreated) => {
    setIsNavigating(true);
    setWorkspaceId(id);
    setIsWorkspaceLoading(true);
    setActiveModal(false);
    navigate(`/workspace/${id}/Projects`, { replace: true });
    if (!isCreated) {
      setIsPrivateDataLoading(true);
    }
  };

  const handleCreateWorkspace = (obj) => {
    const isCreated = true;
    return createWorkspace(userInfo, obj).then((id) =>
      goToWorkspace(id, isCreated)
    );
  };

  const handleUpdateItem = (itemObj, obj, setClose) => {
    const { collection, itemId } = itemObj;
    try {
      return updateCollectionItem(collection, itemId, obj).then(() => {
        return handleFeedback("Item Updated", setClose);
      });
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleUpdateClient = (obj, id, setClose) => {
    try {
      return updateClientMember(obj, id).then(() => {
        return handleFeedback("Item Updated", setClose);
      });
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleDeleteItemFromConfirmation = () => {
    const { collection, itemId } = itemToDelete;
    const obj = { prop: "isDeleted", value: true };
    try {
      return updateCollectionItem(collection, itemId, obj).then(() => {
        return handleFeedback("Item Deleted", () => setItemToDelete(false));
      });
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleAddClientContacts = (id, obj) => {
    try {
      return addClientContacts(id, obj).then(() =>
        handleFeedback("Item Updated", () => setActiveModal(false))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateScopeFromTemplate = (templateId) => {
    const entityObj = { projectId: selectedProjectId, workspaceId };
    try {
      return createFromTemplate(
        templateId,
        entityObj,
        userInfo,
        valueAdds
      ).then(() => handleFeedback("Item Updated"));
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateValueAdd = (obj) => {
    const entityObj = { workspaceId: workspace.id, projectId: "" };
    try {
      return createValueAdd(entityObj, obj, userInfo).then(() =>
        handleFeedback("Optional Service created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleRemoveUserFromWorkspace = () => {
    const obj = {
      workspaceId,
      users: privateData.users,
      userId: userToDelete.userId,
      userEmail: userToDelete.email,
    };
    try {
      return removeUserFromWorkspace(obj).then(() =>
        handleFeedback("User Removed")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  useEffect(() => {
    if (isNavigating && !isLoading) {
      setIsNavigating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return isLoading || isNavigating ? (
    <PageShellLoading />
  ) : (
    <div className={`w-full min-h-screen bg-primary-100`}>
      <>
        {toast && <Toast message={toast} />}
        {alert && <Alert isTop={false} message={alert} />}
        {isSpinning && <LoadingSpinner />}
        <ModalWorkspaceCreate
          isOpen={activeModal === "workspace"}
          setIsOpen={() => setActiveModal(false)}
          handleCreate={handleCreateWorkspace}
        />
        <ModalInviteCreate
          isOpen={activeModal === "invite"}
          setIsOpen={() => setActiveModal(false)}
          handleCreate={handleCreateInvite}
        />
        <ModalClientCreate
          isOpen={activeModal === "client"}
          setIsOpen={() => setActiveModal(false)}
          handleCreate={handleCreateClient}
        />
        <ModalValueAdd
          isOpen={activeModal === "value-add"}
          setIsOpen={setActiveModal}
          handleCreate={handleCreateValueAdd}
          valueAdds={valueAdds}
          labels={labels}
          workspaceId={workspace.id}
        />
        <ModalConfirm
          isOpen={userToDelete !== false}
          setIsOpen={setUserToDelete}
          buttonMessage={"Yes, delete"}
          title={`Are you sure you want to remove ${userToDelete.name} from this workspace?`}
          description={
            "If you remove this user, you will still be able to add them back at a later date."
          }
          handleAction={() => handleRemoveUserFromWorkspace()}
        />
        <ModalConfirm
          isOpen={itemToDelete !== false}
          setIsOpen={setItemToDelete}
          buttonMessage={"Yes, delete"}
          title={`Are you sure you want to delete ${itemToDelete.title} for good?`}
          description={`If you delete this, you may not be able to add it back at a later date.`}
          handleAction={() => handleDeleteItemFromConfirmation()}
        />
        <PageShell
          userInfo={userInfo}
          mainView={getActiveView()}
          activeView={activeView}
          setActiveView={handleSelectView}
          selectedProjectId={selectedProjectId}
          navigationItems={getSidebarNavigationItems("workspace")}
          type={"workspace"}
          currentPage={"workspace"}
          isHeadingHidden={true}
          isWorkspaceDropdownVisible={true}
          currentWorkspace={workspace}
          goToWorkspace={goToWorkspace}
          projects={privateData.projects}
          handleSelectProject={handleSelectProject}
          isInWorkspace={true}
        />
      </>
    </div>
  );
}

export default WorkspaceDashboard;
