import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Alert from "../../components/alerts/Alert";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import Toast from "../../components/toasts/Toast";
import { db } from "../../firebase";
import { UserContext } from "../../store/user-context";
import { createTemplate } from "../../services/TemplateService";
import Button from "../../components/buttons/Button";
import {
  ArrowTopRightOnSquareIcon,
  BookmarkIcon,
} from "@heroicons/react/24/outline";
import ModalTemplateAdd from "../../components/modals/ModalAddTemplate";
import { ModalContext } from "../../store/modal-context";

export default function CreatedScopePage(props) {
  let { scopeId, projectId, workspaceId } = useParams();
  const [scope, setScope] = useState(false);
  const [isScopeLoading, setIsScopeLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [templateCreated, setTemplateCreated] = useState(false);
  //const [isSpinning, setIsSpinning] = useState(false);
  const userInfoCtx = useContext(UserContext);
  const { userInfo, authLoading } = userInfoCtx;
  const { activeModal, setActiveModal } = useContext(ModalContext);

  const fetchScope = async () => {
    if (!isScopeLoading) {
      return;
    }
    try {
      db.collection("scopes")
        .doc(scopeId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setScope(data);
          setIsScopeLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchScope();
    //eslint-disable-next-line
  }, [isScopeLoading, scopeId]);

  useEffect(() => {
    setIsScopeLoading(true);
    fetchScope();
    //eslint-disable-next-line
  }, [scopeId]);

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        setToastMessage("");
      }, 3000);
    }
  }, [toastMessage]);

  const handleFeedback = (message, setClose) => {
    setToastMessage(message);
    setTemplateCreated(true);
    if (setClose) {
      setClose();
    }
  };

  const isLoading = authLoading || isScopeLoading;

  const handleCreateTemplate = (obj) => {
    const entityObj = { workspaceId: scope.workspaceId, scopeId: scope.id };
    let items = scope.items.filter((item) => item.type === "item");
    items = items.map((item) => ({
      title: item.title,
      description: item.description,
      type: item.type,
      cost: item.cost,
      quantity: item.quantity,
      phaseId: item.phaseId,
      ref: item.ref,
      order: item.order,
    }));
    const valueAdds = scope.valueAdds.map((item) => ({
      title: item.title,
      description: item.description,
      type: item.type,
      cost: item.cost,
      id: item.id,
      ref: item.ref ?? "",
    }));
    const phases = scope.phases.map((item) => ({
      title: item.title,
      description: item.description,
      code: item.code,
      type: item.type,
      order: item.order,
      originalPhaseId: item.id,
    }));
    const scopeObj = { items, valueAdds, phases, currency: scope.currency };
    try {
      return createTemplate(entityObj, scopeObj, obj, userInfo).then(() =>
        handleFeedback("Template Created", () => setActiveModal(false))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  return isLoading ? (
    <PageShellLoading />
  ) : (
    <>
      {toastMessage && <Toast message={toastMessage} />}
      {alert && <Alert isTop={false} message={alert} />}
      <ModalTemplateAdd
        isOpen={activeModal === "template"}
        handleCreate={handleCreateTemplate}
      />
      <main className="bg-primary-200">
        <div className="flex flex-col justify-center min-h-screen items-center px-4 py-2">
          <span alt="Celebrate" className="text-[4rem]">
            🎉
          </span>
          <div className="text-gray-700 mb-20 space-y-0">
            <h1 className="text-xl font-bold">Your scope has been sent</h1>
            <p className="max-w-lg mx-auto">
              Your client will receive an email with a link to this scope to
              approve. When they approve it, they will see the scoping
              dashboard.
            </p>
          </div>
          <div className="space-y-2 flex flex-col">
            {!templateCreated && (
              <Button
                icon={<BookmarkIcon className="h-5 w-5 mr-1" />}
                message={"Go to dashboard"}
                handleClick={() => setActiveModal("template")}
              />
            )}
            <Link to={`/scope/${scopeId}/${projectId}/${workspaceId}`}>
              <Button
                bg="accent"
                icon={<ArrowTopRightOnSquareIcon className="h-5 w-5 mr-1" />}
                message={"Go to the scoping dashboard"}
              />
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
