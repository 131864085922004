import Button from "../buttons/Button";

export default function SectionHeading(props) {
  const { heading, subheading } = props;
  const buttonMessage = props.buttonMessage ?? "Create new";
  const handleClick = props.handleClick ?? void 0;
  const color = props.color ?? "primary";
  const bg = props.shadeBg ? props.shadeBg : "bg-transparent";
  return (
    <div className={`border-gray-200 py-5 ${bg}`}>
      <div className="-mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
        <div className=" mt-2 text-left">
          <h3 className="text-xl font-bold leading-6 text-gray-800">
            {heading}
          </h3>
          {subheading && <p className="text-gray-500">{subheading}</p>}
        </div>
        {props.handleClick && (
          <div className="mt-2 flex-shrink-0">
            <Button
              bg={color}
              handleClick={handleClick}
              message={buttonMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
}
