import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../buttons/Button";

export default function ModalConfirmUpdateItem(props) {
  const {
    isOpen,
    setIsOpen,
    handleConfirm,
    setNeedsConfirmation,
    handleSetExpanded,
  } = props;

  const clearState = () => {
    const isForced = true;
    handleSetExpanded(isForced);
    setIsOpen(false);
    setNeedsConfirmation(false);
  };

  const handleSubmit = (e) => {
    return handleConfirm(e).then(() => clearState());
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={clearState}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col bg-none"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="px-4 sm:px-6 rounded">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-xl font-bold text-gray-800">
                          Save changes
                        </Dialog.Title>
                      </div>
                      <div className="mb-3">
                        <p className="text-sm text-gray-500">
                          Do you want to save your changes before closing the
                          card?
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => clearState()}
                    >
                      Cancel
                    </button>
                    <div className="flex">
                      <div>
                        <Button
                          bg={"tertiary"}
                          message={"Save changes"}
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
