import React, { useContext, useState } from "react";
import Avatar from "../avatars/Avatar";
import Dropdown from "../dropdowns/Dropdown";
import { UserContext } from "../../store/user-context";
import ReactTimeago from "react-timeago";

function CommentBox(props) {
  const { comments, entityId, handleUpdateItem, handleCreateComment, handleStatusCommentNotApproved } = props;
  const { userInfo } = useContext(UserContext);
  const labelStlye = "uppercase text-xs text-gray-600 pl-0 font-normal";
  return (
    <>
      <div className="text-left pt-2">
        <h3 className={labelStlye}>Comments</h3>
        <p className="text-sm italic text-gray-500 mb-2">
          Add comments here and your project contact will be notified.
        </p>
        <CommentInput
          handleCreateComment={handleCreateComment}
          handleUpdateItem={handleUpdateItem}
          entityId={entityId}
          userInfo={userInfo}
          handleStatusCommentNotApproved={handleStatusCommentNotApproved}
        />
      </div>
      {comments.length > 0 && (
        <div>
          <ul className="border mt-2">
            {comments.map((comment) => (
              <li key={comment.id}>
                <Comment
                  handleUpdate={handleUpdateItem}
                  comment={comment}
                  userInfo={userInfo}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export default CommentBox;

const CommentInput = ({ handleCreateComment, entityId, userInfo,handleStatusCommentNotApproved }) => {
  const [comment, setComment] = useState("");
  const obj = { text: comment, entityId };
  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateComment(obj, () => setComment(''));
    handleStatusCommentNotApproved();
  };
  const isButtonVisible = comment.trim().length > 0;

  return (
    <form
      onSubmit={(e) => handleSubmit(e)}
      className="flex border p-2 items-center"
    >
      <Avatar name={userInfo.name.charAt(0)} />
      <input
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="p-2 flex-1 mx-2 text-sm"
        placeholder="Ask a question or add a comment"
      />
      <button
        disabled={!isButtonVisible}
        type="submit"
        className={`bg-accent text-white rounded-lg px-4 py-2 text-sm transition-all ${
          isButtonVisible ? "opacity-100" : "opacity-0"
        }`}
      >
        Send
      </button>
    </form>
  );
};

const Comment = ({ comment, handleUpdate, userInfo }) => {
  const { text, creator, createdTimestamp } = comment;
  const isMyComment = creator.userId === userInfo.id;

  const handleDelete = () => {
    const deleteObj = { prop: "isDeleted", value: true };
    const itemObj = { itemId: comment.id, collection: "comments" };
    handleUpdate(itemObj, deleteObj);
  };

  const options = [
    {
      action: "Delete comment",
      handleSelect: () => handleDelete(),
      color: "red",
    },
  ];
  return (
    <div className="py-2">
      <div className="flex items-center ml-2 ">
        <Avatar name={creator.name?.charAt(0)} />
        <div className="text-left flex-1">
          <div className="text-xs">
            <span className="font-medium ml-2 mr-1">{creator.name}</span>
            <span className="text-xs text-gray-400">
              <ReactTimeago date={createdTimestamp} />
            </span>
          </div>
          <div>
            <p className="ml-2 text-sm text-left">{text}</p>
          </div>
        </div>
        {isMyComment && <Dropdown options={options} />}
      </div>
    </div>
  );
};
