import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dropdown(props) {
  const { options } = props;

  const position = props.position ?? "right-0";

  const icon = props.icon ?? (
    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
  );
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-tertiary-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          <span className="sr-only">Open options</span>
          {icon}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${position}`}
        >
          <div className="py-1">
            {options
              .filter((item) => !item.isHidden)
              .map((option) => {
                const colors = getColors(option.color);
                const { textDark, textLight, bgLight } = colors;
                return (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={option.handleSelect}
                        aria-label={option.label ? option.label : option.action}
                        className={classNames(
                          active ? `${bgLight} ${textDark}` : textLight,
                          "block px-4 py-2 text-sm w-full text-left"
                        )}
                      >
                        {option.action}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const getColors = (color) => {
  switch (color) {
    case "red":
      return {
        textLight: "text-red-700",
        textDark: "text-red-900",
        bgLight: "bg-red-100",
      };
    case "primary":
      return {
        textLight: "text-primary",
        textDark: "text-primary-700",
        bgLight: "bg-primary-100",
      };
    default:
      return {
        textLight: "text-slate-700",
        textDark: "text-slate-900",
        bgLight: "bg-slate-100",
      };
  }
};
