import { BriefcaseIcon, CogIcon } from "@heroicons/react/24/outline";

export function getNaviagtionItems(page) {
  switch (page) {
    case "user":
      return ["My Workspaces", "My Info"];
    case "workspace":
      return ["Projects", "New Project", "Team"];
    case "project":
      return ["Scope", "Client", "Settings"];
    case "scope":
      return ["Overview"];
    default:
      return null;
  }
}

export function getSidebarNavigationItems(page) {
  switch (page) {
    case "workspace":
      return [
        { name: "Projects", icon: BriefcaseIcon, id: "projects" },
        { name: "Settings", icon: CogIcon, id: "settings" },
      ];
    default:
      return [
        { name: "Projects", icon: BriefcaseIcon, id: "projects" },
        { name: "Settings", icon: CogIcon, id: "settings" },
      ];
  }
}
