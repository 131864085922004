import { createContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout } from "../firebase";
import { hotjar } from "react-hotjar";
import { UserInfo } from "os";

interface IUserInfo {
  id?: string;
  email?: string;
  ref?: string;
  workspaces?: Array<{
    workspaceId: string;
  }>
}

interface IUserContext {
  userInfo: IUserInfo;
  invites: any[];
  scopes: any[];
  authLoading: boolean,
  user: any;
  hasSeenScope: boolean,
  setHasSeenScope: (value: boolean) => void,
  setHasLoggedIn: (value: boolean) => void,
  hasLoggedIn: boolean,
  scopesLoading: boolean,
  workspaceScopes: any[];
  projects: any[];
  valueAdds: any[];
  isUserLoading: boolean,
  fetchWorkspaceProjects: (workspaceId: string) => void,
  setIsUserUpdated: (value: boolean) => void,
  setIsValueAddsUpdated: (value: boolean) => void,
}


export const UserContext = createContext<IUserContext>({
  userInfo: {} as any,
  invites: [],
  scopes: [],
  authLoading: true,
  user: {},
  hasSeenScope: false,
  setHasSeenScope: () => { },
  setHasLoggedIn: () => { },
  hasLoggedIn: false,
  scopesLoading: false,
  workspaceScopes: [],
  projects: [],
  valueAdds: [],
  isUserLoading: false,
  fetchWorkspaceProjects: () => { },
  setIsUserUpdated: () => { },
  setIsValueAddsUpdated: () => { },
});

interface IProps {
  children: React.ReactNode;
}


export function UserContextProvider(props: IProps) {
  const [user, authLoading] = useAuthState(auth as any);
  const [userInfo, setUserInfo] = useState<IUserInfo>({});

  const [invites, setInvites] = useState<Array<any>>([]);
  const [scopes, setScopes] = useState<Array<any>>([]);
  const [projects, setProjects] = useState<Array<any>>([]);
  const [valueAdds, setValueAdds] = useState<Array<any>>([]);
  const [workspaceScopes, setWorkspaceScopes] = useState<Array<any>>([]);

  const [scopesLoading, setScopesLoading] = useState(true);
  const [invitesLoading, setInvitesLoading] = useState(true);
  const [workspaceScopesLoading, setWorkspaceScopesLoading] = useState(true);
  const [valueAddsLoading, setValueAddsLoading] = useState(true);

  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [isValueAddsUpdated, setIsValueAddsUpdated] = useState(false);
  const [isHotjarInitialized, setIsHotjarInitialized] = useState(false);
  const [hasSeenScope, setHasSeenScope] = useState(false);
  const [hasLoggedIn, setHasLoggedIn] = useState(false);


  useEffect(() => {
    //Check local storage
    const hasSeen = window.localStorage.getItem("hasSeenScope");
    if (!hasSeen) {
      return;
    }
    if (hasSeen === "true") {
      setHasSeenScope(true);
    }
  }, [hasSeenScope]);

  useEffect(() => {

    if (userInfo !== undefined && !authLoading && userInfo.id && !isHotjarInitialized) {
      hotjar.identify(userInfo.id, { email: userInfo.email });
      setIsHotjarInitialized(true);
      console.log("hotjar initialized");
    }
    //eslint-disable-next-line
  }, [userInfo, authLoading, isHotjarInitialized]);

  useEffect(() => {
    const isUser = user ? true : false;
    // eslint-disable-next-line
  }, [user, authLoading]);

  const fetchUser = async () => {
    try {
      db.collection("users")
        .where("uid", "==", user?.uid)
        .onSnapshot((value) => {
          const doc = value.docs[0];
          if (!doc) return;
          const data = doc.data() as IUserInfo;
          data.ref = doc.id;
          data.id = doc.id;
          setUserInfo(data);
          fetchInvites();
          fetchScopes();
          if (data.workspaces !== undefined && data.workspaces[0] !== undefined) {
            fetchWorkspaceProjects(data.workspaces[0].workspaceId);
            fetchWorkspaceScopes(data.workspaces[0].workspaceId);
            fetchWorkspaceValueAdds(data.workspaces[0].workspaceId);
          } else {
            setWorkspaceScopesLoading(false);
            setValueAddsLoading(false);
          }

          setIsUserUpdated(false);
          //window.heap.identify(user.uid, "uid");
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (userInfo && isValueAddsUpdated && userInfo.workspaces !== undefined && userInfo.workspaces[0] !== undefined) {
      fetchWorkspaceValueAdds(userInfo.workspaces[0].workspaceId);
    }
    // eslint-disable-next-line
  }, [isValueAddsUpdated]);

  const fetchInvites = async () => {
    let fetchedInvites: Array<any> = [];

    if (user?.email) {
      try {
        const query = await db
          .collection("invites")
          .doc(user.email)
          .collection("invites")
          .where("status", "!=", "cancelled")
          .get();
        query.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          data.title = data.workspaceTitle;
          data.entityId = data.workspaceId;
          data.entityType = "workspace";
          fetchedInvites.push(data);
        });
        fetchedInvites = fetchedInvites.filter(
          (item) => item.status === "pending"
        );
        setInvites(fetchedInvites);
        setInvitesLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const fetchScopes = async () => {
    let fetchedScopes: Array<any> = [];
    if (user) {
      try {
        const query = await db
          .collection("scopes")
          .where("approverIds", "array-contains", user.uid)
          .get();
        query.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          data.entityType = "scope";
          fetchedScopes.push(data);
        });
        setScopes(fetchedScopes);
        setScopesLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const fetchWorkspaceScopes = async (workspaceId: string) => {
    let fetchedScopes: Array<any> = [];
    if (!workspaceId) {
      setWorkspaceScopes([]);
      setWorkspaceScopesLoading(false);
      return;
    }
    try {
      const query = await db
        .collection("scopes")
        .where("workspaceId", "==", workspaceId)
        .get();
      query.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.entityType = "scope";
        fetchedScopes.push(data);
      });
      setWorkspaceScopes(fetchedScopes);
      setWorkspaceScopesLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspaceProjects = async (workspaceId: string) => {
    if (!workspaceId) {
      setProjects([]);
      return;
    }
    let fetchedProjects: Array<any> = [];
    try {
      const query = await db
        .collection("projects")
        .where("workspaceId", "==", workspaceId)
        .where("isDeleted", "==", false)
        .get();
      query.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.entityType = "project";
        fetchedProjects.push(data);
      });
      setProjects(fetchedProjects);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspaceValueAdds = async (workspaceId: string) => {
    if (!workspaceId) {
      setValueAdds([]);
      setValueAddsLoading(false);
      setIsValueAddsUpdated(false);
      return;
    }
    let fetchedValueAdds: Array<any> = [];
    try {
      const query = await db
        .collection("valueAdds")
        .where("workspaceId", "==", workspaceId)
        .where("isDeleted", "==", false)
        .get();
      query.forEach((doc) => {
        let data = doc.data();
        data.id = doc.id;
        data.entityType = "valueAdd";
        fetchedValueAdds.push(data);
      });
      setValueAdds(fetchedValueAdds);
      setValueAddsLoading(false);
      setIsValueAddsUpdated(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (authLoading) return;
    if (user) {
      setHasLoggedIn(true);
      fetchUser();
    }
    if (!user) {
      logout();
      //navigate("/login");
      return;
    }
    //eslint-disable-next-line
  }, [user, authLoading, isUserUpdated]);

  const isUserLoading =
    authLoading ||
    scopesLoading ||
    invitesLoading ||
    workspaceScopesLoading ||
    valueAddsLoading;

  const contextValue = {
    userInfo,
    invites,
    scopes,
    scopesLoading,
    workspaceScopes,
    projects,
    valueAdds,
    authLoading,
    isUserLoading,
    setIsUserUpdated,
    setIsValueAddsUpdated,
    user,
    hasSeenScope,
    setHasSeenScope,
    setHasLoggedIn,
    hasLoggedIn,
    fetchWorkspaceProjects,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {props.children}
    </UserContext.Provider>
  );
}
