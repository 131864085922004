import {
  ChevronRightIcon,
  PaperClipIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import React, { useContext, useMemo } from "react";
import { useState } from "react";
import { sortArrayByOrder } from "../../utils/sortArray";
import Button from "../buttons/Button";
import ButtonIcon from "../buttons/ButtonIcon";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";
import InputLink from "../inputs/InputLink";
import InputSelection from "../inputs/InputSelection";
import ModalValueAdd from "../modals/ModalAddValueAdd";
import Steps from "../steps/Steps";
import TableReviewScope from "./TableReviewScope";
import TemplateFrame from "../../images/template_crop.png";
import ScratchFrame from "../../images/scratch_crop.png";
import Dropdown from "../dropdowns/Dropdown";
import ModalConfirm from "../modals/ModalConfirm";
import ButtonExample from "../buttons/ButtonExample";
import ModalExample from "../modals/ModalExample";
import { ExampleContext } from "../../store/example-context";
import { createScopeValueAdd } from "../../services/ValueAddService";
import { UserContext } from "../../store/user-context";
import { FeedbackContext } from "../../store/feedback-context";
import { updateCollectionItem } from "../../services/SharedService";
import { ModalContext } from "../../store/modal-context";
import Avatar from "../avatars/Avatar";
import { CardVA } from "../card/CardVA";
import InputSingle from "../inputs/InputSingle";
import Fuse from "fuse.js";
import { FunctionContext } from "../../store/function-context";
import { formatCurrency } from "../../utils/formatCurrency";

const stepsArr = [
  { label: "Create scope", value: "scope" },
  { label: "Optional Service", value: "value-adds" },
  { label: "Send to client", value: "send" },
];

function TableCreateScope(props) {
  const {
    phases,
    items,
    handleCreateItem,
    handleUpdateRow,
    handleUpdateItem,
    handleCreatePhase,
    handleCreateValueAdd,
    handleCreateScope,
    handleAddItemFromRequest,
    handleApproveScope,
    valueAdds,
    scopeVAs,
    projectValueAdds,
    projectId,
    currency,
    description,
    projectTitle,
    templates,
    handleCreateScopeFromTemplate,
    isInviteVisible,
    workspaceId,
    labels,
  } = props;
  const [step, setStep] = useState("scope");
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const exampleCtx = useContext(ExampleContext);
  const { exampleId, setExampleId, imageURL } = exampleCtx;
  const { setIsNavigating } = useContext(FeedbackContext);
  const approvers = props.approvers ?? [];

  const handleToggleValueAdd = (id) => {
    let selectedValueAdds = projectValueAdds;
    const valueAddExists = selectedValueAdds.some((item) => item.id === id);
    const valueAdd = valueAdds.find((item) => item.id === id);
    if (!valueAddExists) {
      selectedValueAdds = [...selectedValueAdds, valueAdd];
    } else {
      selectedValueAdds = selectedValueAdds.filter((item) => item.id !== id);
    }
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "valueAdds", value: selectedValueAdds };
    return handleUpdateItem(itemObj, obj);
  };

  const handleSetStep = (direction) => {
    if (step === "send" && direction === "next") {
      setIsNavigating(true);
      return handleCreateScope().then(() => {
        setIsNavigating(false);
      });
    } else {
      const currentStep = stepsArr.findIndex((item) => item.value === step);
      const nextIndex =
        direction === "next" ? currentStep + 1 : currentStep - 1;
      const nextStep = stepsArr[nextIndex].value;
      setStep(nextStep);
    }
  };

  const getSection = (step) => {
    switch (step) {
      case "scope":
        return (
          <>
            {/* <ActionBar /> */}
            {phases.map((phase) => (
              <TableCreate
                key={phase.id}
                phase={phase}
                items={sortArrayByOrder(
                  items.filter((item) => item.phaseId === phase.id)
                )}
                handleCreateItem={handleCreateItem}
                handleUpdateRow={handleUpdateRow}
                handleUpdateItem={handleUpdateItem}
                currency={currency.symbol}
              />
            ))}
            {phases.length > 0 ? (
              <>
                <div class="flex mb-12">
                  <Button
                    handleClick={() => handleCreatePhase()}
                    message="Add phase"
                    bg="tertiary"
                  />
                </div>
                <TableTotal currency={currency.symbol} items={items} />
              </>
            ) : (
              <StartScopeOption
                templates={templates}
                projectId={projectId}
                handleCreateScopeFromTemplate={handleCreateScopeFromTemplate}
              />
            )}
          </>
        );
      case "value-adds":
        return (
          <ValueAddsTable
            handleToggleValueAdd={handleToggleValueAdd}
            valueAddsSelected={projectValueAdds}
            valueAdds={valueAdds}
            scopeVAs={scopeVAs}
            currency={currency.symbol}
            handleUpdateItem={handleUpdateItem}
            projectId={projectId}
            isInWorkspaceView={true}
            workspaceId={workspaceId}
            labels={labels}
          />
        );
      case "send":
        return (
          <TableReviewScope
            isReview={true}
            scope={{
              phases,
              items,
              valueAdds: scopeVAs,
              valueAddsRequested: items.filter(
                (item) => item.type === "scopeVA"
              ),
              description,
              currency: currency.symbol,
            }}
            projectId={projectId}
            handleAddItem={handleAddItemFromRequest}
            handleUpdateItem={handleUpdateItem}
            handleApproveScope={handleApproveScope}
          />
        );
      default:
        return "scope";
    }
  };

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleDeleteProject = () => {
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "isDeleted", value: true };
    return handleUpdateItem(itemObj, obj, handleReloadPage);
  };

  return (
    <>
      {step === "value-adds" && <ButtonExample exampleId={"va-library"} />}
      <ModalExample
        imageURL={imageURL}
        isOpen={exampleId === "va-library"}
        setIsOpen={setExampleId}
      />
      <ModalConfirm
        isOpen={activeModal === "delete-project"}
        buttonMessage={"Yes, delete"}
        title={"Are you sure you want to delete this project?"}
        description={
          "If you delete this project, all of its data will be lost."
        }
        handleAction={() => handleDeleteProject()}
      />
      <ModalValueAdd
        isOpen={activeModal === "value-add"}
        setIsOpen={setActiveModal}
        handleCreate={handleCreateValueAdd}
        valueAdds={valueAdds}
      />
      <div class="w-full px-4 sm:px-8 py-6 sm:py-10 2xl:py-12 box-border">
        <div className="flex justify-start">
          <Steps
            steps={stepsArr}
            currentStep={step}
            handleSetStep={setStep}
            margin={"mt-2 mb-6"}
          />
        </div>
        <div class="w-full max-w-7xl mx-auto">
          <ReviewScopeHeading
            isInviteVisible={isInviteVisible}
            title={getInfo(step, false, projectTitle).title}
            description={getInfo(step, phases.length > 0).description}
            approvers={approvers}
          />
          {getSection(step)}
        </div>
        {phases.length > 0 && (
          <FooterBanner
            step={step}
            handleSetNext={handleSetStep}
            handleCreateScope={handleCreateScope}
          />
        )}
      </div>
    </>
  );
}

export default TableCreateScope;

export const ReviewScopeHeading = (props) => {
  const { title, description, isInviteVisible } = props;
  const approvers = props.approvers ?? [];
  const { setActiveModal } = useContext(ModalContext);
  return (
    <div className="flex">
      <ActionHeading title={title} description={description} />
      <div className="ml-auto">
        <div className="mr-2 flex items-center relative">
          <ul className="flex items-center justify-center mr-2">
            {approvers.map((item) => {
              return (
                <li className="ml-[-.5em] shadow-sm">
                  <Avatar size={"h-6 w-6 text-xs"} name={item.name} />
                </li>
              );
            })}
          </ul>
        </div>
        {isInviteVisible && (
          <div>
            <Button
              handleClick={() => setActiveModal("invite-scope")}
              bg="tertiary"
              message="Invite client to project scope"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export const ActionHeading = ({ title, description }) => {
  return (
    <div className="flex-1 mr-2">
      <h1 class="font-medium text-2xl sm:text-3xl mb-2 text-left text-gray-800">
        {title}
      </h1>
      {description && (
        <p class="text-sm mb-5 text-left text-gray-600">{description}</p>
      )}
    </div>
  );
};

const StartScopeOption = ({
  templates,
  handleCreateScopeFromTemplate,
  projectId,
}) => {
  const templateOptions = templates.map((template) => ({
    value: template.id,
    label: template.title,
  }));
  const { handleUpdateItem } = useContext(FunctionContext);

  const handleCreate = (templateId) => {
    handleCreateScopeFromTemplate(templateId);
  };

  const handleStartFromScratch = () => {
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "isFromScratch", value: true };
    const feedbackObj = { message: "Project created from scratch" };
    handleUpdateItem(itemObj, obj, feedbackObj);
  };

  return (
    <div className="flex md:space-x-5 flex-col md:flex-row">
      <StartFromScratchButton handleCreate={handleStartFromScratch} />
      <CreateTemplateButton
        options={templateOptions}
        handleCreate={handleCreate}
      />
    </div>
  );
};

const CreateTemplateButton = ({ options, handleCreate }) => {
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [template, setTemplate] = useState(
    options.length > 0 ? options[0].value : ""
  );
  const handleToggle = () => {
    if (options.length > 0) {
      setIsTemplateOpen(!isTemplateOpen);
    }
  };

  return (
    <div className="flex flex-1 my-2 justify-center items-center cursor-pointer rounded-2xl shadow bg-white hover:bg-tertiary-50">
      <div className="text-center flex items-center flex-1 h-full">
        {!isTemplateOpen ? (
          <button onClick={() => handleToggle()} className="flex-1 h-full">
            <p className="text-accent font-medium text-lg">
              Use a saved template
            </p>
            {options.length < 1 && (
              <p className="text-red-400 uppercase text-xs">
                You have no saved templates
              </p>
            )}
          </button>
        ) : (
          <div className="flex-1 flex flex-col items-center px-1">
            <div className="space-y-1">
              <InputSelection
                value={template}
                setValue={setTemplate}
                label="Use template"
                options={options}
              />
              <Button
                handleClick={() => handleCreate(template)}
                message="Create template"
              />
            </div>
          </div>
        )}
        <button onClick={() => handleToggle()}>
          <img
            className="w-72"
            alt="Create from template"
            src={TemplateFrame}
          />
        </button>
      </div>
    </div>
  );
};

const StartFromScratchButton = ({ handleCreate }) => {
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  return (
    <div className="flex my-2 flex-1 justify-center items-center cursor-pointer rounded-2xl shadow bg-white hover:bg-accent-50">
      <div className="text-center flex items-center flex-1 h-full">
        {!isTemplateOpen ? (
          <button
            onClick={() => setIsTemplateOpen(!isTemplateOpen)}
            className="flex-1 h-full"
          >
            <p className="text-accent font-medium text-lg">
              Start from scratch
            </p>
          </button>
        ) : (
          <div className="flex-1 flex flex-col items-center px-1">
            <Button
              handleClick={() => handleCreate()}
              message="Confirm start from scratch"
            />
          </div>
        )}
        <button onClick={() => setIsTemplateOpen(!isTemplateOpen)}>
          <img
            className="w-72 max-w-2/3"
            alt="Start from scratch"
            src={ScratchFrame}
          />
        </button>
      </div>
    </div>
  );
};

/*
const ActionBar = () => {
  return (
    <div className="w-100 flex p-2">
      <div className="flex-1 flex justify-start items-center">
        <InputSelection
          label={"Choose Template"}
          isLabelHidden={true}
          name={"choose-template"}
          options={[]}
          placeholder={"Choose Template"}
        />
      </div>
      <div className="space-x-2 flex justify-end items-center">
        <div className="hidden md:block">
          <Button message="See examples" />
        </div>
        <Button
          bg={"white"}
          isResponsive={true}
          icon={<BookmarkIcon className="md:w-4 md:h-4 h-5 w-5 md:mr-2" />}
          message={"Save as Template"}
        />
        <Button
          bg={"white"}
          isResponsive={true}
          icon={<DocumentIcon className="md:w-4 md:h-5 h-5 w-6 md:mr-2" />}
          message={"Attach Invoice"}
        />
      </div>
    </div>
  );
};
*/

const TableTotal = ({ items, currency }) => {
  const totalCost = items.reduce(
    (acc, item) => +acc + +item.cost * +item.quantity ?? 1,
    0
  );
  return (
    <div class="w-full bg-white p-6 border border-neutral-300 rounded-xl mb-20">
      <table class="w-full table-fixed border-collapse">
        <tbody>
          <tr class="flex w-full lg:table-row">
            <td class="text-lg text-left lg:px-4" colspan="4">
              <p class="w-full text-lg 2xl:text-xl font-bold">Total</p>
            </td>
            <td class="ml-auto text-lg text-right lg:px-4">
              <p class="w-full text-right text-lg 2xl:text-xl font-bold">
                {currency}
                {totalCost}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TableCreate = ({
  phase,
  items,
  handleCreateItem,
  handleUpdateRow,
  handleUpdateItem,
  currency,
}) => {
  const [title, setTitle] = useState(phase.title);
  const totalCost = items.reduce(
    (acc, item) => +acc + +item.cost * +item.quantity ?? 1,
    0
  );
  const handleSubmit = (e) => {
    e.preventDefault();
    const itemObj = { collection: "phases", itemId: phase.id };
    const obj = { prop: "title", value: title };
    return handleUpdateItem(itemObj, obj);
  };

  const handleDelete = () => {
    const itemObj = { collection: "phases", itemId: phase.id };
    const obj = { prop: "isDeleted", value: true };
    return handleUpdateItem(itemObj, obj);
  };

  const options = [
    {
      action: "Delete phase",
      handleSelect: () => handleDelete(),
      color: "red",
    },
  ];
  return (
    <div class="w-full bg-white p-4 pb-1 2xl:p-6 2xl:pb-2 border border-neutral-300 rounded-xl mb-6">
      <div className="flex w-full items-center mb-5">
        <form
          className="flex items-center justify-center flex-1"
          onSubmit={(e) => handleSubmit(e)}
        >
          <input
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            type="text"
            class="flex-1 text-lg 2xl:text-xl font-bold mx-1 border-none p-2 rounded focus:ring-2 focus:ring-tertiary-500 focus:ring-opacity-50"
            placeholder="Name phase"
          />
          <Button
            type="submit"
            disabled={phase.title === title}
            isInvisible={phase.title === title}
            message="Update"
            label="Update phase name"
          />
        </form>
        <Dropdown options={options} />
      </div>
      <table class="w-full table-fixed border-collapse">
        <colgroup class="hidden lg:table-column-group">
          <col class="w-1/12"></col>
          <col class="w-1/6"></col>
          <col class="w-1/3"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
        </colgroup>
        <thead class="">
          <tr class="flex lg:table-row">
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Ref
            </th>
            <th class="lg:w-auto text-sm text-left font-bold uppercase lg:px-4 pb-2">
              Task/item
            </th>
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Details
            </th>
            <th class="hidden lg:flex justify-center text-sm text-center font-bold uppercase px-4 pb-2">
              <PaperClipIcon className="md:w-4 md:h-4 h-5 w-5 md:mr-2" />
              <span className="sr-only">Attachments</span>
            </th>
            <th class=" lg:w-auto text-sm text-left font-bold uppercase lg:px-4 pb-2">
              Quote
            </th>
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Qty
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <TableItem
              key={item.id}
              item={item}
              handleUpdateRow={handleUpdateRow}
              handleUpdateItem={handleUpdateItem}
            />
          ))}
          <TableAddItem
            phaseItemLength={
              items.filter((item) => item.phaseId === phase.id).length
            }
            phase={phase}
            handleCreateItem={handleCreateItem}
          />
        </tbody>
        <tfoot>
          <tr class="flex w-full lg:table-row border-t border-neutral-300">
            <td
              class="text-base 2xl:text-lg text-left font-bold lg:px-4 py-5"
              colspan="4"
            >
              Sub-total
            </td>
            <td class="ml-auto text-base 2xl:text-lg text-right font-bold lg:px-4 py-5">
              {currency}
              {totalCost}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableItem = ({ item, handleUpdateRow, handleUpdateItem }) => {
  const [title, setTitle] = useState(item.title);
  const [description, setDescription] = useState(item.description);
  const [quantity, setQuantity] = useState(item.quantity);
  const [cost, setCost] = useState(item.cost);
  const [link, setLink] = useState(item.attachmentLink);

  const isButtonActive =
    title !== item.title ||
    description !== item.description ||
    quantity !== item.quantity ||
    cost !== item.cost ||
    link?.url !== item.attachments[0]?.url;

  const handleDelete = () => {
    const itemObj = { collection: "items", itemId: item.id };
    const obj = { prop: "isDeleted", value: true };
    return handleUpdateItem(itemObj, obj);
  };

  const handleUpdate = () => {
    const obj = {
      title,
      description,
      quantity,
      cost,
      attachments: [link],
      attachmentLink: link?.url ?? "",
    };
    return handleUpdateRow(item.id, obj);
  };

  const handleSetLink = (newLink) => {
    return setLink(newLink);
  };

  const options = [
    {
      action: "Delete item",
      handleSelect: () => handleDelete(),
      color: "red",
    },
  ];

  return (
    <tr class="relative flex flex-col py-3 w-full lg:table-row lg:w-auto lg:py-0 border-t border-gray-200">
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4 italic text-xs text-gray-600">
        {item.ref}
      </td>
      <td class=" text-left lg:w-auto lg:px-3 lg:py-3 2xl:py-4 ">
        <input
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Add service/product title"
          className="border-none p-2 w-full my-2 text-gray-800 text-sm"
        />
      </td>
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        <textarea
          rows="2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="border-none p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="Describe exactly what is included in this task/item in a way your client will understand. The more detailed you are, the less room there is for confusion."
        ></textarea>
      </td>
      <td class="lg:px-3 lg:py-3 2xl:py-4">
        <div className="flex justify-center items-center">
          <InputLink handleSetLink={handleSetLink} link={link} />
        </div>
      </td>
      <td class="absolute w-1/3 top-3 right-0 lg:static lg:w-auto text-left lg:px-3 lg:py-3 2xl:py-4">
        <input
          required
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          className="border-none p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="€"
          type="number"
        />
      </td>
      <td class="text-left flex lg:table-cell lg:px-3 lg:py-3 2xl:py-4">
        <input
          required
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="border-none p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="00"
          type="number"
        />
      </td>
      <td class="order-last text-left lg:px-3 lg:py-3 2xl:py-4">
        <div class="flex justify-end">
          {isButtonActive ? (
            <Button
              isInvisible={!isButtonActive}
              handleClick={() => handleUpdate()}
              type={"button"}
              message={"Update"}
            />
          ) : (
            <Dropdown options={options} />
          )}
        </div>
      </td>
    </tr>
  );
};

const TableAddItem = ({ handleCreateItem, phase, phaseItemLength }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState(0);
  const [link, setLink] = useState("");

  const handleSetLink = (newLink) => {
    return setLink(newLink);
  };

  const clearData = () => {
    setTitle("");
    setDescription("");
    setQuantity(1);
    setCost(0);
    setLink("");
  };

  const handleCreate = () => {
    const order = phaseItemLength;
    const obj = {
      title,
      description,
      quantity,
      cost,
      order,
      phaseCode: phase.code,
      attachmentLink: link?.url ?? "",
    };
    return handleCreateItem(phase.id, obj).then(() => clearData());
  };

  const isNotConfirmed =
    title.trim() === "" && description === "" && cost === 0 && quantity === 1;

  return (
    <tr
      class={`relative flex flex-col py-3 w-full lg:table-row lg:w-auto lg:py-0 border-t bg-gray-50 ${
        !isNotConfirmed ? "border-red-400" : "border-gray-200"
      } `}
    >
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        {!isNotConfirmed && (
          <div className="absolute left-32 top-[-1em] z-20 text-sm shadow bg-red-100 text-red-800 font-bold py-2 px-5">
            Please hit "Confirm" to add this item
          </div>
        )}
      </td>
      <td class=" text-left lg:w-auto lg:px-3 lg:py-3 2xl:py-4 ">
        <input
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Add service/product title"
          className="border-none bg-gray-50 p-2 w-full my-2 text-gray-800 text-sm"
        />
      </td>
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        <textarea
          rows="2"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="border-none bg-gray-50 p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="Describe exactly what is included in this task/item in a way your client will understand. The more detailed you are, the less room there is for confusion."
        ></textarea>
      </td>
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        <div className="flex justify-center items-center">
          <InputLink handleSetLink={handleSetLink} link={link} />
        </div>
      </td>
      <td class="absolute w-1/3 top-3 right-0 lg:static lg:w-auto text-left lg:px-3 lg:py-3 2xl:py-4">
        <input
          required
          value={cost}
          onChange={(e) => setCost(e.target.value)}
          className="border-none bg-gray-50 p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="$$"
          type="number"
        />
      </td>
      <td class="text-left flex lg:table-cell lg:px-3 lg:py-3 2xl:py-4">
        <input
          required
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="border-none bg-gray-50 p-2 w-full my-2 text-gray-800 text-sm"
          placeholder="00"
          type="number"
        />
      </td>
      <td class="order-last text-left lg:px-3 lg:py-3 2xl:py-4">
        <div class="flex justify-end">
          <Button
            handleClick={() => handleCreate()}
            disabled={title.trim() === ""}
            isInvisible={title.trim() === ""}
            type={"button"}
            message={"Confirm"}
          />
        </div>
      </td>
    </tr>
  );
};

export const FooterBanner = ({
  handleSetNext,
  isDisabled,
  step,
  isBackInvisible,
  buttonMessage,
}) => {
  let message = getInfo(step).buttonMessage;
  if (buttonMessage) {
    message = buttonMessage;
  }
  return (
    <div
      className={`bg-tertiary w-full py-2 fixed left-0 bottom-0 pl-16 ${
        isDisabled ? "opacity-20" : "opacity-100"
      }`}
    >
      <div className="flex max-w-7xl mx-auto">
        <div className="flex w-full px-4 sm:px-8">
          <div className="flex flex-1 justify-start">
            <Button
              disabled={isDisabled}
              isInvisible={step === "scope" || isBackInvisible}
              handleClick={() => handleSetNext("back")}
              bg={"white"}
              message={"Back"}
            />
          </div>
          <div className="flex flex-1 justify-end space-x-4">
            {/*
            <Button
              //handleClick={() => handleSetNext("next")}
              bg={"white"}
              message={"Save scope as template"}
            /> */}
            <Button
              disabled={isDisabled}
              handleClick={() => handleSetNext("next")}
              bg={"white"}
              message={message}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getInfo = (step, isStarted, projectTitle) => {
  switch (step) {
    case "scope":
      return {
        next: "value-adds",
        title: "Create your project scope",
        buttonMessage: "Go to Optional Services",
        description: isStarted
          ? "Add the tasks/items you will include in this project, as well as the quote and quantity for each item. You can also divide your project into separate phases."
          : "Choose whether you want to start from scratch or if you’d like to use an existing template. Any project you create can be saved as a template to save you time for your future projects.",
      };
    case "value-adds":
      return {
        last: "scope",
        title: "Upsell with Optional Services",
        buttonMessage: "Review scope",
        description: (
          <p>
            These are optional extras that may enhance this project for your
            client or may be common client requests for this type of project.
            This page will populate with time as you create more value-adds.
            Examples:{" "}
            <span className="font-italic">
              Half day of training, One round of changes, Booking form, Photo
              edits, Review.
            </span>
          </p>
        ),
      };
    case "send":
      return {
        last: "Optional Services",
        title: `New project scope: ${projectTitle}`,
        buttonMessage: "Go to Approve & Share center",
        description: "",
      };
    default:
      return {
        buttonMessage: "Next",
      };
  }
};

export const ValueAddsTable = ({
  valueAdds,
  scopeVAs,
  currency,
  isClient,
  isInWorkspaceView,
  isFromDashboard,
  projectId,
  workspaceId,
  labels,
}) => {
  const { userInfo } = useContext(UserContext);
  const { handleReset, setAlert } = useContext(FeedbackContext);
  const { setActiveModal } = useContext(ModalContext);
  const [query, setQuery] = useState("");

  const handleScopeCreateValueAdd = (obj) => {
    obj.vaId = obj.id;
    delete obj.id;
    try {
      return createScopeValueAdd(obj, userInfo).then(() =>
        handleReset("Service deleted")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleDeleteValueAdd = (id, collection) => {
    collection = collection ?? "scopeVAs";
    const obj = { prop: "isDeleted", value: true };
    try {
      return updateCollectionItem(collection, id, obj).then(() =>
        handleReset("Optional Service deleted")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleToggleValueAdd = (isCreated, obj) => {
    if (isCreated) {
      handleDeleteValueAdd(obj);
    } else {
      obj.projectId = projectId;
      handleScopeCreateValueAdd(obj);
    }
  };

  const filteredItems = useMemo(() => {
    const fuzzy = new Fuse(valueAdds, {
      keys: ["title", "labels"],
      threshold: 0.3,
    });
    const fuzzyResults = fuzzy.search(query);
    const filteredItems = query
      ? fuzzyResults.map((item) => item.item)
      : valueAdds;
    return filteredItems;
  }, [valueAdds, query]);

  return (
    <div className="p-2">
      {!isClient && (
        <div className="w-full flex mb-5">
          <Button
            bg="tertiary"
            handleClick={() => setActiveModal("value-add")}
            message="Create a new Service"
            icon={<PlusIcon className="h-4 w-4" />}
          />
        </div>
      )}
      <div>
        {!isInWorkspaceView && (
          <>
            <h3 className="uppercase font-bold text-left text-sm text-gray-700 mt-2">
              Service library
            </h3>
            <p className="text-sm text-left text-gray-600 mb-2">
              Build a library of your offerings, which can be quickly added to
              any project.
            </p>
          </>
        )}
        <SearchForm query={query} setQuery={setQuery} />
        <div class="space-y-2">
          {filteredItems.map((item) => (
            <ValueAddSelection
              key={item.id}
              scopeVAs={scopeVAs}
              handleToggleValueAdd={handleToggleValueAdd}
              currency={currency.symbol}
              valueAdd={item}
              handleDeleteValueAdd={handleDeleteValueAdd}
              isInWorkspaceView={isInWorkspaceView}
              isFromDashboard={isFromDashboard}
              workspaceId={workspaceId}
              labels={labels}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const SearchForm = ({ query, setQuery }) => {
  return (
    <div className="mb-2">
      <form name="Search for service">
        <InputSingle
          value={query}
          setValue={setQuery}
          placeholder={"Filter by name or tag"}
          label={"Filter by name or tag"}
          isLabelHidden
        />
      </form>
    </div>
  );
};

const ValueAddSelection = ({
  valueAdd,
  currency,
  handleToggleValueAdd,
  scopeVAs,
  handleDeleteValueAdd,
  isInWorkspaceView,
  isFromDashboard,
  labels,
  workspaceId,
}) => {
  const { title, cost, id, description, max } = valueAdd;
  const [isExpanded, setIsExpanded] = useState(false);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${
        isExpanded ? "rotate-90" : "rotate-0"
      }`}
    />
  );

  const selectedVA = scopeVAs.find((item) => item.vaId === valueAdd.id);
  const isSelected = selectedVA ? true : false;

  const options = [
    {
      action: "Delete from library",
      color: "red",
      handleSelect: () => handleDeleteValueAdd(id, "valueAdds"),
    },
  ];

  return (
    <div>
      <div className="bg-white shadow p-4 rounded">
        <div
          className={`flex-1 flex col-span-1 items-center justify-center ${
            isInWorkspaceView && "pr-1"
          } ${
            isSelected || isFromDashboard ? "opacity-100" : "opacity-60"
          } transition`}
        >
          {!isFromDashboard && (
            <InputCheckboxSingle
              setValue={() =>
                handleToggleValueAdd(
                  isSelected,
                  isSelected ? selectedVA.id : valueAdd
                )
              }
              value={isSelected}
            />
          )}
          <ButtonIcon
            label={buttonLabel}
            icon={buttonIcon}
            handleClick={() => setIsExpanded(!isExpanded)}
          />
          <div className="flex">
            <div className="flex-1 text-left">
              <span className="text-left">{title}</span>
              <div className="text-sm text-gray-500 line-clamp-1">
                <p>{description}</p>
              </div>
            </div>
          </div>
          <span className="text-right font-bold flex-1">
            {formatCurrency(cost,currency)}
            {max > 0 && (
              <span className="ml-[.2em]">
                - {formatCurrency(max,currency)}
              </span>
            )}
          </span>
          {isInWorkspaceView && (
            <div className="flex items-center">
              <Dropdown options={options} />
            </div>
          )}
        </div>
        {isExpanded && (
          <CardVA
            item={valueAdd}
            isContractor={true}
            cardCollection={"valueAdds"}
            workspaceId={workspaceId}
            labels={labels}
          />
        )}
      </div>
    </div>
  );
};
