import Button from "../buttons/Button";
import Dropdown from "../dropdowns/Dropdown";

export default function TablePeople(props) {
  const {
    title,
    description,
    handleAdd,
    action,
    isWorkspaceOwner,
    setUserToDelete,
  } = props;
  const people = props.people ?? [];

  return (
    <div className="">
      {title && (
        <div className="sm:flex sm:items-center text-left">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
            {description && (
              <p className="mt-2 text-sm text-gray-700">{description}</p>
            )}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button bg="accent" handleClick={handleAdd} message={action} />
          </div>
        </div>
      )}
      <div className="-mx-4 mt-8 shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Role
              </th>
              {isWorkspaceOwner && (
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Options</span>
                </th>
              )}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {people.map((person) => {
              let options = [
                {
                  action: "Remove from workspace",
                  handleSelect: () => setUserToDelete(person),
                  color: "red",
                },
              ];
              return (
                <tr key={person.email}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 text-left">
                    {person.name}
                    <dl className="font-normal lg:hidden">
                      <dt className="sr-only">Title</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {person.name}
                      </dd>
                      <dt className="sr-only sm:hidden">Email</dt>
                      <dd className="mt-1 truncate text-gray-500 sm:hidden">
                        {person.email}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell text-left">
                    {person.email}
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-500 text-left">
                    {person.projectRole}
                  </td>
                  {isWorkspaceOwner && (
                    <td className="py-4 pl-3 text-sm font-medium text-left flex items-center">
                      <Dropdown options={options} />
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
