import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputSingle from "../inputs/InputSingle";
import Button from "../buttons/Button";
import { PaperClipIcon, ShareIcon } from "@heroicons/react/20/solid";
import Pill from "../pills/Pill";
import ButtonClose from "../buttons/ButtonClose";
import { URL } from "../../utils/constants";
import { FeedbackContext } from "../../store/feedback-context";

export default function ModalInviteScope(props) {
  const { isOpen, setIsOpen, handleInviteApprover, projectId } = props;
  const [email, setEmail] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const approvers = props.approvers ?? [];
  const invites = props.invites ?? [];
  const { setAlert } = useContext(FeedbackContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (approvers.some((item) => item.email === email)) {
      setAlert("This email is already an approver", "error");
      return;
    }
    if (invites.some((item) => item === email)) {
      setAlert("This email has already been invited", "error");
      return;
    }
    const currentInvites = invites;
    currentInvites.push(email);
    const obj = { prop: "scopeInvites", value: currentInvites };
    return handleInviteApprover(email, obj);
  };

  const pendingInvites = invites.filter(
    (item) => !approvers.some((approver) => approver.email === item)
  );

  const handleCopy = () => {
    navigator.clipboard.writeText(`${URL}/join/${projectId}`);
    setIsCopied("Successfully copied link to clipboard", "success");
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
  }, [isCopied]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-2xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col bg-white relative"
                >
                  <div className="absolute top-1 right-1">
                    <ButtonClose handleClick={() => setIsOpen(false)} />
                  </div>
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="px-4 pt-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-2xl font-bold text-gray-800">
                          Invite approvers
                        </Dialog.Title>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Whoever you invite will have access to the scope and
                          dashboard
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className=" px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <InputSingle
                            isRequired
                            type="email"
                            label="Add one email at a time"
                            name="email"
                            value={email}
                            setValue={setEmail}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-6">
                      {approvers.length > 0 && (
                        <div>
                          <h3 className="font-medium text-sm text-gray-600 mb-1">
                            Approvers
                          </h3>
                          <ul className="py-1 px-2 flex flex-wrap">
                            {approvers.map((item) => (
                              <li className="m-1 ml-0">
                                <Pill
                                  size={"h-6 w-6 text-xs"}
                                  message={item.email}
                                  isLower
                                  type="members"
                                />
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {pendingInvites.length > 0 && (
                        <div className="py-2">
                          <h3 className="font-medium text-sm text-gray-600 mb-1">
                            Outstanding Invites
                          </h3>
                          <ul className="">
                            {pendingInvites.map((invite) => (
                              <li className="text-xs italic text-gray-700">
                                {invite}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <div className="flex relative">
                      <button
                        type="button"
                        onClick={() => handleCopy()}
                        className="flex items-center font-bold text-blue-400 hover:underline mr-3 p-2 text-sm cursor-pointer"
                      >
                        <PaperClipIcon className="h-4 w-4 mr-1" />
                        Copy link
                      </button>
                      <CopiedBanner isCopied={isCopied} />
                      <Button bg="tertiary" message="Invite" type="submit" />
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const CopiedBanner = ({ isCopied }) => {
  return (
    <div
      className={`flex items-center whitespace-nowrap px-5 py-1 rounded absolute bottom-10 right-4 shadow text-xs bg-blue-200 text-blue-700 transition-all ${
        isCopied ? "opacity-100" : "opacity-0"
      } `}
    >
      <ShareIcon className="h-3 w-3 mr-1" />
      <span>Share link copied to clipboard</span>
    </div>
  );
};
