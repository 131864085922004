import Button from "../buttons/Button";
import ButtonText from "../buttons/ButtonText";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";

export default function TableApprovers(props) {
  const { title, description, handleAdd, action, handleToggle } = props;
  const people = props.people ?? [];
  const projectApprovers = props.projectApprovers ?? [];

  return (
    <div className="px-4 sm:px-6 lg:px-8 my-10">
      {title && (
        <div className="sm:flex sm:items-center text-left">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">{title}</h1>
            {description && (
              <p className="mt-2 text-sm text-gray-900">{description}</p>
            )}
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <Button bg={"tertiary"} handleClick={handleAdd} message={action} />
          </div>
        </div>
      )}
      <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="py-3.5 text-center text-sm font-semibold text-gray-900"
              >
                <span className="sr-only">Is approver for this project</span>
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 flex-1 text-left text-sm font-semibold text-gray-900"
              >
                <span className="">Email</span>
              </th>
              <th scope="col" className="relative py-3.5">
                <span className="sr-only">Remove</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {people.map((person) => {
              return (
                <tr
                  className={`${
                    projectApprovers.includes(person)
                      ? "opacity-100"
                      : "opacity-60"
                  } transition`}
                  key={person}
                >
                  <td className="py-4 text-sm text-gray-800 text-right">
                    <div className="flex justify-center items-center">
                      <InputCheckboxSingle
                        setValue={() => handleToggle(person, "isProject")}
                        value={projectApprovers.includes(person)}
                      />
                    </div>
                  </td>
                  <td className="px-3 py-4 text-sm text-gray-800 text-left">
                    {person}
                  </td>
                  <td className="py-4 text-sm font-medium text-left">
                    <ButtonText
                      handleClick={() => handleToggle(person)}
                      aria-label={`Remove ${person}`}
                      message="Remove"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
