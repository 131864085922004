import ReactTimeago from "react-timeago";
import { sortyByDate } from "../../utils/sortArray";
import Button from "../buttons/Button";
import Pill from "../pills/Pill";

export default function TableWorkspaces(props) {
  const { heading, description, handleAdd, action, handleSelect } = props;
  let items = props.workspaces ?? [];
  items = sortyByDate(items);
  const isWorkspace = props.isWorkspace ?? true;
  if (isWorkspace) {
    items = items.map((item) => ({ ...item, id: item.workspaceId }));
  }

  console.log(items);
  
  return (
    <div className="w-full flex justify-center">
      <div className="px-4 sm:px-6 lg:px-8 my-10 w-full">
        {heading && (
          <div className="sm:flex sm:items-center text-left">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">{heading}</h1>
              {description && (
                <p className="mt-2 text-sm text-gray-700">{description}</p>
              )}
            </div>
            {action && handleAdd && (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <Button handleClick={handleAdd} message={action} />
              </div>
            )}
          </div>
        )}
        <div className="-mx-4 mt-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  {isWorkspace ? "Workspaces" : "Scopes"}
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                ></th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {items.map((item) => {
                return (
                  <tr key={item.id}>
                    <td
                      role="button"
                      aria-label={`Go to ${item.title}`}
                      onClick={() =>
                        handleSelect(
                          isWorkspace
                            ? item.id
                            : `${item.projectId}/${item.id}`,
                          isWorkspace ? "workspace" : "scope"
                        )
                      }
                      className="cursor-pointer w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 text-left"
                    >
                      {item.title}
                    </td>
                    <td className="">
                      <div className="flex justify-end mr-2">
                        <div>
                          <Pill
                            message={
                              <ReactTimeago date={item.createdTimestamp} />
                            }
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
