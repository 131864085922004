export default function InputCheckbox(props) {
  const { legend, inputs } = props;
  const labelTextStyle =
    props.labelTextStyle ?? "text-left text-gray-600 font-bold text-sm";
  return (
    <fieldset className="my-2 flex flex-wrap space-x-5 space-y-5">
      <legend className={labelTextStyle}>{legend}</legend>
      {inputs.map((input) => (
        <div
          style={{ opacity: input.disabled ? 0.4 : 1, transition: "all .5s" }}
          className="relative flex items-start"
        >
          <div className="flex h-5 items-center">
            <input
              disabled={input.disabled}
              checked={input.value}
              onChange={() => input.setValue(!input.value)}
              id={input.name}
              aria-describedby={input.name}
              name={input.name}
              type="checkbox"
              className="h-4 w-4 rounded-full border-gray-300 text-tertiary focus:ring-tertiary-400"
            />
          </div>
          <div className="ml-1 text-sm">
            <label htmlFor={input.name} className="font-medium text-gray-700">
              {input.label}
            </label>
            {input.description && (
              <span id={`${input.name}-description`} className="text-gray-500">
                <span className="sr-only">{input.label} </span>
                {input.description}
              </span>
            )}
          </div>
        </div>
      ))}
    </fieldset>
  );
}
