import React from "react";

function InputSelection(props) {
  const { cypressId, label, name, value, setValue, options, placeholder } =
    props;

  const margin = props.margin ?? "m-0";
  const minWidth = props.minWidth ?? "w-xl";
  const isLabelHidden = props.isLabelHidden ?? false;
  const textColor = value === "" ? "text-gray-400" : "text-gray-900";
  const labelTextStyle = props.labelTextStyle ?? "text-accent";
  const width = props.width ?? "";
  const isDisabled = props.isDisabled ?? false;

  return (
    <div className={margin}>
      {!isLabelHidden && (
        <label
          htmlFor={name}
          className={`block text-sm font-medium text-gray-700 pl-2 text-left ${labelTextStyle}`}
        >
          {label}
        </label>
      )}
      <select
        disabled={isDisabled}
        placeholder="Choose Template"
        aria-label={label}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        id={name}
        name={name}
        className={`mt-1 block min-w-[200px] border rounded-md border-gray-200 py-2 pl-3 pr-10 text-base focus:border-tertiary-500 focus:outline-none focus:ring-tertiary-500 sm:text-sm
          ${minWidth} ${textColor} ${width}`}
      >
        {placeholder && (
          <option className="" value="" selected disabled hidden>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default InputSelection;
