import { db, firebase } from "../firebase";

export function createComment(entityObj, userInfo, text) {
  const creator = {
    userId: userInfo.id,
    name: userInfo.name,
    email: userInfo.email,
    userUid: userInfo.uid,
  };
  const { workspaceId, projectId, scopeId, entityId } = entityObj;
  return db.collection("comments").add({
    text,
    workspaceId,
    projectId,
    scopeId,
    entityId,
    attachements: [],
    isApproved: false,
    isRejected: false,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
  });
}
