import React from "react";
import { Link } from "react-router-dom";
import TableCreateScope from "../../components/tables/TableCreateScope";

function ProjectSectionBuilder(props) {
  const { scopeObj, projectId, worksapceId } = props;
  return (
    <div>
      {scopeObj ? (
        <ScopeLink
          projectId={projectId}
          scopeObj={scopeObj}
          worksapceId={worksapceId}
        />
      ) : (
        <TableCreateScope {...props} />
      )}
    </div>
  );
}

export default ProjectSectionBuilder;

const ScopeLink = ({ scopeObj, projectId, workspaceId }) => {
  return (
    <div className="mt-10 text-left font-bold text-2xl text-gray-700">
      <h2 className="">You have created a Scope for this project</h2>
      <div className="w-full bg-white rounded-lg border shadow py-5 text-left px-10 text-md mt-5">
        <Link to={`/scope/${scopeObj.id}/${projectId}/${workspaceId}`}>
          <div>
            <span className="font-bold text-left">Go to {scopeObj.title}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};
