import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputSingle from "../inputs/InputSingle";
import InputTextarea from "../inputs/InputTextarea";
import Button from "../buttons/Button";
import ButtonText from "../buttons/ButtonText";

export default function ModalClientAdd(props) {
  const { isOpen, setIsOpen, handleCreate } = props;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [addMainContact, setAddMainContact] = useState(false);

  const clearState = () => {
    setIsOpen(false);
    setTitle("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const mainContact = { name: contactName, email: contactEmail };
    const obj = { title, description, mainContact };
    return handleCreate(obj);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={clearState}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 z-12 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col divide-y divide-gray-200 bg-none"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-slate-100 px-4 py-6 sm:px-6 rounded">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          New Client
                        </Dialog.Title>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Add this client to your list of clients and assign it
                          to a project.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="py-5 space-y-3">
                          <InputSingle
                            isRequired
                            placeholder="Client Name"
                            label="Client Name"
                            name="client-name"
                            value={title}
                            setValue={setTitle}
                          />
                          <InputTextarea
                            placeholder="Add some information about your client, such as: their industry, what their payment terms are, and any other details helpful to keep in mind."
                            label="Client Description"
                            name="description"
                            value={description}
                            setValue={setDescription}
                          />
                          {addMainContact && (
                            <>
                              <InputSingle
                                placeholder="Main Contact Name"
                                label="Main Contact Name"
                                name="contact-name"
                                value={contactName}
                                setValue={setContactName}
                              />
                              <InputSingle
                                placeholder="Main Contact Email"
                                label="Main Contact Email"
                                name="contact-email"
                                type="email"
                                value={contactEmail}
                                setValue={setContactEmail}
                              />
                            </>
                          )}
                          <ButtonText
                            handleClick={() =>
                              setAddMainContact(!addMainContact)
                            }
                            message={
                              addMainContact
                                ? "- Hide main contact"
                                : "+ Add main contact"
                            }
                            width={""}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <div className="flex">
                      <div>
                        <Button
                          bg={"tertiary"}
                          message="Submit"
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/*
<>
  <span className="`block text-sm font-medium text-left pl-2 text-accent">
    Add client contacts
  </span>
  {members.map((member, index) => (
    <div key={index}>
      <InputSingle
        isLabelHidden
        placeholder="contact@client.com"
        type="email"
        label="Client contact"
        name="client-email"
        value={member}
        setValue={handleSetEmail}
        index={index}
      />
    </div>
  ))}
  <div className="flex">
    <div>
      <ButtonText
        icon={<PlusIcon className="h-3 w-3" />}
        handleClick={() => handleAddMember()}
        message="Add another contact"
        color="text-primary"
      />
    </div>
  </div>
</>
*/
