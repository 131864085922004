import {
  CheckCircleIcon,
  ChevronRightIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import { useState } from "react";
import Button from "../../components/buttons/ButtonIcon";
import ButtonText from "../../components/buttons/ButtonText";
import Dropdown from "../../components/dropdowns/Dropdown";
import { UpdateForm } from "../../components/inputs/FormUpdate";
import Pill from "../../components/pills/Pill";
import { useEffect } from "react";
import { CardFull } from "../../components/card/CardFull";
import { FunctionContext } from "../../store/function-context";
import { FeedbackContext } from "../../store/feedback-context";
import { ModalContext } from "../../store/modal-context";
import { formatCurrency } from "../../utils/formatCurrency";

const filterOptions = [
  { value: "all", label: "All" },
  { value: "item", label: "Original Scope" },
  { value: "scopeVA", label: "Optional Service" },
  { value: "request", label: "Client Request" },
  { value: "complete", label: "Complete" },
];

export default function ScopeOverviewPhase(props) {
  const {
    phase,
    phases,
    items,
    labels,
    allItems,
    allValueAdds,
    handleUpdateItem,
    handleCreateComment,
    handleCreateItem,
    comments,
    userInfo,
    scopeId,
    isContractor,
    currency,
    workspaceId,
    approvalObj,
  } = props;
  //const { description } = phase;
  const [title, setTitle] = useState(phase.title);
  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const isAllCompleted =
    items.every((item) => item.isCompleted) && items.length > 0;
  const [isExpanded, setIsExpanded] = useState(!isAllCompleted);
  const [filter, setFilter] = useState("all");
  const { setAlert } = useContext(FeedbackContext);
  const { setActiveModal, setActiveAddPhase } = useContext(ModalContext);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const isAddHidden = props.isAddHidden ?? false;
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${
        isExpanded ? "rotate-90" : "rotate-0"
      }`}
    />
  );

  let filteredItems =
    filter === "item" || filter === "request" || filter === "scopeVA"
      ? items.filter((item) => item.type === filter)
      : items;

  if (filter === "complete") {
    filteredItems = items.filter((item) => item.isCompleted);
  }

  if (filter === "all" && phase.id !== "completed") {
    filteredItems = items.filter((item) => item.isCompleted != true);
  }

  const handleUpdateItems = (id, obj, type) => {
    let updatedItems = type === "valueAdds" ? allValueAdds : allItems;
    const prop = type === "valueAdds" ? "valueAddsRequested" : "items";
    const itemIndex = updatedItems.findIndex((item) => item.id === id);
    updatedItems[itemIndex][obj.prop] = obj.value;
    const itemObj = { collection: "scopes", itemId: scopeId };
    const updateObj = { prop, value: updatedItems };
    return handleUpdateItem(itemObj, updateObj);
  };

  const isButtonVisible = title.trim() !== phase.title.trim() && isContractor;

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemObj = {
      itemId: phase.id,
      collection: "phases",
    };
    const obj = {
      prop: "title",
      value: title,
    };
    return handleUpdateItem(itemObj, obj).then(() => {
      setIsTitleEditing(false);
    });
  };

  const itemObj = { collection: "phases", itemId: phase.id };
  const deleteObj = { prop: "isDeleted", value: true };
  const deleteFeedbackObj = { message: "Item deleted" };

  const options = [
    {
      action: "Delete",
      color: "red",
      handleSelect: () =>
        filteredItems.length > 0
          ? setAlert(
              "You need to delete each item in the phase before it can be deleted"
            )
          : handleUpdateItem(itemObj, deleteObj, deleteFeedbackObj),
    },
  ];

  const handleSlideLibrary = () => {
    setActiveAddPhase(phase.id);
    setActiveModal("services-lib");
  };
  return (
    <div>
      <div className="flex items-center">
        <Button
          padding={"py-2"}
          label={buttonLabel}
          icon={buttonIcon}
          handleClick={() => setIsExpanded(!isExpanded)}
        />
        {isTitleEditing ? (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="flex items-center flex-1"
          >
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              readOnly={!isContractor}
              className="flex-1 text-lg font-bold bg-primary-100 pl-1 ml-1"
              autoFocus={true}
            />
            {isButtonVisible && (
              <button
                type="submit"
                className="ml-2 text-white text-xs py-1 px-3 bg-tertiary rounded"
              >
                Update
              </button>
            )}
          </form>
        ) : (
          <div className="flex items-center flex-1">
            <h3 className="text-lg font-bold bg-primary-100 pl-1 ml-1">
              {title}
            </h3>
            {isContractor && (
              <button
                type="button"
                onClick={() => setIsTitleEditing(true)}
                className="ml-1 text-gray-400 hover:text-gray-600"
              >
                <span className="sr-only">Edit title</span>
                <PencilSquareIcon className="h-4 w-4" />
              </button>
            )}
          </div>
        )}
        {isAllCompleted && !isAddHidden && (
          <>
            <Pill message="Phase is completed" type="success" />
            <CheckCircleIcon className="h-4 w-4 ml-1 text-green-500" />
            <span className="sr-only">All items are completed</span>
          </>
        )}
        {isContractor && <Dropdown options={options} />}
      </div>
      {items.length > 0 && (
        <div>
          <PhaseFilter setFilter={setFilter} filter={filter} />
        </div>
      )}
      <div>
        {isExpanded && (
          <ul>
            {filter !== "valueAdd" &&
              filteredItems.map((item) => (
                <ItemTile
                  type={item.type}
                  currency={currency.symbol}
                  key={item.id}
                  item={item}
                  handleUpdate={handleUpdateItems}
                  handleUpdateItem={handleUpdateItem}
                  handleCreateComment={handleCreateComment}
                  comments={comments}
                  userInfo={userInfo}
                  isContractor={isContractor}
                  phases={phases}
                  labels={labels}
                  workspaceId={workspaceId}
                  approvalObj={approvalObj}
                />
              ))}
          </ul>
        )}
      </div>
      {!isAddHidden && (
        <div className="flex flex-wrap">
          <ButtonText
            handleClick={() => handleCreateItem(phase.id)}
            color="text-accent font-bold"
            message="+ Add custom service"
            padding=""
            margin="mt-1 mr-5"
            flexDirection="flex-row-reverse"
            width=""
          />
          <ButtonText
            handleClick={() => handleSlideLibrary()}
            color="text-accent font-bold"
            message="+ Add from service library"
            padding=""
            margin="mt-1"
            flexDirection="flex-row-reverse"
            width=""
          />
        </div>
      )}
    </div>
  );
}

const PhaseFilter = ({ setFilter, filter }) => {
  return (
    <ul className="flex items-center space-x-3 my-2">
      {filterOptions.map((option) => {
        const isSelected = filter === option.value;
        return (
          <li
            role="button"
            label={`Select ${option.label}`}
            onClick={() => setFilter(option.value)}
            className={`text-xs font-medium uppercase text-gray-500 ${
              isSelected && "underline text-primary"
            }`}
          >
            <span>{option.label}</span>
          </li>
        );
      })}
    </ul>
  );
};

const ItemTile = ({
  item,
  currency,
  handleUpdate,
  handleCreateComment,
  comments,
  isContractor,
  userInfo,
  phases,
  labels,
  workspaceId,
  approvalObj,
}) => {
  const { title, addedBy, createdTimestamp, isCompleted, ref, type, max } =
    item;
  const approverName = addedBy ? addedBy.name : "";
  const [cost, setCost] = useState(item.cost);
  const [quantity, setQuantity] = useState(item.quantity ?? 1);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const { handleMakeItemOptional, handleUpdateItem } =
    useContext(FunctionContext);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${
        isExpanded ? "rotate-90" : "rotate-0"
      }`}
    />
  );
  const updateType = `items`;

  useEffect(() => {
    setQuantity(item.quantity ?? 1);
  }, [item.quantity]);

  const handleSubmit = (e, prop) => {
    e.preventDefault();
    const value = prop === "cost" ? cost : quantity;
    return handleUpdate(item.id, { prop, value }, updateType).then(() => {
      setIsEditing(false);
    });
  };

  const itemObj = { collection: updateType, itemId: item.id };
  const deleteObj = { prop: "isDeleted", value: true };
  const completeObj = { prop: "isCompleted", value: !isCompleted };
  const feedbackObj = { message: "Item marked as done" };
  const deleteFeedbackObj = { message: "Item deleted" };

  const options = [
    {
      action: isCompleted ? "Unmark as done" : "Mark as done",
      handleSelect: () => handleUpdateItem(itemObj, completeObj, feedbackObj),
    },
    {
      action: "Make optional",
      handleSelect: () => handleMakeItemOptional(item, completeObj),
      isHidden: isCompleted,
    },
    {
      action: "Delete",
      color: "red",
      handleSelect: () =>
        handleUpdateItem(itemObj, deleteObj, deleteFeedbackObj),
    },
  ];

  const handleSetExpanded = (isForced) => {
    if (!isUpdated || isForced) {
      setIsExpanded(!isExpanded);
    } else {
      setNeedsConfirmation(true);
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      setIsUpdated(false);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => {
        setIsVisible(true);
      }, 200);
    }
  }, [isVisible]);

  return (
    <div
      className={`flex-col bg-white rounded-lg shadow items-center pl-4 py-2 my-2 transition-all duration-1000 ${
        isVisible ? "mr-0 opacity-100" : "mr-10 opacity-0"
      }`}
    >
      <div className="flex my-1 items-center">
        <div className="flex flex-1 items-center">
          <Pill
            type={getPillData(type).type}
            message={getPillData(type).message}
          />
          <AddedBy
            name={approverName}
            timestamp={createdTimestamp}
            approvalTimestamp={approvalObj.timestamp}
          />
          {isCompleted && (
            <CheckCircleIcon className="text-green-500 h-4 w-4 ml-1" />
          )}
        </div>
        {isContractor && (
          <div>
            <Dropdown options={options} />
          </div>
        )}
      </div>
      <div className="flex items-center">
        <div className="flex-1 flex items-start">
          <div className="flex-1 flex items-center">
            <Button
              label={buttonLabel}
              icon={buttonIcon}
              handleClick={() => handleSetExpanded()}
            />
            <div className="ml-1">
              <h3 className="text-left leading-6">
                <span className="text-gray-400 text-sm mr-1">{ref}</span>{" "}
                {title}
              </h3>
            </div>
          </div>
        </div>
        <div className="font-bold pr-4 flex items-center">
          {isEditing === "quantity" ? (
            <UpdateForm
              handleSubmit={(e) => handleSubmit(e, "quantity")}
              value={quantity}
              setValue={setQuantity}
            />
          ) : (
            quantity > 1 && (
              <div className="flex justify-end items-center text-gray-400 text-xs pr-2">
                <span>{`${quantity}x ${formatCurrency(
                  `${+item.cost}`,
                  currency
                )} `}</span>
                {max > 0 && (
                  <>
                    <span className="mx-[.1em]">-</span>
                    <span>{formatCurrency(max, currency)}</span>
                  </>
                )}
              </div>
            )
          )}
          {isEditing === "cost" ? (
            <UpdateForm
              handleSubmit={(e) => handleSubmit(e, "cost")}
              value={cost}
              setValue={setCost}
            />
          ) : (
            <span className="font-bold">
              {formatCurrency(`${item.cost * +quantity}`, currency)}
              {item.max ? (
                <>
                  <span className=""> - </span>
                  <span>
                    {formatCurrency(`${+item.max * +quantity}`, currency)}
                  </span>
                </>
              ) : null}
            </span>
          )}
        </div>
      </div>
      {isExpanded && (
        <CardFull
          handleCreateComment={handleCreateComment}
          handleUpdateItem={handleUpdateItem}
          type={type}
          userInfo={userInfo}
          comments={[
            ...comments.filter((comment) => comment.entityId === item.id),
          ].reverse()}
          phases={phases}
          labels={labels}
          item={item}
          isContractor={isContractor}
          cardCollection={"items"}
          workspaceId={workspaceId}
          setIsUpdated={setIsUpdated}
          needsConfirmation={needsConfirmation}
          setNeedsConfirmation={setNeedsConfirmation}
          handleSetExpanded={handleSetExpanded}
        />
      )}
    </div>
  );
};

const AddedBy = ({ name, timestamp, approvalTimestamp }) => {
  const time = timestamp < approvalTimestamp ? approvalTimestamp : timestamp;
  const verb = approvalTimestamp ? "Approved" : "Added";
  return (
    <span className="uppercase text-xs text-gray-400 ml-1">
      {verb} by {name}
      {" - "}
      {new Date(time).toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })}
    </span>
  );
};

const getPillData = (type) => {
  switch (type) {
    case "valueAdd":
    case "scopeVA":
      return { message: "Optional Service", type: "secondary" };
    case "request":
      return { message: "Client Request", type: "tertiary" };
    default:
      return { message: "Original Scope", type: "primary" };
  }
};
