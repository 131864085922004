import { createContext, useContext, useEffect, useState } from "react";
import { ModalContext } from "./modal-context";

export const FeedbackContext = createContext({
  alert: false,
  toast: false,
  isSpinning: false,
  isNavigating: false,
  setAlert: (message) => {},
  setToast: (message) => {},
  setIsSpinning: (bool) => {},
  setIsNavigating: (bool) => {},
  handleReset: (message, setIsFetching) => {},
  handleFeedback: (message, callback) => {},
  autoSaveTime: 0,
});

export function FeedbackContextProvider(props) {
  const [alert, setAlert] = useState(false);
  const [toast, setToast] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [autoSaveTime, setAutoSaveTime] = useState(0);
  const modalCtx = useContext(ModalContext);
  const { setActiveModal } = modalCtx;

  useEffect(() => {
    if (toast) {
      setTimeout(() => {
        setToast(false);
      }, 4000);
    }
  }, [toast]);

  useEffect(() => {
    setIsSpinning(false);
    if (alert) {
      setTimeout(() => {
        setAlert(false);
      }, 4000);
    }
  }, [alert]);

  const handleReset = (message, setIsFetching, skipCloseModal) => {
    if (skipCloseModal) {
      setActiveModal(false);
    }
    setToast(message);
    if (setIsFetching) setIsFetching(true);
  };

  const handleFeedback = (message, callback, keepModal) => {
    if (keepModal) {
      setActiveModal(false);
    }
    setToast(message);
    if (callback) callback();
  };

  useEffect(() => {
    //Set a timeout and increment to current time every 1 minute
    const interval = setInterval(() => {
      setAutoSaveTime(Date.now());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const contextValue = {
    alert,
    toast,
    isSpinning,
    isNavigating,
    setAlert,
    setToast,
    setIsSpinning,
    setIsNavigating,
    handleReset,
    handleFeedback,
    autoSaveTime,
  };

  return (
    <FeedbackContext.Provider value={contextValue}>
      {props.children}
    </FeedbackContext.Provider>
  );
}
