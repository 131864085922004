import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonIcon from "../buttons/ButtonIcon";
import { TrashIcon } from "@heroicons/react/20/solid";
import InputSingle from "../inputs/InputSingle";
import { useState } from "react";
import Button from "../buttons/Button";

export default function ModalEditScopeApprovers(props) {
  const { isOpen, handleClose, client, handleUpdateItem, id } = props;
  const [newEmail, setNewEmail] = useState("");
  const approvers = client.scopeApprovers ?? [];

  const handleDelete = (approver) => {
    const updatedApprovers = approvers.filter((item) => item !== approver);
    const updatedMembers = client.members.filter(
      (item) => item.email === approver
    );
    const obj = { members: updatedMembers, scopeApprovers: updatedApprovers };
    return handleUpdateItem(obj, id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (approvers.includes(newEmail)) {
      return;
    }
    const updatedApprovers = [...approvers];
    const updatedMembers = [...client.members];
    const memberObj = { email: newEmail };
    updatedMembers.push(memberObj);
    updatedApprovers.push(newEmail);
    const obj = { members: updatedMembers, scopeApprovers: updatedApprovers };
    return handleUpdateItem(obj, id).then(() => setNewEmail(""));
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-primary-100 text-left shadow-xl transition-all w-full md:max-w-7xl sm:my-8 sm:max-w-sm md:p-4">
                <div className="flex items-center justify-center">
                  <Dialog.Title className="text-lg font-medium text-center text-gray-700">
                    Approvers for {client.title}
                  </Dialog.Title>
                </div>
                <div>
                  <ul>
                    {approvers.map((approver) => (
                      <li key={approver}>
                        <div className="flex items-center">
                          <div className="p-2 pl-0">
                            <ButtonIcon
                              handleClick={() => handleDelete(approver)}
                              disabled={approvers.length <= 1}
                              icon={
                                <TrashIcon className="h-4 w-4 text-gray-500 hover:text-red-600" />
                              }
                              label={`Remove ${approver}`}
                            />
                          </div>
                          <div className="flex-1">{approver}</div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div>
                    <form
                      className="flex mt-10"
                      onSubmit={(e) => handleSubmit(e)}
                    >
                      <div className="flex-1 mr-1">
                        <InputSingle
                          type="email"
                          isRequired
                          placeholder="New approver email"
                          value={newEmail}
                          setValue={setNewEmail}
                        />
                      </div>
                      <Button
                        bg={"tertiary"}
                        message="Add approver"
                        type="submit"
                      />
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
