import { ChevronRightIcon, PaperClipIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useState } from "react";
import { sortArrayByOrder } from "../../utils/sortArray";
import Button from "../buttons/Button";
import ButtonIcon from "../buttons/ButtonIcon";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";

export default function TableReviewScope(props) {
  const { scope, handleApproveScope, handleUpdateItem, handleAddItem } = props;
  const { description, phases, items, currency, projectId } = scope;
  const isReview = props.isReview ?? false;

  const handleAdd = (vaId) => {
    const item = items.find((item) => item.vaId === vaId);
    if (item) {
      const obj = { value: true, prop: "isDeleted" };
      const itemObj = { collection: "items", itemId: item.id };
      return handleUpdateItem(itemObj, obj);
    } else {
      //VAs gotten from project but restructured as scope
      const valueAdd = scope.valueAdds.find((item) => item.vaId === vaId);
      //Create Item
      return handleAddItem(valueAdd, projectId);
    }
  };

  return (
    <>
      <div class="w-full py-6 sm:py-10 2xl:py-12">
        <div class="w-full max-w-7xl mx-auto">
          {description && (
            <ActionHeading
              title={"Project summary"}
              description={description}
              isSmall={true}
            />
          )}
          <>
            {phases.map((phase) => (
              <TableCreate
                key={phase.id}
                phase={phase}
                items={sortArrayByOrder(
                  items.filter((item) => item.phaseId === phase.id)
                )}
                currency={currency}
              />
            ))}
            <TableTotal currency={currency} items={items} />
          </>
        </div>
        {scope.valueAdds.length > 0 && (
          <div className="bg-primary-200 my-5 py-10 rounded-lg">
            <div className="max-w-7xl px-4 sm:px-8 mx-auto">
              <ValueAddsSection
                valueAdds={scope.valueAdds}
                addsRequested={scope.valueAddsRequested}
                handleAdd={handleAdd}
                currency={currency}
                isReview={isReview}
              />
            </div>
          </div>
        )}
        {!isReview && (
          <div className="mx-auto">
            <ApprovalButton handleApproveScope={handleApproveScope} />
          </div>
        )}
      </div>
    </>
  );
}

const ValueAddsSection = ({
  valueAdds,
  addsRequested,
  handleAdd,
  isSkipped,
  currency,
}) => {
  return (
    <div class="w-full">
      <div>
        <h3 class="font-bold text-xl text-center text-gray-600">
          Optional services
        </h3>
        <p className="text-gray-600">
          These are optional services you might like to enhance this project
          (you can also add them later in the scoping dashboard)
        </p>
      </div>
      <div
        class={`flex flex-wrap justify-start transition-all ${
          isSkipped && "opacity-25"
        }`}
      >
        <ValueAddList
          valueAdds={valueAdds}
          addsRequested={addsRequested}
          handleAdd={handleAdd}
          currency={currency}
        />
      </div>
    </div>
  );
};

const ValueAddList = ({ valueAdds, handleAdd, addsRequested, currency }) => {
  return (
    <ul className="space-2 w-full flex flex-wrap justify-start items-start my-5">
      {valueAdds.map((valueAdd) => (
        <ValueAddItem
          handleAdd={handleAdd}
          valueAdd={valueAdd}
          key={valueAdd.id}
          currency={currency.symbol}
          isRequested={addsRequested.some((add) => add.vaId === valueAdd.vaId)}
        />
      ))}
    </ul>
  );
};

const ValueAddItem = ({ valueAdd, currency, handleAdd, isRequested }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { title, vaId } = valueAdd;
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${
        isExpanded ? "rotate-90" : "rotate-0"
      }`}
    />
  );
  return (
    <li
      className={`flex items-center rounded-lg shadow pr-5 bg-white text-gray-800 w-full md:w-[48%] md:mr-[1%] my-1 py-2`}
    >
      <div className="flex flex-1">
        <div className="ml-5 items-center flex">
          <InputCheckboxSingle
            divHeight={"h-6"}
            boxHeight={"h-5 w-5"}
            value={isRequested}
            setValue={() => handleAdd(vaId)}
            label={title}
            isLabelVisible={false}
          />
        </div>
        <div className="flex items-center flex-1">
          <div className="hidden lg:flex">
            <ButtonIcon
              isInvisible={!valueAdd.description}
              label={buttonLabel}
              icon={buttonIcon}
              handleClick={() => setIsExpanded(!isExpanded)}
            />
          </div>
          <div className="ml-1">
            <h3 className="text-left">{valueAdd.title}</h3>
            {isExpanded && (
              <p className="text-sm text-left text-gray-500">
                {valueAdd.description}
              </p>
            )}
          </div>
          <div className="flex flex-1 justify-end items-center font-bold">
            <span>{currency}</span>
            <span>{valueAdd.cost}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

const ApprovalButton = ({ handleApproveScope }) => {
  return (
    <div class="flex flex-col items-center justify-center flex-1 w-full">
      <div class="mb-5">
        <h3 className="text-xl font-bold">Client approval</h3>
        <p className="text-gray-600">
          The contractor can approve, if client acceptance is not required
        </p>
      </div>
      <Button
        handleClick={() => handleApproveScope()}
        width={"w-full"}
        type="button"
        message={"I approve this project scope, let's get started"}
        fontSize={"text-md"}
        bg={"tertiary"}
      />
    </div>
  );
};

const ActionHeading = ({ title, description, isSmall }) => {
  const fontSize = isSmall ? "text-lg sm:text-xl " : "text-2xl sm:text-3xl ";
  return (
    <>
      <h1 class={`font-medium mb-2 text-left text-gray-800 ${fontSize}`}>
        {title}
      </h1>
      <p class="text-base mb-8 text-left text-gray-600">{description}</p>
    </>
  );
};

const TableTotal = ({ items, currency }) => {
  const totalCost = items.reduce(
    (acc, item) => +acc + +item.cost * +item.quantity ?? 1,
    0
  );
  return (
    <div class="w-full bg-accent text-white px-6 py-2 border border-neutral-300 rounded-xl mb-20">
      <table class="w-full table-fixed border-collapse">
        <tbody>
          <tr class="flex w-full lg:table-row">
            <td class="text-lg text-left lg:px-4" colspan="4">
              <p class="w-full text-lg 2xl:text-xl font-bold">Total</p>
            </td>
            <td class="ml-auto text-lg text-right lg:px-4">
              <p class="w-full text-right text-lg 2xl:text-xl font-bold">
                {currency}
                {totalCost}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const TableCreate = ({ phase, items, handleUpdateRow, currency }) => {
  const { title } = phase;
  const totalCost = items.reduce(
    (acc, item) => +acc + +item.cost * +item.quantity ?? 1,
    0
  );

  return (
    <div class="w-full bg-white p-4 pb-1 2xl:p-6 2xl:pb-2 border border-neutral-300 rounded-xl mb-6">
      <div className="flex items-center justify-center mb-5">
        <span className="flex-1 text-lg 2xl:text-xl font-bold ml-1 border-none p-2 rounded text-left">
          {title}
        </span>
      </div>
      <table class="w-full table-fixed border-collapse">
        <colgroup class="hidden lg:table-column-group">
          <col class="w-1/12"></col>
          <col class="w-1/6"></col>
          <col class="w-1/3"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
          <col class="w-1/12"></col>
        </colgroup>
        <thead class="">
          <tr class="flex lg:table-row">
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Ref
            </th>
            <th class="lg:w-auto text-sm text-left font-bold uppercase lg:px-4 pb-2">
              Task/item
            </th>
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Details
            </th>
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              <PaperClipIcon className="md:w-4 md:h-4 h-5 w-5 md:mr-2" />
              <span className="sr-only">Attachments</span>
            </th>
            <th class=" lg:w-auto text-sm font-bold uppercase lg:px-4 pb-2 text-right">
              Quote
            </th>
            <th class="hidden lg:table-cell text-sm text-left font-bold uppercase px-4 pb-2">
              Qty
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <TableItem
              key={item.id}
              item={item}
              handleUpdateRow={handleUpdateRow}
            />
          ))}
        </tbody>
        <tfoot>
          <tr class="flex w-full lg:table-row border-t border-neutral-300">
            <td
              class="text-base 2xl:text-lg text-left font-bold lg:px-4 py-5"
              colspan="4"
            >
              Sub-total
            </td>
            <td class="ml-auto text-base 2xl:text-lg text-right font-bold lg:px-4 py-5">
              {currency}
              {totalCost}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

const TableItem = ({ item }) => {
  const { title, description, quantity, cost, attachmentLink } = item;

  return (
    <tr class="relative flex flex-col py-3 w-full lg:table-row lg:w-auto lg:py-0 border-t border-gray-200">
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4 italic text-xs text-gray-600">
        {item.ref}
      </td>
      <td class=" text-left lg:w-auto lg:px-3 lg:py-3 2xl:py-4 ">
        <span className="border-none p-2 w-full my-2 text-gray-800 text-sm">
          {title}
        </span>
      </td>
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        <span className="border-none w-full my-2 text-gray-600 text-sm">
          {description}
        </span>
      </td>
      <td class="text-left lg:px-3 lg:py-3 2xl:py-4">
        {attachmentLink && (
          <a
            rel="noreferrer"
            target="_blank"
            className="flex flex-1 items-center text-accent-500"
            href={item.attachmentLink}
          >
            <span className="text-xs mr-1 sr-only">Go to link</span>
            <PaperClipIcon className="h-4 w-4" />
          </a>
        )}
      </td>
      <td class="absolute w-1/3 top-3 right-0 lg:static lg:w-auto text-right lg:px-3 lg:py-3 2xl:py-4">
        <span className="border-none p-2 w-full my-2 text-gray-800 text-sm">
          {cost}
        </span>
      </td>
      <td class="text-left flex lg:table-cell lg:px-3 lg:py-3 2xl:py-4">
        <span className="border-none p-2 w-full my-2 text-gray-800 text-sm">
          {quantity}
        </span>
      </td>
    </tr>
  );
};
