import { XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

export default function ButtonClose(props) {
  const { handleClick, disabled } = props;
  const type = props.type ?? "button";
  const label = props.label ?? "Close";
  const icon = props.icon ?? (
    <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
  );

  return (
    <button
      aria-label={label}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={""}
    >
      {icon && icon}
      <span className="sr-only">{label}</span>
    </button>
  );
}
