import React, { useContext } from "react";
import { ExampleContext } from "../../store/example-context";

function ButtonExample(props) {
  const { exampleId } = props;
  const width = props.width ?? "";
  const fontSize = props.fontSize ?? "text-sm";

  const exampleCtx = useContext(ExampleContext);
  const { setExampleId } = exampleCtx;

  const handleClick = () => {
    setExampleId(exampleId);
  };

  const color = props.color ?? "text-accent";
  return (
    <button
      aria-label={"Show examples"}
      onClick={handleClick}
      type={"button"}
      className={`fixed z-20 top-3 right-2 cursor-pointer transition-all focus:outline-none focus:ring-2 focus:ring-oƒffset-2 ${color} ${width} ${fontSize}`}
    >
      <span className={`${color} text-xs`}>Show example</span>
    </button>
  );
}

export default ButtonExample;
