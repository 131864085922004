import React, { useState } from "react";
import Button from "../../components/buttons/Button";
import InputSingle from "../../components/inputs/InputSingle";
import InputTextarea from "../../components/inputs/InputTextarea";
import SectionHeading from "../../components/headings/HeadingSection";
import { logout } from "../../firebase";
import { checkIfStringIsValid } from "../../utils/checkStrings";

function UserSectionInfo(props) {
  const { user } = props;
  const [name, setName] = useState(user?.name);
  const [bio, setBio] = useState(user?.bio);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = checkIfStringIsValid(name);
    if (isValid) {
      props.handleUpdateForm({ name, bio });
    } else {
      alert("Please enter a valid name");
    }
  };

  const isSubmitDisabled =
    (name === user.name && bio === user.bio) || name?.trim() === "";

  return (
    <section className="max-w-3xl mx-auto box-border px-2">
      <div className="mt-5">
        <SectionHeading
          heading={"My Info"}
          subheading={`Fill in your info to let others know who you are`}
        />
      </div>
      <div className="max-w-3xl m-auto py-5">
        <form onSubmit={(e) => handleSubmit(e)} className="mb-5">
          <InputSingle
            placeholder={"My name"}
            label={"My name"}
            name={"my-name"}
            value={name}
            setValue={setName}
            margin={"my-5"}
          />
          <InputTextarea
            placeholder={"My bio"}
            label={"My bio"}
            name={"my-bio"}
            value={bio}
            setValue={setBio}
            margin={"my-5"}
          />
          <div className="flex justify-end">
            <div>
              <Button
                type="submit"
                message={"Update"}
                disabled={isSubmitDisabled}
                bg={"accent"}
              />
            </div>
          </div>
        </form>
        {/* 
        <div className="p-2 text-left">
          <ActionPanelDelete entity={"Account"} />
        </div> */}
        <div className="flex w-full justify-end mt-5">
          <div>
            <Button bg="red" message="Logout" handleClick={() => logout()} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default UserSectionInfo;
