import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useContext, useEffect, useState } from "react";
import { updateCollectionItemMultiple } from "../../services/SharedService";
import Button from "../buttons/Button";
import InputSelection from "../inputs/InputSelection";
import InputSingle from "../inputs/InputSingle";
import InputTextarea from "../inputs/InputTextarea";
import CommentBox from "../comment-box/CommentBox";
import InputCombo from "../inputs/InputCombo";
import Pill from "../pills/Pill";
import { FunctionContext } from "../../store/function-context";
import ModalConfirmUpdateItem from "../modals/ModalConfirmUpdateItem";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";

export const CardFull = ({
  comments,
  phases,
  item,
  labels,
  handleUpdateItem,
  handleCreateComment,
  isContractor,
  cardCollection,
  workspaceId,
  setIsUpdated,
  needsConfirmation,
  setNeedsConfirmation,
  handleSetExpanded,
  type,
}) => {
  const [phase, setPhase] = useState(item.phaseId);
  const [title, setTitle] = useState(item.title);
  const [quantity, setQuantity] = useState(item.quantity ?? false);
  const [cost, setCost] = useState(item.cost ?? 0);
  const [max, setMax] = useState(item.max ?? 0);
  const [description, setDescription] = useState(item.description);
  const [link, setLink] = useState(item.attachmentLink ?? "");
  const [labelValue, setLabelValue] = useState("");
  const [isRecurring, setIsRecurring] = useState(item.isRecurring ?? false);
  const phaseOptions = phases.map((option) => ({
    label: option.title,
    value: option.id,
  }));
  const [itemLabels, setItemLabels] = useState(item.labels ?? []); //labels are needed for combo
  const [labelQuery, setLabelQuery] = useState("");
  const labelStlye = "uppercase text-xs text-gray-600 pl-0 font-normal";

  const quantityCheck = item.quantity ?? false;
  const isRecurringCheck = item.isRecurring ?? false;

  const isButtonDisabled =
    !isContractor ||
    (phase === item.phaseId &&
      title === item.title &&
      quantity === quantityCheck &&
      cost === item.cost &&
      max === item.max &&
      link === item.attachmentLink &&
      description === item.description &&
      isRecurring === isRecurringCheck &&
      itemLabels.length === item.labels.length &&
      itemLabels.every((label) => item.labels.includes(label)));

  useEffect(() => {
    if (isButtonDisabled) {
      setIsUpdated(false);
    } else {
      setIsUpdated(true);
    }
  }, [isButtonDisabled]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemObj = {
      ...item,
      title,
      cost,
      max,
      description,
      isRecurring,
      attachmentLink: link,
      labels: itemLabels,
    };
    if (phase) {
      itemObj.phaseId = phase;
    }
    if (quantity) {
      itemObj.quantity = quantity;
    }
    return updateCollectionItemMultiple(cardCollection, item.id, itemObj);
  };

  return (
    <>
      <ModalConfirmUpdateItem
        isOpen={needsConfirmation === true}
        setIsOpen={setNeedsConfirmation}
        handleConfirm={handleSubmit}
        handleSetExpanded={handleSetExpanded}
      />
      <div className="mr-4 mb-2">
        <form onSubmit={(e) => handleSubmit(e)} className="space-y-2">
          <InputSingle
            value={title}
            setValue={setTitle}
            label={"Service Name"}
            labelTextStyle={labelStlye}
            isDisabled={!isContractor}
          />
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-1">
            <div className="sm:flex-1">
              <InputSingle
                isRequired
                type="number"
                value={cost}
                setValue={setCost}
                label={"Quote low range"}
                labelTextStyle={labelStlye}
                isDisabled={!isContractor}
              />
            </div>
            <div className="sm:flex-1">
              <InputSingle
                type="number"
                value={max}
                setValue={setMax}
                label={"Quote high range"}
                labelTextStyle={labelStlye}
                isDisabled={!isContractor}
              />
            </div>
            {item.quantity && (
              <div className="sm:flex-1">
                <InputSingle
                  type="number"
                  value={quantity}
                  setValue={setQuantity}
                  label={"Quantity"}
                  labelTextStyle={labelStlye}
                  isDisabled={!isContractor}
                />
              </div>
            )}
          </div>
          {phase && (
            <InputSelection
              options={phaseOptions}
              label={"Project Phase"}
              value={phase}
              setValue={setPhase}
              width={"w-full"}
              labelTextStyle={labelStlye}
              isDisabled={!isContractor}
            />
          )}
          <LabelSection
            labels={labels ?? []}
            confirmedLabels={item.labels}
            itemLabels={itemLabels}
            setItemLabels={setItemLabels}
            setLabelQuery={setLabelQuery}
            labelQuery={labelQuery}
            labelValue={labelValue}
            setLabelValue={setLabelValue}
            workspaceId={workspaceId}
          />
          <InputSingle
            label="Link to shared drive"
            name="link"
            placeholder="Add a link to a shared drive"
            value={link}
            setValue={setLink}
            labelTextStyle={labelStlye}
          />
          <div className="flex justify-end">
            {item.attachmentLink && (
              <div className="flex items-center">
                <a
                  rel="noreferrer"
                  target="_blank"
                  className="flex flex-1 items-center text-accent-500"
                  href={item.attachmentLink}
                >
                  <span className="text-xs mr-1">Go to link</span>
                  <PaperClipIcon className="h-4 w-4" />
                </a>
              </div>
            )}
          </div>
          <InputTextarea
            value={description}
            setValue={setDescription}
            label={"Details"}
            labelTextStyle={labelStlye}
            isDisabled={!isContractor}
          />
          {type === "valueAddsStandard" && (
            <InputCheckboxSingle
              value={isRecurring}
              setValue={() => setIsRecurring(!isRecurring)}
              label="Can this Service be recurring?"
            />
          )}
          <div className="flex justify-end">
            <Button
              isInvisible={isButtonDisabled}
              disabled={isButtonDisabled}
              message="Update"
              type="submit"
              bg={"tertiary"}
            />
          </div>
        </form>
        <div>{/* Attachements */}</div>
        <CommentBox
          comments={comments}
          handleCreateComment={handleCreateComment}
          entityId={item.id}
          handleUpdateItem={handleUpdateItem}
        />
      </div>
    </>
  );
};

export const LabelList = ({ labels, handleRemove, confirmedLabels }) => {
  return (
    <ul className="flex space-x-1 my-2">
      {labels.map((item) => {
        const isConfirmed = confirmedLabels.includes(item);
        return (
          <li>
            <Pill
              handleClick={() => handleRemove(item)}
              message={item}
              isLower
              type={isConfirmed ? "primary" : "tertiary"}
            />
          </li>
        );
      })}
    </ul>
  );
};

export const LabelSection = ({
  itemLabels,
  setItemLabels,
  labels,
  confirmedLabels,
  labelValue,
  setLabelValue,
  labelQuery,
  setLabelQuery,
  labelStyle,
  workspaceId,
}) => {
  const { handleCreateLabel } = useContext(FunctionContext);
  const handleSelect = (item) => {
    if (itemLabels.includes(item.title)) return;

    if (!labels.find((label) => label.title === item.title)) {
      const obj = { title: item.title, workspaceId };
      handleCreateLabel(obj);
    }
    setItemLabels([...itemLabels, item.title]);
    setLabelValue("");
  };

  const handleRemove = (title) => {
    setItemLabels(itemLabels.filter((item) => item !== title));
  };
  return (
    <>
      <InputCombo
        label="Tags"
        placeholder="Add a tag"
        labelTextStyle={labelStyle}
        query={labelQuery}
        setQuery={setLabelQuery}
        options={labels.filter((item) => !itemLabels.includes(item.title))}
        handleSelect={handleSelect}
        value={labelValue}
      />
      {itemLabels.length > 0 && (
        <LabelList
          handleRemove={handleRemove}
          labels={itemLabels}
          confirmedLabels={confirmedLabels}
        />
      )}
    </>
  );
};
