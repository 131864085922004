import {
  ArrowDownTrayIcon,
  ArrowLongLeftIcon,
  CheckIcon,
  ChevronRightIcon,
} from "@heroicons/react/20/solid";
import React, { useState, useContext, useEffect } from "react";
import Button from "../../components/buttons/Button";
import ButtonText from "../../components/buttons/ButtonText";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import { CardFull } from "../../components/card/CardFull";
import Dropdown from "../../components/dropdowns/Dropdown";
import { UpdateForm } from "../../components/inputs/FormUpdate";
import ModalExample from "../../components/modals/ModalExample";
import ModalValueAddsSelection from "../../components/modals/ModalValueAddsSelection";
import Pill from "../../components/pills/Pill";
import { ExampleContext } from "../../store/example-context";
import ScopeOverviewPhase from "./ScopeOverviewPhase";
import { ModalContext } from "../../store/modal-context";
import Avatar from "../../components/avatars/Avatar";
import SlideoutLibrary from "../../components/slideouts/SlideoutLibrary";
import ModalTemplateAdd from "../../components/modals/ModalAddTemplate";
import CommentBox from "../../components/comment-box/CommentBox";
import { UserContext } from "../../store/user-context";
import ReactTimeago from "react-timeago";
import { FunctionContext } from "../../store/function-context";

import { FeedbackContext } from "../../store/feedback-context";
import { getCSVData } from "../../utils/getCSVData";
import Tooltip from "../../components/tooltips/Tooltip";
import DownloadIcon from "../../images/Download.svg";
import { formatCurrency } from "../../utils/formatCurrency";
import convertTimestampToFormattedDate from "../../utils/getDateTime";

import { ClientBanner } from "../../components/page-shells/ClientBanner";
import { MobileTopNavbarContent } from "../../components/navs/MobileTopNavbar";

function ScopeSectionOverview(props) {
  const {
    project,
    setIsRequestOpen,
    handleSelectRequestToAddress,
    handleCreateComment,
    handleCreatePhase,
    handleCreateItem,
    handleCreateTemplate,
    handleCreateValueAdd,
    handleAddValueAdd, //ScopeVA
    handleAddItemFromLibrary,
    comments,
    requests,
    labels,
    isApprover,
    isContractor,
    handleUpdateItem,
    handleUpdateItemMultiple,
    userInfo,
    items,
    phases,
    valueAdds,
    scopeVAs,
    valueAddsRequested,
    workspaceId,
    handleStatusCommentNotApproved
  } = props;
  const [activeView, setActiveView] = useState("Scope");
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const options = ["Scope", "Optional Services"];
  const originalIds = items.map((item) => item.originalId);
  let totalCost = items.reduce(
    (acc, item) => +acc + +item.cost * (item.quantity ? +item.quantity : 1),
    0
  );
  let totalMax = items.reduce(
    (acc, item) =>
      +acc +
      (item.max
        ? +item.max * (item.quantity ? +item.quantity : 1)
        : +item.cost * (item.quantity ? +item.quantity : 1)),
    0
  );
  const exampleCtx = useContext(ExampleContext);
  const { exampleId, setExampleId, imageURL } = exampleCtx;
  const { currency, scopeApprovers, approvalObj } = project;
  const scopeInvites = project.scopeInvites ?? [];

  const filteredScopeVAs = scopeVAs.filter(
    (va) => va.isRecurring || !items.some((item) => item.vaId === va.vaId)
  );
  return (
    <>
      <MobileTopNavbarContent>
        <div className="flex flex-col items-center justify-center">
          <p>{project.title}</p>
          <p className="text-sm">Proposed scope  {totalCost} - {totalMax} EX VAT </p>
        </div>
      </MobileTopNavbarContent>
      <SlideoutLibrary
        title={"My Services Libray"}
        isOpen={activeModal === "services-lib"}
        setIsOpen={setActiveModal}
        handleCreate={handleCreateValueAdd}
        handleAddValueAdd={handleAddValueAdd}
        handleAddItem={handleAddItemFromLibrary}
        valueAdds={valueAdds}
        scopeVAs={scopeVAs}
        currency={currency.symbol}
        isApproved={approvalObj}
        items={items}
        workspaceId={workspaceId}
        labels={labels}
        isScopeApproved={approvalObj}
      />
      <ModalExample
        imageURL={imageURL}
        isOpen={exampleId === "project-scope"}
        setIsOpen={setExampleId}
      />
      <ModalTemplateAdd
        isOpen={activeModal === "create-template"}
        setIsOpen={setActiveModal}
        handleCreate={handleCreateTemplate}
      />
      <div className="w-full flex flex-col min-h-screen bg-primary-100">
        <ClientBanner
          project={project}
          phases={phases}
          items={items}
          projectName={project.title}
          approvers={project.scopeApprovers}
          isContractor={isContractor}
          currency={currency}
        />
        <div className="flex md:hidden md:pt-0">
          <PageMenu
            options={options}
            activeView={activeView}
            handleSelect={setActiveView}
          />
        </div>
        <div className="flex flex-1 md:pt-10">
          <section
            style={{ flex: 1 }}
            className={`${activeView === "Scope" ? "block" : "hidden"
              } md:block flex-1 bg-primary-100 border-r border-r-[#FCC4C7] md:pt-5 px-2 md:px-4 md:pl-10 pb-5`}
          >
            <div className="max-w-4xl m-auto">
              <ScopeBanner
                isApproved={approvalObj}
                currency={currency.symbol}
                budget={totalCost}
                max={totalMax}
                clientApproval={
                  <ClientApproval
                    scopeApprovers={scopeApprovers}
                    scopeInvites={scopeInvites}
                    approvalObj={approvalObj}
                    isContractor={isContractor}
                    projectScopeStatus={project.scopeStatus}
                    projectId={project.id}
                    isReady={project.isReadyForApproval}
                    handleCreateComment={handleCreateComment}
                    workspaceId={workspaceId}
                    handleStatusCommentNotApproved={handleStatusCommentNotApproved}
                    comments={[
                      ...comments.filter(
                        (comment) => comment.entityId === project.id
                      ),
                    ]}
                  />
                }
              />
              {/*<ActionButton />*/}
              <div className="pb-2 mt-2">
                {isContractor && !project.isAddInfoHidden && (
                  <div className="my-3">
                    <p className="text-left text-gray-500">
                      Start building your project scope here by adding phases
                      and services. When your project scope is ready for your
                      client to approve click 'Ready for approval' and invite
                      your client to approve and collaborate.
                    </p>
                    <div className="flex justify-end">
                      <ButtonText
                        handleClick={() => {
                          handleUpdateItem(
                            {
                              collection: "projects",
                              itemId: project.id,
                            },
                            { prop: "isAddInfoHidden", value: true }
                          );
                        }}
                        width=""
                        message="Hide"
                      />
                    </div>
                  </div>
                )}
                {phases.map((phase) => (
                  <ScopeOverviewPhase
                    key={phase.id}
                    isAddHidden={!isContractor}
                    items={items.filter((item) => item.phaseId === phase.id)}
                    allItems={items}
                    allValueAdds={valueAddsRequested}
                    phase={phase}
                    phases={phases}
                    handleUpdateItem={handleUpdateItem}
                    handleCreateComment={handleCreateComment}
                    handleCreateItem={handleCreateItem}
                    comments={comments}
                    userInfo={userInfo}
                    scopeId={project.scopeId}
                    projectId={project.id}
                    isContractor={isContractor}
                    currency={currency}
                    labels={labels}
                    workspaceId={workspaceId}
                    approvalObj={approvalObj}
                  />
                ))}
                {/*Completed phase*/}
                {items.some((item) => item.isCompleted) && (
                  <ScopeOverviewPhase
                    isAddHidden={!isContractor}
                    items={items.filter((item) => item.isCompleted)}
                    allItems={items}
                    allValueAdds={valueAddsRequested}
                    phase={{ title: "Completed", id: "completed" }}
                    phases={phases}
                    handleUpdateItem={handleUpdateItem}
                    handleCreateComment={handleCreateComment}
                    handleCreateItem={handleCreateItem}
                    comments={comments}
                    userInfo={userInfo}
                    scopeId={project.scopeId}
                    projectId={project.id}
                    isContractor={isContractor}
                    currency={currency}
                    labels={labels}
                    workspaceId={workspaceId}
                    approvalObj={approvalObj}
                  />
                )}
              </div>
              {isContractor && (
                <div className="w-full mt-5 space-y-5">
                  <Button
                    handleClick={() => handleCreatePhase()}
                    bg="accent"
                    message="+ Add Phase"
                  />
                </div>
              )}
            </div>
          </section>
          <section
            style={{ flex: 1 }}
            className={`${activeView === "Optional Services" ? "block" : "hidden"
              } md:block flex-1 md:bg-white pt-5 px-2 md:px-10`}
          >
            <div className="max-w-4xl m-auto">
              <>
                <div>
                  <ValueAddBanner
                    isApprover={isApprover}
                    isContractor={isContractor}
                    setIsRequestOpen={setIsRequestOpen}
                    setActiveModal={setActiveModal}
                  />
                </div>
                {filteredScopeVAs.length > 0 || requests.length > 0 ? (
                  <div className="mt-12 space-y-2">
                    <ValueAddList
                      valueAdds={filteredScopeVAs}
                      addsRequested={valueAddsRequested}
                      isContractor={isContractor}
                      handleUpdateItem={handleUpdateItem}
                      projectId={project.id}
                      currency={currency}
                      userInfo={userInfo}
                      handleCreateComment={handleCreateComment}
                      comments={comments}
                      workspaceId={workspaceId}
                      labels={labels}
                    />
                    <RequestList
                      requests={requests.filter(
                        (item) =>
                          !item.isRejected && !originalIds.includes(item.id)
                      )}
                      addsRequested={valueAddsRequested}
                      handleSelectRequestToAddress={
                        handleSelectRequestToAddress
                      }
                      handleUpdateItem={handleUpdateItem}
                      handleUpdateItemMultiple={handleUpdateItemMultiple}
                      currency={currency}
                      isContractor={isContractor}
                      handleCreateComment={handleCreateComment}
                      comments={comments}
                      userInfo={userInfo}
                      phases={phases}
                    />
                  </div>
                ) : (
                  <div className="mt-12 space-y-2">
                    {isContractor && (
                      <p className="text-gray-500 text-sm">
                        You have no optional services added for this project,
                        add some from your service library
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default ScopeSectionOverview;

const PageMenu = ({ options, activeView, handleSelect }) => {
  return (
    <div>
      <ul className="flex items-center space-x-4 m-5">
        {options.map((option) => {
          const isActiveView = option === activeView;
          return (
            <li
              onClick={() => handleSelect(option)}
              key={option}
              label={`Select ${option} view`}
              role="button"
              className={`${isActiveView ? "text-primary-500 underline" : "text-gray-800"
                } font-bold text-lg`}
            >
              {option}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const ClientApproval = ({
  isContractor,
  scopeApprovers,
  scopeInvites,
  approvalObj,
  handleCreateComment,
  comments,
  projectId,
  isReady,
  workspaceId,
  handleStatusCommentNotApproved
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isApprovalPending =
    isContractor && !approvalObj && scopeApprovers.length <= 0;
  const pillMessage = approvalObj
    ? `Approved by ${approvalObj.approverName}`
    : "Pending approval";

  const { handleUpdateItem } = useContext(FunctionContext);
  const { setActiveModal } = useContext(ModalContext);
  const { fetchWorkspaceProjects } = useContext(UserContext);

  const setReadyObj = {
    itemObj: { collection: "projects", itemId: projectId },
    obj: { prop: "isReadyForApproval", value: true },
    feedbackObj: {
      message: "Project is ready for approval",
      callback: () => {
        setActiveModal("invite-scope")
        fetchWorkspaceProjects(workspaceId)
      },
    },
  };

  const setSentApprovalObj = {
    itemObj: { collection: "projects", itemId: projectId },
    obj: {
      prop: "scopeStatus", value: {
        state: 'pending',
        timestamp: Date.now(),
      }
    },
    feedbackObj: {
      message: "Project is now pending a response",
      callback: () => fetchWorkspaceProjects(workspaceId),
    }
  }
  const { itemObj, obj, feedbackObj } = setReadyObj;

  const sendApprovalRequest = () => {
    handleUpdateItem(itemObj, obj, feedbackObj)
    handleUpdateItem(setSentApprovalObj.itemObj, setSentApprovalObj.obj, setSentApprovalObj.feedbackObj);
  }

  return (
    <div className="space-y-2 relative">
      {isApprovalPending && scopeInvites.length <= 0 && !isReady ? (
        <div className="flex justify-end w-full">
          <Button
            handleClick={() => sendApprovalRequest()}
            bg="tertiary"
            message="Ready for approval"
          />
        </div>
      ) : (
        <div className="flex justify-end items-end flex-col">
          <div className="flex md:items-end justify-end flex-col md:flex-row space-y-2">
            {!approvalObj && (
              <Button
                shadow="shadow-lg"
                bg="tertiary"
                message={"Client approval"}
                handleClick={() => setIsExpanded(!isExpanded)}
                icon={
                  <ChevronRightIcon
                    className={`h-5 w-5 text-white ${isExpanded && "rotate-90"
                      }`}
                  />
                }
              />
            )}
          </div>
          {isExpanded && !approvalObj && (
            <ClientApprovalCenter
              comments={comments}
              handleCreateComment={handleCreateComment}
              handleUpdateItem={handleUpdateItem}
              entityId={projectId}
              approvalObj={approvalObj}
              pillMessage={pillMessage}
              handleStatusCommentNotApproved={handleStatusCommentNotApproved}
            />
          )}
        </div>
      )}
      {isApprovalPending && scopeInvites.length > 0 && (
        <div className="flex">
          <div>
            <Pill type="members" message={"Invites pending"} />
          </div>
        </div>
      )}
    </div>
  );
};

const ClientApprovalCenter = (props) => {
  const { entityId, approvalObj, pillMessage } = props;
  const { userInfo } = useContext(UserContext);
  const { setConfirmObj } = useContext(ModalContext);
  const approvalItemObj = {
    timestamp: Date.now(),
    approverUid: userInfo.uid,
    approverId: userInfo.id,
    approverName: userInfo.name,
    status: "approved",
  };
  const itemObj = { collection: "projects", itemId: entityId };
  const obj = { prop: "approvalObj", value: approvalItemObj };
  const confirmObj = { itemObj, obj, type: "approval" };
  const handleApprove = () => {
    return setConfirmObj(confirmObj);
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <div
      className={`absolute top-12 left-0 bg-white pb-3 pt-1 px-5 border border-tertiary rounded space-y-2 mt-2 transition-all duration-300 ${!isVisible ? "-ml-5 opacity-0" : "ml-0 opacity-100"
        }`}
    >
      <CommentBox {...props} />
      {approvalObj ? (
        <div className="flex space-x-1 items-center">
          <Pill message={pillMessage} />
          <span className="text-xs text-gray-400">
            <ReactTimeago date={approvalObj.timestamp} />
          </span>
        </div>
      ) : (
        <div className="flex justify-end">
          <Button
            handleClick={() => handleApprove()}
            width={"flex-1"}
            bg={"tertiary"}
            message={"I approve this project scope"}
          />
        </div>
      )}
    </div>
  );
};


const ScopeBanner = ({ currency, budget, max, clientApproval, isApproved }) => {
  return (
    <div className="flex items-center">
      <h1 className="font-bold text-left flex-1 text-3xl hidden xl:block">
        {isApproved ? "Approved" : "Proposed"} Scope
      </h1>
      <div className="flex justify-start md:justify-end items-center space-x-2">
        {clientApproval}
        <div className="flex flex-col items-center md:items-end justify-center md:justify-end">
          <span class="flex flex-col bg-white md:text-right text-left border-4 sm:border-[6px] border-primary-300 px-3 py-1.5 rounded-lg shadow-lg">
            <span class="text-neutral-400 text-xs font-bold uppercase line-clamp-1">
              Project budget: ex TAX
            </span>
            <div className="flex items-center justify-end">
              <span class="text-sm text-neutral-600 font-bold">
                {formatCurrency(budget, currency)}
              </span>
              {max !== budget && (
                <span class="text-sm text-neutral-600 font-bold ml-[.2em]">
                  - {formatCurrency(max, currency)}
                </span>
              )}
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

const ValueAddBanner = ({ setIsRequestOpen, isContractor, setActiveModal }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <h2 className="font-bold text-left text-3xl hidden md:block md:mt-3">
        Optional Services
      </h2>
      <div className="flex-1 flex lg:items-end lg:justify-end justify-start items-start">
        <div className="flex space-x-2">
          {isContractor && (
            <Button
              handleClick={() => setActiveModal("services-lib")}
              message="Service Library"
              bg="accent"
            />
          )}
          <Button
            handleClick={() => setIsRequestOpen(true)}
            message="Add Client Request"
            bg="tertiary"
          />
        </div>
      </div>
    </div>
  );
};

const ValueAddList = ({
  valueAdds,
  addsRequested,
  isContractor,
  handleUpdateItem,
  projectId,
  currency,
  userInfo,
  comments,
  handleCreateComment,
  workspaceId,
  labels,
}) => {
  const handleUpdateValueAdd = (id, va, setClose) => {
    const updatedItems = valueAdds.map((item) => (item.id === id ? va : item));
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "valueAdds", value: updatedItems };
    return handleUpdateItem(itemObj, obj, setClose);
  };
  return (
    <ul className="space-y-2">
      {valueAdds.map((valueAdd) => {
        const addedCount = addsRequested.filter(
          (item) => item.originalId === valueAdd.id
        ).length;
        return (
          <ValueAddItem
            valueAdd={valueAdd}
            key={valueAdd.id}
            currency={currency.symbol}
            isRequested={addsRequested.some((add) => add.id === valueAdd.id)}
            isContractor={isContractor}
            handleUpdateValueAdd={handleUpdateValueAdd}
            handleUpdateItem={handleUpdateItem}
            userInfo={userInfo}
            comments={[
              ...comments.filter((comment) => comment.entityId === valueAdd.id),
            ]}
            handleCreateComment={handleCreateComment}
            addedCount={addedCount}
            workspaceId={workspaceId}
            labels={labels}
          />
        );
      })}
    </ul>
  );
};

const ValueAddItem = ({
  valueAdd,
  currency,
  isRequested,
  isContractor,
  handleUpdateValueAdd,
  handleUpdateItem,
  userInfo,
  comments,
  handleCreateComment,
  addedCount,
  workspaceId,
  labels,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isCostEditable, setIsCostEditable] = useState(false);
  const [cost, setCost] = useState(valueAdd.cost);
  const [isAnimating, setIsAnimating] = useState(false);
  const [initialCount, setInitialCount] = useState(addedCount);
  const [isUpdated, setIsUpdated] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const { setConfirmObj } = useContext(ModalContext);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${isExpanded ? "rotate-90" : "rotate-0"
        }`}
    />
  );

  useEffect(() => {
    if (initialCount < addedCount) {
      setIsAnimating(true);
    }
    setInitialCount(addedCount);
  }, [addedCount]);

  useEffect(() => {
    if (isAnimating) {
      setTimeout(() => {
        setIsAnimating(false);
        setInitialCount(addedCount);
      }, 1000);
    }
  }, [isAnimating]);

  const handleAdd = (action) => {
    valueAdd.action = action;
    setConfirmObj(valueAdd);
  };

  const icon = !isRequested ? (
    <ArrowLongLeftIcon className="w-8 text-white" />
  ) : (
    <CheckIcon className="w-8 text-white" />
  );
  let bg = isRequested ? "bg-accent" : "bg-tertiary";

  let options = [
    { action: "Add to scope", handleSelect: () => handleAdd("add") },
    { action: "Delete", color: "red", handleSelect: () => handleAdd("delete") },
  ];
  options = options.filter((option) => option.isVisible !== false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedItem = {
      ...valueAdd,
      cost,
    };
    return handleUpdateValueAdd(valueAdd.id, updatedItem, () =>
      setIsCostEditable(false)
    );
  };

  const handleSetIsCoseEditable = (value) => {
    if (!isContractor) {
      return;
    } else {
      setIsCostEditable(value);
    }
  };

  const handleSetExpanded = (isForced) => {
    if (!isUpdated || isForced) {
      setIsExpanded(!isExpanded);
    } else {
      setNeedsConfirmation(true);
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      setIsUpdated(false);
    }
  }, [isExpanded]);

  return (
    <li
      className={`rounded-lg shadow bg-white transition-all duration-1000 ${isAnimating ? "opacity-0 ml-10" : "opacity-100"
        }`}
    >
      <div className="flex items-center">
        <button
          disabled={isRequested}
          onClick={() => handleAdd(valueAdd.id)}
          className={`px-3 rounded-l-lg py-5 ${bg}`}
        >
          {icon}
        </button>
        <div className="flex-1">
          <div className="flex ml-5 items-center pt-1">
            <Pill message="Optional Service" type="secondary" />
            <div className="flex flex-1 justify-end">
              <Dropdown options={options} />
            </div>
          </div>
          <div
            onClick={() => handleSetExpanded()}
            className="flex items-center cursor-pointer"
          >
            <ButtonIcon
              padding={"pl-2 py-2"}
              label={buttonLabel}
              icon={buttonIcon}
            />
            <div>
              <div>
                <div className="ml-1">
                  <h3 className="text-left leading-6">
                    <span className="text-gray-400 text-sm mr-1">
                      {valueAdd.ref}
                    </span>{" "}
                    {valueAdd.title}{" "}
                    {addedCount > 0 && (
                      <span className="text-sm text-gray-400">
                        ({addedCount})
                      </span>
                    )}
                  </h3>
                </div>
              </div>
            </div>
            <button className="flex flex-1 justify-end items-center font-bold pr-4 cursor-pointer">
              <span>{currency}</span>

              <span>{valueAdd.cost}</span>
              {valueAdd.max > 0 && (
                <p className="ml-[.2em]">
                  - {currency}
                  {valueAdd.max}
                </p>
              )}
            </button>
            {/* {isCostEditable ? (
              <UpdateForm
                handleSubmit={(e) => handleSubmit(e)}
                setValue={setCost}
                value={cost}
                type={"Quote"}
              />
            ) : (
              <button
                onClick={() => handleSetIsCoseEditable(true)}
                className="flex flex-1 justify-end items-center font-bold pr-4 cursor-pointer"
              >
                <span>{currency}</span>
                <span>{valueAdd.cost}</span>
                {valueAdd.max > 0 && (
                  <p className="ml-[.2em]">
                    - {currency}
                    {valueAdd.max}
                  </p>
                )}
              </button>
            )} */}
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="p-3">
          <CardFull
            handleCreateComment={handleCreateComment}
            handleUpdateItem={handleUpdateItem}
            type={"valueAddsStandard"}
            userInfo={userInfo}
            comments={comments}
            phases={[]}
            item={valueAdd}
            isContractor={isContractor}
            cardCollection={`${valueAdd.type}s`}
            workspaceId={workspaceId}
            labels={labels}
            setIsUpdated={setIsUpdated}
            needsConfirmation={needsConfirmation}
            setNeedsConfirmation={setNeedsConfirmation}
            handleSetExpanded={handleSetExpanded}
          />
        </div>
      )}
    </li>
  );
};

const RequestList = ({
  requests,
  handleSelectRequestToAddress,
  handleUpdateItem,
  handleUpdateItemMultiple,
  currency,
  isContractor,
  userInfo,
  handleCreateComment,
  comments,
  phases,
}) => {
  return (
    <ul className="space-y-2">
      {requests.map((request) => (
        <RequestItem
          handleSelect={handleSelectRequestToAddress}
          handleUpdateItem={handleUpdateItem}
          handleUpdateItemMultiple={handleUpdateItemMultiple}
          request={request}
          key={request.id}
          currency={currency.symbol}
          isRequested={request.isAdded}
          isContractor={isContractor}
          userInfo={userInfo}
          handleCreateComment={handleCreateComment}
          comments={[
            ...comments.filter((comment) => comment.entityId === request.id),
          ]}
          phases={phases}
        />
      ))}
    </ul>
  );
};

const RequestItem = ({
  request,
  currency,
  handleUpdateItem,
  isRequested,
  isContractor,
  userInfo,
  handleCreateComment,
  comments,
  phases,
  workspaceId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [cost, setCost] = useState(request.cost);
  const [isCostEditable, setIsCostEditable] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [needsConfirmation, setNeedsConfirmation] = useState(false);
  const { setConfirmObj } = useContext(ModalContext);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${isExpanded ? "rotate-90" : "rotate-0"
        }`}
    />
  );

  const handleAdd = () => {
    setConfirmObj(request);
  };

  const bg = isRequested ? "bg-accent" : "bg-tertiary";
  const handleClick =
    request.isApproved && request.cost ? () => handleAdd() : () => void 0;

  const deleteObj = { prop: "isDeleted", value: true };
  const updateObj = { prop: "cost", value: cost };
  const itemObj = { collection: "requests", itemId: request.id };
  let options = [
    { action: "Add to scope", handleSelect: handleClick },
    {
      action: "Delete request",
      handleSelect: () => handleUpdateItem(itemObj, deleteObj),
      color: "red",
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    return handleUpdateItem(itemObj, updateObj).then(() =>
      setIsCostEditable(false)
    );
  };

  const handleSetExpanded = (isForced) => {
    if (!isUpdated || isForced) {
      setIsExpanded(!isExpanded);
    } else {
      setNeedsConfirmation(true);
    }
  };

  useEffect(() => {
    if (!isExpanded) {
      setIsUpdated(false);
    }
  }, [isExpanded]);

  return (
    <li
      className={`rounded-lg shadow bg-white ${!request.isApproved && !isContractor ? "opacity-40" : "opacity-100"
        }`}
    >
      <div className="flex items-center">
        <button
          onClick={handleClick}
          className={`px-3 rounded-l-lg py-5 cursor-pointer ${bg} ${!request.cost && "opacity-40"
            }`}
        >
          <ArrowLongLeftIcon className="w-8 text-white" />
        </button>
        <div className="flex-1">
          <div className="flex ml-5 items-center">
            <Pill message="Client Request" type="tertiary" />
            <div className="flex flex-1 justify-end">
              <Dropdown options={options} />
            </div>
          </div>
          <div className="flex items-center">
            <ButtonIcon
              padding={"pl-2 py-2"}
              label={buttonLabel}
              icon={buttonIcon}
              handleClick={() => handleSetExpanded()}
            />
            <div>
              <div>
                <div className="ml-1">
                  <h3 className="text-left leading-6">
                    <span className="text-gray-400 text-sm mr-1">
                      {request.ref}
                    </span>{" "}
                    {request.title}
                  </h3>
                </div>
              </div>
            </div>
            <div className="flex flex-1 justify-end items-center font-bold">
              {request.cost ? (
                isCostEditable ? (
                  <UpdateForm
                    handleSubmit={(e) => handleSubmit(e)}
                    setValue={setCost}
                    value={cost}
                    type={"Quote"}
                  />
                ) : (
                  <button
                    onClick={() => setIsCostEditable(true)}
                    className="flex flex-1 justify-end items-center font-bold pr-4 cursor-pointer"
                  >
                    <span>{currency}</span>
                    <span>{request.cost}</span>
                    {request.max > 0 && (
                      <p className="ml-[.2em]">
                        - {currency}
                        {request.max}
                      </p>
                    )}
                  </button>
                )
              ) : (
                <button onClick={() => setIsExpanded(true)}>
                  <span className="font-bold text-sm px-2 py-1 mr-2">
                    {isContractor ? "Add" : "Waiting for"} quote
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className="p-3">
          <CardFull
            handleCreateComment={handleCreateComment}
            handleUpdateItem={handleUpdateItem}
            type={"requests"}
            userInfo={userInfo}
            comments={comments}
            phases={phases}
            item={request}
            isContractor={isContractor}
            cardCollection={"requests"}
            workspaceId={workspaceId}
            setIsUpdated={setIsUpdated}
            needsConfirmation={needsConfirmation}
            setNeedsConfirmation={setNeedsConfirmation}
            handleSetExpanded={handleSetExpanded}
          />
        </div>
      )}
    </li>
  );
};

const ValueAddOverlay = (props) => {
  return <ModalValueAddsSelection {...props} />;
};
