import React, { useContext } from "react";
import contractor from "../../images/zoe.png";
import { useEffect, useState } from "react";
import {
  auth,
  db,
  registerWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithGoogle,
} from "../../firebase";
import InputSingle from "../../components/inputs/InputSingle";
import Button from "../../components/buttons/Button";
import { useNavigate, useLocation } from "react-router";
import Alert from "../../components/alerts/Alert";
import googleLogo from "../../images/google-logo.svg";
import logo from "../../images/logo.png";
import { FeedbackContext } from "../../store/feedback-context";
import { UserContext } from "../../store/user-context";
import { Link } from "react-router-dom";

export default function LoginPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [isLocationChecked, setIsLocationChecked] = useState(false);
  const { alert, setAlert } = useContext(FeedbackContext);
  const { user, authLoading, hasLoggedIn, setHasLoggedIn } =
    useContext(UserContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasLoggedIn) {
      setHasLoggedIn(false);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (!isLocationChecked && !user) {
      if (location.pathname !== "/" || location.pathname !== "/login") {
        navigate("/login");
      } else {
        setIsLocationChecked(true);
      }
    }
    //es-lint-disable-next-line
  }, [isLocationChecked, user]);

  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    else {
      goToUserWorkspace();
    }
    //es-lint-deisable-next-line
  }, [user, authLoading, navigate]);

  const goToUserWorkspace = async () => {
    try {
      db.collection("users")
        .where("uid", "==", user?.uid)
        .onSnapshot((value) => {
          const doc = value.docs[0];
          if(!doc) return;
          const data = doc.data();
          if (data.workspaces.length > 0) {
            navigate(`/workspace/${data.workspaces[0].workspaceId}/Projects`);
          } else {
            navigate("/user");
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoggingIn) {
      signInWithEmailAndPassword(email, password).then((res) => {
        if (!res.isValid) {
          setAlert({ type: "error", message: res.message });
        }
      });
    } else {
      try {
        registerWithEmailAndPassword(name, email, password);
      } catch (error) {
        setAlert("There has been an error creating this account");
      }
    }
  };

  const handleToggleView = () => {
    setIsLoggingIn(!isLoggingIn);
  };

  return (
    <div className="flex h-screen">
      {alert && (
        <Alert isTop={true} message={alert.message} type={alert.type} />
      )}
      <div className="hidden md:flex flex-col justify-center items-center w-1/2 bg-[#984FBF]">
        <img className="w-2/3 max-w-xl" src={contractor} alt="contractor" />
        <div className="max-w-xl mt-10">
          <h1 className="text-white text-3xl font-bold">
            The smarter way to scope your work
          </h1>
        </div>
      </div>
      <div className="flex flex-col justify-center w-full md:w-1/2 py-20 px-10 my-auto h-screen max-h-[800px]">
        <div className="max-w-lg w-full mx-auto flex-flex-col flex-1">
          <Logo />
          <WelcomeMessage
            isLoggingIn={isLoggingIn}
            handleToggleView={handleToggleView}
          />
          <div className="flex-1">
            <form className="mb-5 mt-10 space-y-5" onSubmit={handleSubmit}>
              <LoginForm
                email={email}
                setEmail={setEmail}
                password={password}
                setPassword={setPassword}
                name={name}
                setName={setName}
                isLoggingIn={isLoggingIn}
              />
              <div className="w-full mt-10">
                <Button
                  width={"w-full"}
                  type="Submit"
                  message={isLoggingIn ? "Login" : "Create account"}
                />
              </div>
            </form>
            <ContinueWith />
          </div>
        </div>
      </div>
    </div>
  );
}

const Logo = () => {
  return (
    <div className="flex justify-start w-full items-start">
      <img className="h-12 w-auto" src={logo} alt="Scopey" />
      <span className="sr-only">Scopey</span>
    </div>
  );
};

export const WelcomeMessage = ({ handleToggleView, isLoggingIn, welcome }) => {
  const message = isLoggingIn ? "Register here" : "Go back to login";
  const preMessage = isLoggingIn
    ? "Don't have an account?"
    : "Already have an account?";
  welcome = welcome ?? (
    <h1 className="text-gray-700 text-3xl font-bold">{welcome}</h1>
  );
  return (
    <div className="pt-6 text-left">
      <div>
        {welcome}
        <span className="text-gray-500 font-bold text-lg">
          {preMessage}
          <span
            onClick={() => handleToggleView()}
            role="button"
            className="underline ml-1 text-sm"
          >
            {message}
          </span>
        </span>
      </div>
    </div>
  );
};

export const LoginForm = ({
  email,
  setEmail,
  password,
  setPassword,
  isLoggingIn,
  name,
  setName,
}) => {
  return (
    <>
      {!isLoggingIn && (
        <InputSingle
          isRequired={true}
          type="text"
          name="name"
          label="Full Name"
          value={name}
          setValue={setName}
        />
      )}
      <InputSingle
        isRequired={true}
        type="email"
        name="email"
        label="Email"
        value={email}
        setValue={setEmail}
        labelTextStyle={"text-gray-700 font-bold"}
      />
      <InputSingle
        isRequired={true}
        type="password"
        name="password"
        label="Password"
        value={password}
        setValue={setPassword}
        labelTextStyle={"text-gray-700 font-bold"}
      />
    </>
  );
};

export const ContinueWith = () => {
  return (
    <div className="">
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center text-sm">
          <span className="bg-white px-2 text-gray-500 mt-5">
            Or continue with
          </span>
        </div>
      </div>
      {/*<div className="mt-6 grid grid-cols-3 gap-3"> */}
      <div className="mt-2">
        <div
          onClick={signInWithGoogle}
          role={"button"}
          className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
        >
          <span className="sr-only">Sign in with Google</span>
          <img alt="Google logo" src={googleLogo} />
        </div>
      </div>
      <div className="flex justify-end mt-5">
        <Link to="/forgot-password">
          <span className="italic text-sm text-gray-500 hover:underline">
            Forgot your password?
          </span>
        </Link>
      </div>
    </div>
  );
};
