import React from "react";
import SectionHeading from "../../components/headings/HeadingSection";
import { ValueAddsTable } from "../../components/tables/TableCreateScope";

function WorkspaceSectionValueAdds(props) {
  const { workspace, valueAdds, handleUpdateItem, scopeVAs, labels } = props;

  return (
    <section className="max-w-4xl mx-auto box-border px-2">
      <div className="mt-5">
        <SectionHeading
          subheading={
            "Build a library of your offerings, which can be quickly added to any project"
          }
          heading={`${workspace.title} Service Library`}
        />
      </div>
      <div>
        <ValueAddsTable
          valueAdds={valueAdds}
          scopeVAs={scopeVAs}
          valueAddsSelected={[]}
          currency={workspace.currency}
          handleUpdateItem={handleUpdateItem}
          isClient={false}
          handleToggleValueAdd={() => {}}
          isFromDashboard={true}
          isInWorkspaceView={true}
          workspaceId={workspace.id}
          labels={labels}
        />
      </div>
    </section>
  );
}

export default WorkspaceSectionValueAdds;
