import React from "react";

function Button(props) {
  const { message, handleClick, disabled, icon } = props;
  const type = props.type ?? "button";
  const opacity = disabled ? "opacity-20" : "";
  const color = props.color ?? "text-gray-500";
  const margin = props.margin ?? "";
  const padding = props.padding ?? "py-2 px-4";
  const flexDirection = props.flexDirection ?? "flex-row";
  const width = props.width ?? "w-full";
  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={`flex items-center hover:underline justify-center rounded-md border border-transparent text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 bg-none ${width} ${color} ${opacity} ${margin} ${padding} ${flexDirection}`}
    >
      {icon && icon}
      {message}
    </button>
  );
}

export default Button;
