import { createContext, useState } from "react";

export const EmailContext = createContext({
  emailInfo: {},
  handleSetEmailInfo: (project, client) => {},
});

export function EmailContextProvider(props) {
  const [emailInfo, setEmailInfo] = useState({ isInfoSet: false });

  const handleSetEmailInfo = (project, clientName) => {
    const owner = project.users?.find((item) => item.type === "Owner");
    setEmailInfo({
      clientName,
      clientEmail: project.leadContact,
      projectName: project.title,
      contractorName: owner?.name,
      contractorEmail: owner?.email,
    });
  };

  const contextValue = {
    emailInfo,
    handleSetEmailInfo,
  };

  return (
    <EmailContext.Provider value={contextValue}>
      {props.children}
    </EmailContext.Provider>
  );
}
