import React from "react";
import { useContext } from "react";
import ModalInviteScope from "../../components/modals/ModalInviteScope";
import { createScopeInvite } from "../../services/ScopeService";
import { FeedbackContext } from "../../store/feedback-context";
import { ModalContext } from "../../store/modal-context";
import ScopeDashboard from "./ScopeDashboardRebuild";

function ScopeDashboardHolder(props) {
  const {
    project,
    userInfo,
    client,
    isContractor,
    isApprover,
    isLoading,
    setActiveRequest,
    workspaceProjects,
    scopeId,
    projectId,
    workspaceId,
    comments,
    requests,
    items,
    phases,
    valueAdds,
    scopeVAs,
    templates,
    labels
  } = props;
  const { activeModal, setActiveModal } = useContext(ModalContext);
  const { setAlert, handleFeedback } = useContext(FeedbackContext);

  const handleInviteApprover = (email, valueObj) => {
    const obj = { email, project, valueObj };
    try {
      return createScopeInvite(userInfo, obj).then(() =>
        handleFeedback("Approver Invited", false, false)
      );
    } catch {
      setAlert("There has been an error with this request");
    }
  };

  return (
    <>
      <ModalInviteScope
        setIsOpen={setActiveModal}
        isOpen={activeModal === "invite-scope"}
        approvers={project.scopeApprovers}
        invites={project.scopeInvites}
        projectId={project.id}
        handleInviteApprover={handleInviteApprover}
      />
      <ScopeDashboard
        scopeId={scopeId}
        projectId={projectId}
        project={project}
        workspaceId={workspaceId}
        comments={comments}
        requests={requests}
        items={items}
        phases={phases}
        valueAdds={valueAdds}
        scopeVAs={scopeVAs}
        templates={templates}
        labels={labels}
        setActiveRequest={setActiveRequest}
        workspaceProjects={workspaceProjects}
        userInfo={userInfo}
        client={client}
        isContractor={isContractor}
        isApprover={isApprover}
        isLoading={isLoading}
      />
    </>
  );
}

export default ScopeDashboardHolder;
