import React from "react";

function Toast(props) {
  const { message } = props;
  return (
    <div className="flex justify-center w-full fixed bottom-2 right-0">
      <div className="z-10 center-content bg-none ">
        <div className="w-100 text-center rounded-md shadow px-5 py-2 text-green-800 bg-green-200">
          {message}
        </div>
      </div>
    </div>
  );
}

export default Toast;
