import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputTextarea from "../inputs/InputTextarea";
import Button from "../buttons/Button";
import InputSingle from "../inputs/InputSingle";
import { getTwoDigitNumber } from "../../utils/getCode";

export default function ModalRequestAdd(props) {
  const { isOpen, setIsOpen, handleCreate, phases, requests } = props;
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [description, setDescription] = useState("");

  const clearState = () => {
    setIsOpen(false);
    setTitle("");
    setDueDate("");
    setDescription("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = { title, description, dueDate, attachmentLink: link };
    let phaseId = phases.length < 1 ? "" : phases[phases.length - 1].id;
    obj.ref = `CR-${getTwoDigitNumber(requests.length + 1)}`;
    return handleCreate(phaseId, obj);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={clearState}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col divide-y divide-gray-200 bg-none"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-slate-100 px-4 py-6 sm:px-6 rounded">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          New Product/Service
                        </Dialog.Title>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Request a quote for an additional product/service in
                          this project.{" "}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          {/*
                          <InputSelection
                            required
                            label="Phase name"
                            name="phase-name"
                            value={phase}
                            setValue={setPhase}
                            options={selectOptions}
                          />
                           
                          <InputSingle
                            required
                            type="date"
                            label="Required Date"
                            name="date"
                            value={dueDate}
                            setValue={setDueDate}
                            /> */}
                          <InputSingle
                            isRequired
                            label="Short Title"
                            name="title"
                            placeholder="Name your request"
                            value={title}
                            setValue={setTitle}
                          />
                          <InputSingle
                            label="Link to shared drive"
                            name="link"
                            placeholder="Add a link to an example in a shared drive"
                            value={link}
                            setValue={setLink}
                          />
                          <InputTextarea
                            label="Description"
                            name="description"
                            placeholder="Your description should make it easy for your service provider to understand exactly what is included in this request"
                            value={description}
                            setValue={setDescription}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <div className="flex">
                      <div>
                        <Button bg="tertiary" message="Submit" type="submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
