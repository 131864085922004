import React from "react";

function InputTextarea(props) {
  const { label, name, value, setValue, placeholder } = props;
  const rows = props.rows ?? 4;
  const margin = props.margin ?? "m-0";
  const labelTextStyle = props.labelTextStyle ?? "text-accent";
  const isDisabled = props.isDisabled ?? false;
  const exampleText = props.exampleText ?? "";

  return (
    <div className={margin}>
      <label
        htmlFor={name}
        className={`block text-sm font-medium text-left pl-2 ${labelTextStyle}`}
      >
        {label}
      </label>
      {exampleText !== "" && (
        <p className="text-xs italic text-gray-600 mb-2">{exampleText}</p>
      )}
      <div className="mt-1">
        <textarea
          disabled={isDisabled}
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          id={name}
          name={name}
          rows={rows}
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-accent-100 focus:ring-accent-100 sm:text-sm"
        />
      </div>
    </div>
  );
}

export default InputTextarea;
