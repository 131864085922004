import React from "react";
import { useState } from "react";
import InputSingle from "../../components/inputs/InputSingle";
import InputTextarea from "../../components/inputs/InputTextarea";
import {
  ActionHeading,
  FooterBanner,
} from "../../components/tables/TableCreateScope";
import { MobileTopNavbarContent } from "../../components/navs/MobileTopNavbar";

export default function WorkspaceSectionNewProject(props) {
  const { handleCreate, setAlert } = props;
  const [title, setTitle] = useState("");

  const isBannerVisible = title.trim() !== "";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isBannerVisible) {
      handleCreateProject();
    } else {
      setAlert("You must have a title, and select a client and approvers.");
    }
  };

  const handleCreateProject = () => {
    const projectObj = {
      title,
    };
    handleCreate(projectObj);
  };

  return (
    <>
      <MobileTopNavbarContent><p>Add New Project</p></MobileTopNavbarContent>
      <section className="min-h-screen max-w-7xl w-full mx-auto">
        <div class="w-full px-4 sm:px-8 py-6 sm:py-10 2xl:py-12">
          <ActionHeading title={"Add New Project"} />
          <div>
            <form onSubmit={(e) => handleSubmit(e)} className="space-y-5">
              <InputSingle
                isRequired
                value={title}
                setValue={setTitle}
                label={"Project Name"}
                placeholder={"Name your project"}
              />
            </form>
          </div>
        </div>
        <FooterBanner
          isDisabled={!isBannerVisible}
          isBackInvisible
          buttonMessage={"Create Project"}
          handleSetNext={() => handleCreateProject()}
        />
      </section>
    </>
  );
}
