import { db, firebase } from "../firebase";
import { URL } from "../utils/constants";
import { getEmailTemplate, SCOPE_INVITE } from "../utils/emailStrings";
import { getTwoDigitNumber } from "../utils/getCode";
import { sendBatchEmails, updateCollectionItem } from "./SharedService";

export function createScope(entityObj, obj, userInfo, emailInfo, isBlankScope) {
  const { workspaceId, projectId } = entityObj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  //Clients are configured in project, when a user sends scope, each of them get an email
  const { title, currency } = obj;
  const description = obj.description ?? "";
  const clientId = obj.clientId ?? "";
  const items = obj.items ?? [];
  const valueAdds = obj.valueAdds ?? [];
  const approvers = obj.approvers ?? [];
  const phases = obj.phases ?? [];
  return db
    .collection("scopes")
    .add({
      title,
      description,
      task: "",
      phases,
      items,
      currency,
      approvers,
      approvalObj: false,
      valueAdds,
      valueAddsRequested: [],
      attachments: [],
      clientId,
      projectId,
      workspaceId,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      owner: creator,
    })
    .then((docRef) => {
      const scopeId = docRef.id;
      const scopeObj = { title, id: scopeId };
      return db
        .collection("projects")
        .doc(projectId)
        .update({
          scopeObj,
        })
        .then(() => {
          const emails = approvers.map((approver) => ({
            email: approver,
            subject: `New Scope: ${title}`,
            html: getEmailTemplate("client", emailInfo, "SCOPE_CREATED").html,
          }));
          return sendBatchEmails(emails).then(() => {
            if (isBlankScope) {
              const approveObj = {
                prop: "approvalObj",
                value: {
                  approverUid: userInfo.uid,
                  approverId: userInfo.id,
                  approverName: userInfo.name,
                  status: "approved",
                },
              };
              return updateCollectionItem(
                "projects",
                projectId,
                approveObj
              ).then(() => {
                return scopeId;
              });
            } else {
              return scopeId;
            }
          });
        });
    });
}

export function createItem(entityObj, userInfo, obj) {
  const { workspaceId, projectId, phaseId } = entityObj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
    name: userInfo.name,
  };
  const title = obj.title ?? "New Item";
  const description = obj.description ?? "";
  const cost = obj.cost ?? 0;
  const quantity = obj.quantity ?? 1;
  const order = obj.order ?? 0;
  const ref = `${obj.phaseCode}-${getTwoDigitNumber(order + 1)}`;
  const attachmentLink = obj.attachmentLink ?? "";
  const max = obj.max ?? 0;
  // Keep batch items in sync from Template Service
  return db.collection("items").add({
    title,
    description,
    cost,
    max,
    quantity,
    attachments: [],
    labels: [],
    attachmentLink,
    variations: [],
    selectedVariationIndex: 0,
    order,
    ref,
    phaseId,
    projectId,
    workspaceId,
    isRecurring: false,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
    owner: creator,
    addedBy: creator,
  });
}

export function createItemFromRequest(userInfo, obj) {
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
    name: userInfo.name,
  };
  if (!obj.quantity) {
    obj.quantity = 1;
  }

  // Keep batch items in sync from Template Service
  return db.collection("items").add({
    ...obj,
    isDeleted: false,
    requested: firebase.firestore.FieldValue.serverTimestamp(),
    requestedTimestamp: Date.now(),
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    addedBy: creator,
  });
}

export function createItemFromLibrary(userInfo, obj) {
  const creator = {
    uid: userInfo.uid,
    id: userInfo.id,
    name: userInfo.name,
  };
  if (!obj.quantity) {
    obj.quantity = 1;
  }

  // Keep batch items in sync from Template Service
  return db.collection("items").add({
    ...obj,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    addedBy: creator,
  });
}

export function addScopeItemFromRequest(requestObj, items, userInfo) {
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  const itemObj = {
    title: requestObj.title,
    cost: requestObj.cost,
    max: requestObj.max ?? 0,
    description: requestObj.description,
    id: requestObj.id,
    phaseId: requestObj.phaseId,
    ref: requestObj.ref,
    attachments: [],
    variations: [],
    selectedVariationIndex: 0,
    createdTimestamp: Date.now(),
    requestTimestamp: requestObj.createdTimestamp,
    approverName: userInfo.name,
    creator,
    owner: creator,
    approver: creator,
    requestCreator: requestObj.creator,
    type: "request",
  };
  const updatedItems = items;
  updatedItems.push(itemObj);
  return db
    .collection("scopes")
    .doc(requestObj.scopeId)
    .update({
      items: updatedItems,
    })
    .then(() =>
      db.collection("requests").doc(requestObj.id).update({
        isAdded: true,
      })
    );
}

export function updatePhaseRow(id, obj) {
  obj.attachments =
    obj.attachments && !obj.attachments[0] ? [] : obj.attachments;
  obj.attachmentLink = obj.attachmentLink ?? "";
  return db
    .collection("items")
    .doc(id)
    .update({
      ...obj,
    });
}

export function createScopeInvite(userInfo, obj) {
  const { email, project, valueObj } = obj;
  const link = `${URL}/join/${project.id}`;
  const emailProps = {
    projectName: project.title,
    clientName: userInfo.name,
    link,
    email,
  };
  const inviteEmailObj = SCOPE_INVITE(emailProps);
  const { to, html, subject } = inviteEmailObj;
  const emailObj = {
    to,
    message: {
      html,
      subject,
    },
  };
  return db
    .collection("mail")
    .add(emailObj)
    .then(() => {
      return updateCollectionItem("projects", project.id, valueObj);
    });
}
