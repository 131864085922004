import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputSingle from "../inputs/InputSingle";
import Button from "../buttons/Button";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import InputSelection from "../inputs/InputSelection";
import Pill from "../pills/Pill";

export default function ModalInviteCreate(props) {
  const { isOpen, setIsOpen } = props;
  const [email, setEmail] = useState("");
  const typeOptions = [
    { value: "Admin", label: "Admin" },
    { value: "Member", label: "Member" },
  ];
  const [type, setType] = useState("Admin");

  const clearState = () => {
    setEmail("");
    setType("Admin");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = { email, type };
    props.handleCreate(obj).then(() => clearState(false));
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-7xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col divide-y divide-gray-200 bg-white"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-slate-100 px-4 py-6 sm:px-6">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          Invite a new member
                        </Dialog.Title>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-500">
                        Just fill in their email to get started.
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <InputSingle
                            required
                            type="email"
                            label="Email"
                            name="email"
                            value={email}
                            setValue={setEmail}
                          />
                          <div className="items-end">
                            <InputSelection
                              isDisabled={true}
                              label={"Member type"}
                              setValue={setType}
                              value={type}
                              options={typeOptions}
                            />
                            <div className="mt-1 flex">
                              <Pill
                                message={"Currently all members are Admins"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <div className="flex">
                      <div>
                        <Button message="Submit" type="submit" />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
