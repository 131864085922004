import { db, firebase } from "../firebase";

export function createClient(workspaceId, userInfo, obj) {
  const { title, mainContact } = obj;
  const description = obj.description ?? "";
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  return db
    .collection("clients")
    .add({
      title,
      description,
      workspaceId,
      mainContact,
      isDeleted: false,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
    })
    .then((doc) => {
      return doc.id;
    });
}

export function updateClientMember(obj, itemId) {
  const { members, scopeApprovers } = obj;
  return db.collection("clients").doc(itemId).update({
    members,
    scopeApprovers,
  });
}

export function addClientContacts(id, obj) {
  const { members, scopeApprovers } = obj;
  return db.collection("clients").doc(id).update({
    members,
    scopeApprovers,
  });
}
