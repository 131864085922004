import { PlusIcon } from "@heroicons/react/20/solid";
import Button from "../buttons/Button";
import { BookmarkSquareIcon } from "@heroicons/react/24/outline";

export default function EmptyState(props) {
  const { entity, message, buttonMessage, handleClick, icon, buttons } = props;
  const buttonColor = props.buttonColor ?? "tertiary";
  const text = props.isTextSmall ? "text-sm" : "text-base";
  const paddingTop = props.paddingTop ?? "pt-10";
  const isManyButtons = props.isManyButtons ?? false;

  return (
    <div className={`text-center w-full px-5 ${paddingTop}`}>
      <div className="flex justify-center">{icon && icon}</div>
      <h3 className="mt-2 font-medium text-gray-900">No {entity}</h3>
      <p className={`mt-1 text-gray-500 max-w-xl m-auto ${text}`}>{message}</p>
      {(buttonMessage || isManyButtons) && (
        <div className="mt-6 flex justify-center space-x-2">
          {isManyButtons ? (
            buttons.map((item) => (
              <div>
                <Button
                  bg={item.color}
                  message={item.message}
                  handleClick={item.handleClick}
                  icon={<BookmarkSquareIcon className="h-5 w-5 mr-2 -ml-1" />}
                />
              </div>
            ))
          ) : (
            <div>
              <Button
                bg={buttonColor}
                message={buttonMessage}
                handleClick={handleClick}
                icon={<PlusIcon className="h-5 w-5 mr-2 -ml-1" />}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
