import { UsersIcon } from "@heroicons/react/20/solid";
import React, { useContext } from "react";
import EmptyState from "../../components/empty-states/EmptyState";
import TableApprovers from "../../components/tables/TableApprovers";
import { ModalContext } from "../../store/modal-context";

function ProjectSectionClient(props) {
  const { handleToggle } = props;
  const approvers = props.approvers ?? [];
  const { setActiveModal } = useContext(ModalContext);

  return (
    <section className="max-w-5xl w-full mx-auto">
      <div className="p-2">
        {approvers.length <= 0 ? (
          <EmptyState
            handleClick={() => setActiveModal("approver")}
            icon={<UsersIcon className="h-12 w-12 text-gray-500" />}
            entity={"Approvers"}
            message={"You do not have any approvers set for this client yet."}
            buttonMessage={"Add Approver"}
          />
        ) : (
          <TableApprovers
            title={"Project scope members"}
            action={"Add member"}
            handleAdd={() => setActiveModal("approver")}
            handleToggle={handleToggle}
            people={approvers}
            projectApprovers={approvers}
          />
        )}
      </div>
    </section>
  );
}

export default ProjectSectionClient;

/* Client info form
<div className="text-left py-10">
          <SectionHeading heading={"Client Info"} />
        </div>
<div className="p-5 mx-4 sm:mx-6 lg:mx-8 bg-white border rounded-lg shadow">
            <form onSubmit={(e) => handleSubmitClientForm(e)} className="flex">
              <div className="flex-1">
                <InputSingle
                  value={clientTitle}
                  setValue={setClientTitle}
                  label={"Client Name"}
                  border="border-none"
                  text="text-lg"
                />
              </div>
              <div className="flex justify-end items-end ml-2">
                <Button
                  isInvisible={isButtonDisabled}
                  disabled={isButtonDisabled}
                  type="submit"
                  message={"Update"}
                />
              </div>
            </form>
          </div>

*/
