import React, { useState } from "react";
import Button from "../../components/buttons/Button";
import ButtonText from "../../components/buttons/ButtonText";
import SectionHeading from "../../components/headings/HeadingSection";
import { currencyOptions } from "../../components/overlays/OverlayOnboarding";
import InputCombo from "../../components/inputs/InputCombo";
import ModalConfirm from "../../components/modals/ModalConfirm";
import InputSingle from "../../components/inputs/InputSingle";
import InputTextarea from "../../components/inputs/InputTextarea";
import { useContext } from "react";
import { FunctionContext } from "../../store/function-context";
import InputSelection from "../../components/inputs/InputSelection";
import { ModalContext } from "../../store/modal-context";

export default function ProjectSectionSettings(props) {
  const { project, clients, clientId, setClientId } = props;
  const [currency, setCurrency] = useState(project.currency);
  const [query, setQuery] = useState("");
  const [title, setTitle] = useState(project.title);
  const [description, setDescription] = useState(project.description);
  const [isDeleteActive, setIsDeleteActive] = useState(false);
  const { handleUpdateItem, handleUpdateItemMultiple } =
    useContext(FunctionContext);
  const { setActiveModal } = useContext(ModalContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemObj = { collection: "projects", itemId: project.id };
    const obj = {
      currency: currency,
      title: title,
      description: description,
      clientId,
    };
    return handleUpdateItemMultiple(itemObj, obj);
  };

  const isSubmitDisabled =
    currency.id === project.currency.id &&
    title === project.title &&
    description === project.description &&
    clientId === project.clientId;

  const handleReloadPage = () => {
    window.location.reload();
  };

  const handleDelete = () => {
    const itemObj = { collection: "projects", itemId: project.id };
    const obj = { prop: "isDeleted", value: true };
    const feedbackObj = {
      message: "Project deleted",
      callback: () => handleReloadPage(),
    };
    return handleUpdateItem(itemObj, obj, feedbackObj);
  };

  return (
    <section className="max-w-5xl mx-auto w-full">
      <ModalConfirm
        isOpen={isDeleteActive}
        setIsOpen={setIsDeleteActive}
        buttonMessage={"Yes, delete"}
        title={"Are you sure you want to delete this project?"}
        description={
          "If you delete this project, all of its data will be lost."
        }
        handleAction={() => handleDelete()}
      />
      <div className="px-5">
        <SectionHeading
          heading={"Project Settings"}
          subheading={`Update information about this project`}
        />
        <div className="p-5 w-full">
          <form onSubmit={(e) => handleSubmit(e)} className="mb-5">
            <div className="mt-5 space-y-5">
              <InputSingle
                isRequired
                value={title}
                setValue={setTitle}
                label={"Project Name"}
                placeholder={"Name your project"}
              />
              <InputTextarea
                value={description}
                setValue={setDescription}
                label={"Project summary"}
                placeholder={
                  "Write a description of the project, you could include things like: What you will do, project goals, and any other relevant information"
                }
              />
              <InputCombo
                labelTextStyle="text-left font-bold text-lg text-gray-600"
                label={"What is the currency of this project?"}
                query={query}
                setQuery={setQuery}
                options={currencyOptions}
                legend="Start typing currency"
                isCustomPossible={false}
                value={currency}
                handleSelect={setCurrency}
              />
              <InputSelection
                label="Client"
                name="client"
                placeholder="Select a client"
                options={clients.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
                value={clientId}
                setValue={setClientId}
                width={"w-full"}
              />
            </div>
            <ButtonText
              message={"+ Add new client"}
              handleClick={() => setActiveModal("client")}
              width={""}
            />
            <div className="flex justify-end mt-5">
              <Button
                type="submit"
                message={"Update"}
                disabled={isSubmitDisabled}
                bg={"accent"}
              />
            </div>
          </form>
        </div>
        <div className="flex justify-end items-end pb-10 flex-1 pr-5 w-full">
          <Button
            handleClick={() => setIsDeleteActive(true)}
            bg={"red"}
            message={"Delete Project"}
          />
        </div>
      </div>
    </section>
  );
}
