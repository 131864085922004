import { db } from "../firebase";

export function updateUser(userId, obj) {
  const { prop, value } = obj;
  return db
    .collection("users")
    .doc(userId)
    .update({
      [prop]: value,
    });
}

export function updateUserForm(userId, arr) {
  return db
    .collection("users")
    .doc(userId)
    .update({
      ...arr,
    });
}
