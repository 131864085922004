import React from "react";
import { getColors } from "../../utils/getColors";

function Button(props) {
  const { message, handleClick, disabled, icon, label } = props;
  const type = props.type ?? "button";
  const bg = props.bg ?? "tertiary";
  const { color, colorHover, focusColor } = getColors(bg);
  const opacity = disabled ? "opacity-20" : "";
  const isResponsive = props.isResponsive ?? false;
  const width = props.width ?? "";
  const isInvisible = props.isInvisible ?? false;
  const fontSize = props.fontSize ?? "text-sm";
  const padding = props.padding ?? "py-2 px-4";
  const shadow = props.shadow ?? "shadow-sm";

  return (
    <button
      aria-label={label ?? message}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={`cursor-pointer flex items-center justify-center rounded-md border border-transparent font-medium transition-all focus:outline-none focus:ring-2 focus:ring-oƒffset-2 ${shadow} ${padding} ${focusColor} ${color} ${colorHover} ${opacity} ${width} ${fontSize} ${
        bg === "white" ? "text-gray-700 border-gray-200" : "text-white"
      } ${isInvisible && "invisible"}`}
    >
      {icon && icon}
      <span className={`${isResponsive && "hidden md:block"}`}>{message}</span>
    </button>
  );
}

export default Button;
