import { db, firebase } from "../firebase";

export function updateCollectionItem(collection, itemId, obj) {
  const { prop, value } = obj;
  return db
    .collection(collection)
    .doc(itemId)
    .update({
      [prop]: value,
    });
}

export function updateCollectionItemMultiple(collection, itemId, obj) {
  return db
    .collection(collection)
    .doc(itemId)
    .update({ ...obj });
}

export function sendBatchEmails(emails) {
  const batch = db.batch();
  emails.forEach((email) => {
    const docRef = db.collection("mail").doc();
    const doc = {
      to: email.email,
      message: {
        subject: email.subject,
        html: email.html,
      },
    };
    batch.set(docRef, doc);
  });
  return batch.commit();
}

export function sendEmail(to, subject, html) {
  return db.collection("mail").add({
    to,
    message: {
      subject,
      html,
    },
  });
}

export function sendEmailFromObj(obj) {
  const { to, subject, html } = obj;
  return db.collection("mail").add({
    to,
    message: {
      subject,
      html,
    },
  });
}

export function makeItemOptional(obj, userInfo) {
  const { id } = obj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
    name: userInfo.name,
  };
  return db
    .collection("items")
    .doc(id)
    .update({
      isDeleted: true,
    })
    .then(() => {
      obj.vaId = obj.id;
      delete obj.id;
      return db.collection("scopeVAs").add({
        ...obj,
        isCancelled: false,
        requested: firebase.firestore.FieldValue.serverTimestamp(),
        requestedTimestamp: Date.now(),
        addedBy: creator,
      });
    });
}
