
export default function convertTimestampToFormattedDate (timestamp) {
    const date = new Date(timestamp)

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate;
}