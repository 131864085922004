import React, { useContext, useState } from "react";
import ButtonText from "../../components/buttons/ButtonText";
import Button from "../../components/buttons/Button";
import SectionHeading from "../../components/headings/HeadingSection";
import InputSingle from "../../components/inputs/InputSingle";
import TablePeople from "../../components/tables/TablePeople";
import { updateWorkspaceInviteStatus } from "../../services/WorkspaceService";
import { ModalContext } from "../../store/modal-context";

function WorkspaceSectionTeam(props) {
  const {
    userInfo,
    workspaceId,
    workspace,
    isWorkspaceOwner,
    setUserToDelete,
    handleUpdateItem,
  } = props;
  const { setActiveModal } = useContext(ModalContext);

  let membersRemoved = props.membersRemoved ?? [];
  let members = props.members ?? [];
  members = members.map((member) => {
    return { ...member, projectRole: member.type };
  });
  let invites = props.invites ?? [];
  const [name, setName] = useState(workspace.title);

  const handleCancelInvite = (invite) => {
    const updatedInvites = invites.filter(
      (item) => item.inviteId !== invite.inviteId
    );
    const { inviteId, invited } = invite;
    const obj = { inviteId, invitedEmail: invited, status: "cancelled" };
    updateWorkspaceInviteStatus(userInfo, obj, workspaceId, updatedInvites);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = { value: name, prop: "title" };
    const itemObj = { collection: "workspaces", itemId: workspaceId };
    handleUpdateItem(itemObj, obj);
  };

  const isSubmitDisabled = name === workspace.title || name?.trim() === "";

  return (
    <section className="max-w-3xl mx-auto box-border px-2">
      <div className="mt-5">
        <SectionHeading
          subheading={
            "Keep all the information regarding you workspace up to date"
          }
          heading={workspace.title}
        />
      </div>
      <div className="flex justify-center w-full">
        <form
          onSubmit={(e) => handleSubmit(e)}
          className="mb-5 max-w-3xl w-full"
        >
          <InputSingle
            placeholder={"Workspace name"}
            label={"Workspace name"}
            name={"workspace-name"}
            value={name}
            setValue={setName}
            margin={"my-5"}
          />
          <div className="flex justify-end">
            <div>
              <Button
                type="submit"
                message={"Update"}
                disabled={isSubmitDisabled}
                bg={"tertiary"}
              />
            </div>
          </div>
        </form>
      </div>
      <div className="max-w-3xl px-2 mx-auto">
        <TablePeople
          title={"Our Team"}
          action={"Add Member"}
          handleAdd={() => setActiveModal("invite")}
          people={members}
          isWorkspaceOwner={isWorkspaceOwner}
          setUserToDelete={setUserToDelete}
        />
        {invites.filter((item) => !membersRemoved.includes(item.invited))
          .length > 0 && (
          <div className="px-4">
            <SectionHeading heading={"Invites"} />
            <ul className="text-left px-10">
              {invites
                .filter((item) => !membersRemoved.includes(item.invited))
                .map((invite) => {
                  const { inviteId, invited, status } = invite;
                  return (
                    <li className="flex" key={inviteId}>
                      <span className="mr-2">{invited}</span>
                      <span className="italic text-gray-600">{status}</span>
                      <div className="flex-grow flex justify-end">
                        <div>
                          <ButtonText
                            handleClick={() => handleCancelInvite(invite)}
                            color="text-red-700 hover:text-red-900"
                            message="Cancel invitation"
                          />
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </div>
    </section>
  );
}

export default WorkspaceSectionTeam;
