import React, { useContext, useEffect, useState } from "react";

// Context
interface IPortal {
    id: string;
    children: React.ReactNode;
}

function createServiceProvider<T>(): [React.Provider<T>, () => T] {
    const context = React.createContext<T>({} as any);
    const provider = context.Provider;
    const useHook = () => useContext(context);
    return [provider, useHook];
}

const [ServiceProvider, useHook] = createServiceProvider<{
    setValue: (value: IPortal[]) => void;
    value: IPortal[];
}>();

// Provider
export const PortalStateProvider = (props: { children: React.ReactNode }) => {
    const [state, setState] = useState<IPortal[]>([]);

    return (
        <ServiceProvider
            value={{
                value: state,
                setValue: setState,
            }}
        >
            {props.children}
        </ServiceProvider>
    );
};

// Portals
interface BlackIProps {
    id: string;
    children: React.ReactNode;
}

interface WhiteIProps {
    id: string;
}

export const BlackPortal = (props: BlackIProps) => {
    const state = useHook();

    useEffect(() => {
        const filteredState = state.value.filter(p => p.id !== props.id)
        state.setValue([...filteredState, props]);

        return () => {
            state.setValue(state.value.filter((v) => v.id !== props.id));
        };
    }, [props]);

    return null;
};

export const WhitePortal = (props: WhiteIProps) => {

    const state = useHook();
    const portal = state.value.find((p) => p.id === props.id);
    console.log(portal)
    return <>{portal?.children}</>;
};