import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Alert from "../../components/alerts/Alert";
import ModalRequestAdd from "../../components/modals/ModalAddRequest";
import PageShell from "../../components/page-shells/PageShellSidebar";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import Toast from "../../components/toasts/Toast";
import { createRequest } from "../../services/RequestService";
import {
  createItem,
  createItemFromLibrary,
  createItemFromRequest,
  createScopeInvite,
} from "../../services/ScopeService";
import {
  sendEmailFromObj,
  updateCollectionItem,
  updateCollectionItemMultiple,
} from "../../services/SharedService";
import ScopeSectionOverview from "./ScopeSectionOverviewRebuild";
import { createComment } from "../../services/CommentService";
import ModalConfirmAdd from "../../components/modals/ModalConfirmAdd";
import ModalValueAdd from "../../components/modals/ModalAddValueAdd";
import { createValueAdd } from "../../services/ValueAddService";
import { EmailContext } from "../../store/email-context";
import { getEmailTemplate } from "../../utils/emailStrings";
import ModalPhaseAdd from "../../components/modals/ModalAddPhase";
import { createPhase } from "../../services/PhaseService";
import { FeedbackContext } from "../../store/feedback-context";
import { ModalContext } from "../../store/modal-context";
import {
  createFromTemplate,
  createTemplate,
} from "../../services/TemplateService";
import { ScopeStartOption } from "./ScopeStartOption";
import { getTwoDigitNumber } from "../../utils/getCode";
import ModalConfirm from "../../components/modals/ModalConfirm";
import { FunctionContext } from "../../store/function-context";
import convertTimestampToFormattedDate from "../../utils/getDateTime";
import { UserContext } from "../../store/user-context";

export default function ScopeDashboard(props) {
  const {
    scopeId,
    projectId,
    project,
    workspaceId,
    comments,
    requests,
    items,
    phases,
    valueAdds,
    scopeVAs,
    templates,
    labels,
    setActiveRequest,
    workspaceProjects,
    userInfo,
    client,
    isApprover,
    isContractor,
    isLoading,
  } = props;
  const [activeView, setActiveView] = useState("Overview");
  const [isRequestOpen, setIsRequestOpen] = useState(false);
  const [isRequestHidden, setIsRequestHidden] = useState(false);
  const emailCtx = useContext(EmailContext);
  const { fetchWorkspaceProjects } = useContext(UserContext);
  const { toast, setToast, alert, setAlert } = useContext(FeedbackContext);
  const { handleSetEmailInfo, emailInfo } = emailCtx;
  const {
    activeModal,
    setActiveModal,
    confirmObj,
    setConfirmObj,
    activeAddPhase,
  } = useContext(ModalContext);
  const { handleAddValueAdd } = useContext(FunctionContext);

  const handleFeedback = (message, setClose) => {
    setToast(message);
    if (setClose) {
      setClose();
    }
  };

  const getActiveView = () => {
    switch (activeView) {
      case "Overview":
        return phases.length > 0 || project.isFromScratch ? (
          <ScopeSectionOverview
            project={project}
            handleUpdateItem={handleUpdateItem}
            handleAddValueAdd={handleAddValueAddFromId}
            handleUpdateItemMultiple={handleUpdateItemMultiple}
            handleAddItemFromRequest={handleAddItemFromRequest}
            handleAddItemFromLibrary={handleAddItemFromLibrary}
            handleSelectRequestToAddress={handleSelectRequestToAddress}
            handleInviteApprover={handleInviteApprover}
            setIsRequestOpen={setIsRequestOpen}
            userInfo={userInfo}
            comments={comments}
            handleCreateComment={handleCreateComment}
            handleCreatePhase={handleCreatePhase}
            handleCreateItem={handleCreateItem}
            handleCreateValueAdd={handleCreateValueAdd}
            handleCreateTemplate={handleCreateTemplate}
            handleCreateFromTemplate={handleCreateFromTemplate}
            requests={requests}
            phases={phases}
            labels={labels}
            client={client}
            isApprover={isApprover}
            isContractor={isContractor}
            items={items.filter((item) => !item.isDeleted)}
            valueAdds={valueAdds}
            scopeVAs={scopeVAs}
            valueAddsRequested={items.filter((item) => item.type === "scopeVA")}
            setConfirmObj={setConfirmObj}
            handleToggleValueAdd={handleToggleValueAdd}
            workspaceId={workspaceId}
            handleStatusCommentNotApproved={handleStatusCommentNotApproved}
          />
        ) : (
          <ScopeStartOption
            templates={templates}
            projectId={projectId}
            handleCreateScopeFromTemplate={handleCreateFromTemplate}
          />
        );
      default:
        return null;
    }
  };

  const handleUpdateItem = (itemObj, obj, setClose) => {
    const { collection, itemId } = itemObj;
    try {
      return updateCollectionItem(collection, itemId, obj).then(() => {
        return handleFeedback("Item Updated", setClose);
      });
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleStatusApproved = (itemObj) => {

    const setApprovedStatusObj = {
      obj: {prop: "scopeStatus", value: {
        state: 'approved-no-comment',
        timestamp: Date.now()
      }},
      feedbackObj:{
        callback: () => fetchWorkspaceProjects(workspaceId),
      }
    }
    const {obj, feedbackObj} = setApprovedStatusObj;
    handleUpdateItem(itemObj,obj,feedbackObj.callback);
  }

  const handleStatusCommentNotApproved = () =>{
    // [WIP] Needs to be updated once confirmed how to get client ID
    if(comments.length === 0 && confirmObj !== true){      
      const setCommentNotApprovedObj = {
        itemObj: {collection: "projects", itemId: projectId},
        obj:{prop: "scopeStatus", value:{
          state: 'comment-not-approved',
          timestamp: Date.now(),
        }},
        feedbackObj:{
          callback: () => fetchWorkspaceProjects(workspaceId),
        }
      }
      const { itemObj, obj, feedbackObj} = setCommentNotApprovedObj;
      handleUpdateItem(itemObj, obj, feedbackObj.callback)
    }
    return;
  }

  const handleUpdateItemWithEmail = (itemObj, obj, setClose, emailTemp) => {
    const { collection, itemId } = itemObj;
    try {
      return updateCollectionItem(collection, itemId, obj).then(() => {
        return handleFeedbackWithEmail("Item Updated", setClose, emailTemp);
      });
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateRequest = (phaseId, obj) => {
    const entityObj = {
      phaseId,
      scopeId,
      projectId,
      workspaceId: project.workspaceId,
    };
    const emailTemplateId = isContractor
      ? "ADDED_REQUEST_CONTRACTOR"
      : "ADDED_REQUEST_CLIENT";

    //If current user is contractor, send email to client
    const type = !isContractor ? "contractor" : "client";
    const emailInfoWithRequestTitle = {
      ...emailInfo,
      requestTitle: obj.title,
      requestRef: obj.ref,
    };
    const emailTemp = getEmailTemplate(
      type,
      emailInfoWithRequestTitle,
      emailTemplateId
    );
    try {
      return createRequest(entityObj, userInfo, obj).then(() =>
        handleFeedbackWithEmail(
          "Request Created",
          setIsRequestOpen(false),
          emailTemp
        )
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleFeedbackWithEmail = (message, onClose, emailTemp) => {
    return sendEmailFromObj(emailTemp).then(() => {
      handleFeedback(message, onClose);
    });
  };

  const handleInviteApprover = (email, valueObj) => {
    const obj = { email, project, valueObj };
    try {
      return createScopeInvite(userInfo, obj).then(() =>
        handleFeedback("Approver Invited")
      );
    } catch {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateComment = (obj, onClose) => {
    const { entityId, text } = obj;
    const entityObj = {
      scopeId,
      projectId,
      entityId,
      workspaceId: project.workspaceId,
    };
    const emailTemplateId = isContractor
      ? "ADDED_COMMENT_CONTRACTOR"
      : "ADDED_COMMENT_CLIENT";

    //If current user is contractor, send email to client
    const type = !isContractor ? "contractor" : "client";
    const emailTemp = getEmailTemplate(type, emailInfo, emailTemplateId);
    try {
      return createComment(entityObj, userInfo, text).then(() =>
        handleFeedbackWithEmail("Comment Created", onClose, emailTemp)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleAddValueAddFromId = (valueAddId) => {
    const valueObj = valueAdds.find((item) => item.id === valueAddId);
    const valueAddObj = {
      ...valueObj,
    };
    delete valueAddObj.id;
    valueAddObj.vaId = valueAddId;
    valueAddObj.projectId = projectId;
    return handleAddValueAdd(valueAddObj);
  };

  const handleAddItemFromRequest = (obj, vaProjectId) => {
    const phaseId = obj.phaseId ?? phases[phases.length - 1].id;
    obj.phaseId = phaseId;
    if (vaProjectId) {
      obj.projectId = vaProjectId;
    }
    try {
      return createItemFromRequest(userInfo, obj).then(() =>
        handleFeedback("Item Added")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
      console.log(error);
    }
  };

  const handleAddItemFromLibrary = (valueAddId) => {
    const obj = valueAdds.find((item) => item.id === valueAddId);
    const phase = activeAddPhase
      ? phases.find((item) => item.id === activeAddPhase)
      : phases[phases.length - 1];
    obj.phaseId = phase.id;
    const ref = `${phase.code}-${getTwoDigitNumber(items.length + 1)}`;
    obj.vaId = valueAddId;
    obj.projectId = projectId;
    obj.ref = ref;
    obj.type = "original";
    obj.order = items.filter((item) => item.phaseId === phase.id).length;
    try {
      return createItemFromLibrary(userInfo, obj).then(() =>
        handleFeedback("Item Added")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
      console.log(error);
    }
  };

  const handleUpdateItemMultiple = (itemObj, arr) => {
    const { collection, itemId, isRequest } = itemObj;
    let emailTemp;
    if (isRequest) {
      emailInfo.requestTitle = arr.title;
      emailTemp = getEmailTemplate(
        "client",
        emailInfo,
        "QUOTE_ADDED_CONTRACTOR"
      );
    }
    try {
      return updateCollectionItemMultiple(collection, itemId, arr).then(() =>
        isRequest && isContractor
          ? handleFeedbackWithEmail(
              "Item Updated",
              () => setIsRequestHidden(true),
              emailTemp
            )
          : handleFeedback("Item Updated", () => setIsRequestHidden(true))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      handleSetEmailInfo(project, userInfo.name);
    }
    //eslint-disable-next-line
  }, [isLoading]);

  const handleSelectRequestToAddress = (id) => {
    const activeReq = requests.find((item) => item.id === id);
    setActiveRequest(activeReq);
    setIsRequestHidden(false);
  };

  const handleConfirmAdd = () => {
    if (phases.length < 1) {
      setAlert("Please create a phase first");
      return;
    }
    const type = confirmObj.type;
    if (type !== "request" && type !== "valueAdd" && type !== "scopeVA") {
      return;
    }
    const emailTemplateId = isContractor
      ? "APPROVE_ITEM_CONTRACTOR"
      : "APPROVE_ITEM_CLIENT";

    //If current user is client, send email to contractor
    const requesterType = !isContractor ? "contractor" : "client";
    const emailInfoWithRequestTitle = {
      ...emailInfo,
      itemTitle: confirmObj.title,
      itemRef: confirmObj.ref,
    };
    const emailTemp = getEmailTemplate(
      requesterType,
      emailInfoWithRequestTitle,
      emailTemplateId
    );
    confirmObj.phaseId = phases[phases.length - 1].id;
    confirmObj.originalId = confirmObj.id;
    confirmObj.order = items.filter(
      (item) => item.phaseId === phases[phases.length - 1]
    ).length;
    if (!project.approvalObj) {
      confirmObj.type = "item";
    }
    return createItemFromRequest(userInfo, confirmObj).then(() =>
      handleFeedbackWithEmail(
        "Item added to scope",
        setConfirmObj(false),
        emailTemp
      )
    );
  };

  const handleConfirmDelete = () => {
    const obj = { prop: "isDeleted", value: true };
    const itemObj = { collection: "scopeVAs", itemId: confirmObj.id };
    return handleUpdateItem(itemObj, obj, setConfirmObj(false));
  };

  const handleCreateValueAdd = (obj) => {
    const entityObj = { workspaceId, projectId };
    if (!obj.ref) {
      const ref = `VA-${getTwoDigitNumber(valueAdds.length + 1)}`;
      obj.ref = ref;
    }
    try {
      return createValueAdd(entityObj, obj, userInfo).then(() =>
        handleFeedback("Optional Service created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleToggleValueAdd = (id) => {
    let selectedValueAdds = project.valueAdds;
    const valueAddExists = selectedValueAdds.some((item) => item.id === id);
    const valueAdd = valueAdds.find((item) => item.id === id);
    if (!valueAddExists) {
      selectedValueAdds = [...selectedValueAdds, valueAdd];
    } else {
      selectedValueAdds = selectedValueAdds.filter((item) => item.id !== id);
    }
    const itemObj = { collection: "projects", itemId: projectId };
    const obj = { prop: "valueAdds", value: selectedValueAdds };
    return handleUpdateItem(itemObj, obj);
  };

  const handleCreatePhase = () => {
    const order = phases.length;
    const obj = { title: `Phase ${order + 1}`, description: "", order };
    const entityObj = { workspaceId: workspaceId, projectId };
    try {
      return createPhase(entityObj, userInfo, obj).then(() =>
        handleFeedback("Phase created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateItem = (phaseId) => {
    const phase = phases.find((item) => item.id === phaseId);
    const order = items.filter((item) => item.phaseId === phaseId).length;
    const entityObj = { workspaceId: workspaceId, projectId, phaseId };
    const { code } = phase;
    const obj = { title: "New Item", description: "", order, phaseCode: code };
    try {
      return createItem(entityObj, userInfo, obj).then(() =>
        handleFeedback("Item created")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateTemplate = (obj) => {
    const entityObj = { workspaceId, scopeId };
    const templateItems = items.map((item) => ({
      title: item.title,
      description: item.description,
      type: item.type,
      cost: item.cost,
      max: item.max ?? 0,
      quantity: item.quantity,
      phaseId: item.phaseId,
      ref: item.ref,
      order: item.order ?? 0,
    }));
    const templatePhases = phases.map((item) => ({
      title: item.title,
      description: item.description,
      code: item.code,
      type: item.type,
      order: item.order,
      originalPhaseId: item.id,
    }));
    const scopeObj = {
      items: templateItems,
      phases: templatePhases,
      currency: project.currency,
    };
    try {
      return createTemplate(entityObj, scopeObj, obj, userInfo).then(() =>
        handleFeedback("Template Created", () => setActiveModal(false))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCreateFromTemplate = (templateId) => {
    const entityObj = { workspaceId, projectId, scopeId };
    try {
      return createFromTemplate(templateId, entityObj, userInfo).then(() =>
        handleFeedback("Template Created", () => setActiveModal(false))
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  return isLoading ? (
    <PageShellLoading />
  ) : (
    <>
      {toast && <Toast message={toast} />}
      {alert && <Alert isTop={false} message={alert} />}
      <ModalConfirm
        isOpen={confirmObj !== false && confirmObj.type === "approval"}
        setIsOpen={setConfirmObj}
        buttonMessage={"Yes, approve"}
        title={`Are you sure you want to approve this scope?`}
        description={
          "If you approve this scope, the original scope will not be editable and any addition will need to be made through Optional Services"
        }
        handleAction={() => {
          handleStatusApproved(confirmObj.itemObj)
          handleUpdateItemWithEmail(
            confirmObj.itemObj,
            confirmObj.obj,
            () => setConfirmObj(false),
            getEmailTemplate("contractor", emailInfo, "SCOPE_APPROVED")
          )
          }
        }
        buttonBg={"tertiary"}
      />
      <ModalConfirmAdd
        isOpen={confirmObj !== false && confirmObj?.type !== "approval"}
        setIsOpen={setConfirmObj}
        handleConfirm={handleConfirmAdd}
        handleConfirmDelete={handleConfirmDelete}
        isToDelete={confirmObj?.action === "delete"}
      />
      <ModalValueAdd
        isOpen={activeModal === "value-add"}
        setIsOpen={setActiveModal}
        handleCreate={handleCreateValueAdd}
        valueAdds={valueAdds}
      />
      {isRequestOpen && (
        <ModalRequestAdd
          isOpen={isRequestOpen}
          setIsOpen={setIsRequestOpen}
          handleCreate={handleCreateRequest}
          phases={phases}
          requests={requests}
        />
      )}
      <ModalPhaseAdd
        isOpen={activeModal === "add-phase"}
        handleClose={() => setActiveModal(false)}
      />
      <PageShell
        userInfo={userInfo}
        mainView={getActiveView()}
        activeView={project.scopeId}
        setActiveView={setActiveView}
        //navigationItems={getNaviagtionItems("scope")}
        navigationItems={[]}
        type={"scope"}
        currentPage={"scope"}
        maxViewWidth={""}
        viewPadding={""}
        isHeadingHidden
        projects={workspaceProjects}
        selectedProjectId={projectId}
      />
    </>
  );
}
