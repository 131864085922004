import React from "react";

function Steps(props) {
  const { steps, currentStep } = props;
  const margin = props.margin ?? "my-5";
  return (
    <ul className={`flex space-x-4 ${margin}`}>
      {steps.map((step, i) => {
        const isCurrent = currentStep !== step.value;
        const handleSetStep = props.handleSetStep
          ? () => props.handleSetStep(step.value)
          : () => {};
        return (
          <li
            className={`${
              isCurrent ? "opacity-50" : "opacity-100"
            } transition-all `}
            key={step.value}
          >
            <button className="flex" onClick={handleSetStep}>
              <div className="h-4 w-4 mr-1 text-xs rounded-full bg-primary text-white">
                {i + 1}
              </div>
              <span className="text-xs">{step.label}</span>
            </button>
          </li>
        );
      })}
    </ul>
  );
}

export default Steps;
