import { useEffect, useState } from "react";
import {
  auth,
  db,
  registerWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "../../firebase";
import Button from "../../components/buttons/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Alert from "../../components/alerts/Alert";
import logo from "../../images/logo.png";
import { useContext } from "react";
import { FeedbackContext } from "../../store/feedback-context";
import { useParams } from "react-router-dom";
import { updateCollectionItem } from "../../services/SharedService";
import {
  ContinueWith,
  LoginForm,
  WelcomeMessage,
} from "../login-page/LoginPageSplit";
import { UserContext } from "../../store/user-context";

export default function JoinPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [user, authLoading] = useAuthState(auth);
  const [isFetching, setIsFetching] = useState(true);
  const [project, setProject] = useState(false);
  const { alert, setAlert } = useContext(FeedbackContext);
  const { setIsUserUpdated } = useContext(UserContext);
  let { projectId } = useParams();
  const navigate = useNavigate();

  const fetchProject = async () => {
    if (!isFetching && project) {
      return;
    }
    try {
      console.log(projectId);
      db.collection("projects")
        .doc(projectId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setProject(data);
          setIsFetching(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const checkUserStatus = async () => {
    if (!user && !authLoading) {
      setIsFetching(false);
      return;
    }
    if (!user || isFetching) {
      return;
    }
    if (user && !project) {
      await fetchProject();
      return;
    }
    const isAlreadyApprover = project.scopeApprovers.some(
      (item) => item.uid === user.uid
    );
    if (isAlreadyApprover) {
      navigate(`/scope/${projectId}/${project.workspaceId}`);
    } else {
      const scopeApprovers = project.scopeApprovers ?? [];
      const approverIds = project.approverIds ?? [];
      const userObj = {
        uid: user.uid,
        name: user.displayName,
        email: user.email,
      };
      scopeApprovers.push(userObj);
      const obj = { prop: "scopeApprovers", value: scopeApprovers };
      const approverObj = { prop: "approverIds", value: approverIds };
      approverIds.push(user.uid);
      try {
        return updateCollectionItem("projects", projectId, obj)
          .then(() => {
            return updateCollectionItem(
              "scopes",
              project.scopeId,
              approverObj //For fetching scopes
            );
          })
          .then(() => {
            setIsUserUpdated(true);
          })
          .then(() => {
            navigate(`/scope/${projectId}/${project.workspaceId}`);
          });
      } catch (error) {
        setAlert("There has been an error with this request");
      }
    }
  };

  useEffect(() => {
    if (!user) {
      return;
    }
    fetchProject();
    //eslint-disable-next-line
  }, [isFetching, user, authLoading]);

  useEffect(() => {
    checkUserStatus();
    //eslint-disable-next-line
  }, [isFetching, user, authLoading, project]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isLoggingIn) {
      signInWithEmailAndPassword(email, password).then((res) => {
        if (!res.isValid) {
          setAlert({ type: "error", message: res.message });
        }
      });
    } else {
      try {
        registerWithEmailAndPassword(name, email, password);
      } catch (error) {
        setAlert("There has been an error creating this account");
      }
    }
  };

  const handleToggleView = () => {
    setIsLoggingIn(!isLoggingIn);
  };

  return isFetching ? (
    <p>Fetching...</p>
  ) : (
    <>
      <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-slate-50">
        {alert && (
          <Alert isTop={true} message={alert.message} type={alert.type} />
        )}
        <div className="sm:mx-auto sm:w-full sm:max-w-md px-2">
          <img className="mx-auto h-16 w-auto" src={logo} alt="Scopey" />
          <WelcomeMessage
            isLoggingIn={isLoggingIn}
            handleToggleView={handleToggleView}
            welcome={
              <h1 className="text-gray-700 text-md mb-5">
                You have been invited to access {!project && "a project"}:{" "}
                <br />
                {project && (
                  <span className="text-3xl font-bold">{project.title}</span>
                )}
              </h1>
            }
          />
        </div>
        <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md px-2">
          <div className="bg-white py-8 shadow sm:rounded-lg px-2 sm:px-10">
            <div className="flex-1">
              <form className="space-y-5" onSubmit={handleSubmit}>
                <LoginForm
                  email={email}
                  setEmail={setEmail}
                  password={password}
                  setPassword={setPassword}
                  name={name}
                  setName={setName}
                  isLoggingIn={isLoggingIn}
                />
                <div className="w-full mt-10">
                  <Button
                    width={"w-full"}
                    type="Submit"
                    message={isLoggingIn ? "Login" : "Create account"}
                  />
                </div>
              </form>
              <ContinueWith />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
