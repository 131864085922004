import { PaperClipIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";
import { useState } from "react";
import Button from "../buttons/Button";
import InputSingle from "./InputSingle";
import { Dialog, Transition } from "@headlessui/react";

function InputLink(props) {
  const { link, handleSetLink } = props;
  const [isActive, setIsActive] = useState(false);

  const handleSubmit = (e, url, name) => {
    e.preventDefault();
    const link = { url, name };
    handleSetLink(link);
    setIsActive(false);
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsActive(true)}
        className={`h-8 w-8 rounded-lg border-dashed border flex items-center justify-center ${
          link ? "border-tertiary" : "border-gray-300"
        }`}
      >
        <span className="sr-only">Add link</span>
        <PaperClipIcon
          className={`h-4 w-4 ${link ? "text-tertiary" : "text-gray-300"}`}
          aria-hidden="true"
        />
      </button>
      <LinkWindow
        link={link}
        handleSubmit={handleSubmit}
        isOpen={isActive}
        setIsOpen={setIsActive}
      />
    </div>
  );
}

export default InputLink;

const LinkWindow = ({ isOpen, setIsOpen, link, handleSubmit }) => {
  const [currentLink, setCurrentLink] = useState(link ?? "");
  const isButtonDisabled =
    !currentLink || currentLink.trim() === "" || currentLink === link;
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-xl sm:my-8 sm:max-w-sm md:p-4">
                <Dialog.Title className="text-lg font-medium text-gray-900 sr-only">
                  Add link
                </Dialog.Title>
                <div>
                  <p className="text-gray-500 text-small py-2 px-5">
                    Add reference material by providing a link to any shared
                    drive URL (Google Drive, Sharepoint, Dropbox etc.)
                  </p>
                  <form
                    onSubmit={(e) => handleSubmit(e, currentLink, "")}
                    className="flex items-center justify-center px-2 md:px-10"
                  >
                    <div className="flex-1">
                      <InputSingle
                        label="Link"
                        name="link"
                        value={currentLink}
                        setValue={setCurrentLink}
                        isLabelHidden
                      />
                    </div>

                    <Button
                      disabled={isButtonDisabled}
                      message="Add link"
                      fontSize={"text-xs ml-2"}
                      type="submit"
                    />
                  </form>
                  {currentLink && (
                    <div className="text-center mt-2">
                      <a
                        className="text-xs text-blue-400 underline text-center"
                        href={currentLink}
                      >
                        {currentLink}
                      </a>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
