import { useEffect, useState } from "react";
import { auth, sendResetPasswordEmail } from "../../firebase";
import InputSingle from "../../components/inputs/InputSingle";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router";
import Alert from "../../components/alerts/Alert";
import { useContext } from "react";
import { UserContext } from "../../store/user-context";
import { FeedbackContext } from "../../store/feedback-context";
import logo from "../../images/logo.png";
import Button from "../../components/buttons/Button";
import { Link } from "react-router-dom";

export default function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [user, authLoading] = useAuthState(auth);
  const { alert, setAlert } = useContext(FeedbackContext);
  const { userInfo, setHasLoggedIn, hasLoggedIn } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (hasLoggedIn) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (authLoading) return;
    if (!user) return;
    if (userInfo.workspaces.length > 0) {
      setHasLoggedIn(true);
      navigate(`/workspace/${userInfo.workspaces[0].id}/Projects`);
    } else {
      setHasLoggedIn(true);
      navigate("/user");
    }
    //es-lint-deisable-next-line
  }, [user, authLoading, navigate]);

  //useSendPasswordResetEmail function form react-firebase-hooks/auth
  const handleSubmit = (e) => {
    e.preventDefault();
    sendResetPasswordEmail(email).then((res) => {
      const { isError, message } = res;
      setIsSent(true);
      setAlert({
        type: !isError ? "success" : "error",
        message,
      });
    });
  };

  return (
    <div className="flex min-h-screen flex-col py-12 sm:px-6 lg:px-8 bg-slate-50">
      {alert && (
        <Alert isTop={true} message={alert.message} type={alert.type} />
      )}
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Workflow" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot your password?
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 max-w">
          Enter the email address you used when you signed up for your account,
          and we’ll email you a link to reset your password.
        </p>
        {isSent ? (
          <div className="mt-10">
            <h1>A reset password link has been sent to {email}</h1>
            <div className="mt-10 flex justify-center">
              <Link to="/login">
                <Button message="Back to login" />
              </Link>
            </div>
          </div>
        ) : (
          <form
            onSubmit={(e) => handleSubmit(e)}
            className="mt-20 flex items-end space-x-1"
          >
            <div className="flex-1">
              <InputSingle
                label="Email address"
                value={email}
                setValue={setEmail}
                type="email"
              />
            </div>
            <div>
              <Button message="Submit" type="submit" />
            </div>
          </form>
        )}
        <div className="flex justify-end mt-5">
          {isSent ? (
            <button onClick={() => setIsSent(false)}>
              <span className="italic text-sm text-gray-500 hover:underline">
                Try with another email
              </span>
            </button>
          ) : (
            <Link to="/login">
              <span className="italic text-sm text-gray-500 hover:underline">
                Back to login
              </span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}
