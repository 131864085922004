import React from "react";

export default function ButtonIcon(props) {
  const { handleClick, disabled, icon, label } = props;
  const type = props.type ?? "button";
  const opacity = disabled ? "opacity-20" : "";
  const width = props.width ?? "";
  const isInvisible = props.isInvisible ?? false;
  const padding = props.padding ?? "py-2";
  const rounded = props.rounded ?? "rounded-none";

  return (
    <button
      aria-label={label}
      disabled={disabled}
      onClick={handleClick}
      type={type}
      className={`bg-none text-gray-700 hover:text-gray-900 flex items-center justify-center border border-transparent text-sm font-medium focus:outline-none focus:ring-2 focus:ring-oƒffset-2 ${rounded} ${opacity} ${width} ${
        isInvisible ? "invisible" : ""
      } ${padding}`}
    >
      {icon && icon}
      <span className="sr-only">{label}</span>
    </button>
  );
}
