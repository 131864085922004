import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./store/user-context";
import { WorkspaceContextProvider } from "./store/workspace-context";
import { ProjectContextProvider } from "./store/project-context";
import { EmailContextProvider } from "./store/email-context";
import { ExampleContextProvider } from "./store/example-context";
import { ModalContextProvider } from "./store/modal-context";
import { FeedbackContextProvider } from "./store/feedback-context";
import { FunctionContextProvider } from "./store/function-context";
import { PortalStateProvider } from "./components/portal/PortalProvider";

ReactDOM.render(
  <PortalStateProvider>
    <BrowserRouter>
      <UserContextProvider>
        <EmailContextProvider>
          <ModalContextProvider>
            <FeedbackContextProvider>
              <FunctionContextProvider>
                <WorkspaceContextProvider>
                  <ProjectContextProvider>
                    <ExampleContextProvider>
                      <App />
                    </ExampleContextProvider>
                  </ProjectContextProvider>
                </WorkspaceContextProvider>
              </FunctionContextProvider>
            </FeedbackContextProvider>
          </ModalContextProvider>
        </EmailContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  </PortalStateProvider>
  ,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
