import { Fragment, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3BottomLeftIcon,
  BookmarkSquareIcon,
  BriefcaseIcon,
  CogIcon,
  DocumentArrowUpIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import logo from "../../images/icon-light.png";
import logoFull from "../../images/logo-light.png";
import logoLight from "../../images/icon-light.png";
import { logout } from "../../firebase";
import Avatar from "../avatars/Avatar";
import { useContext } from "react";
import { UserContext } from "../../store/user-context";
import { FeedbackContext } from "../../store/feedback-context";
import { MobileTopNavbar } from "../navs/MobileTopNavbar";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PageShell(props) {
  const {
    activeView,
    setActiveView,
    selectedProjectId,
    mainView,
    type,
    secondaryView,
    currentPage,
    clients,
  } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const isInWorkspace = props.isInWorkspace ?? false;
  const userInfoCtx = useContext(UserContext);
  const { userInfo, projects, scopes } = userInfoCtx;
  const { setIsNavigating } = useContext(FeedbackContext);

  if (!visible) {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/user");
  };
  const userNavigation = [
    {
      name: "Your Profile",
      handleClick: () => handleNavigate(),
      role: "link",
      href: "/user",
    },
    { name: "Sign out", handleClick: () => logout(), type: "warning" },
  ];

  const handleSelectProject = (projectId) => {
    setIsNavigating(true);
    const project = projects.find((item) => item.id === projectId);
    navigate(`/scope/${projectId}/${project.workspaceId}`);
  };

  const handleSelectScope = (scope) => {
    setIsNavigating(true);
    navigate(`/scope/${scope.projectId}/${scope.workspaceId}`);
  };

  const workspaceId =
    userInfo.workspaces && userInfo.workspaces?.length > 0
      ? userInfo?.workspaces[0]?.workspaceId
      : false;

  const handleGoToHome = () => {
    if (isInWorkspace) {
      setActiveView("Projects");
    } else {
      if (!workspaceId) {
        return;
      }
      navigate(`/workspace/${workspaceId}/Projects`);
    }
  };

  const handleSelectActiveView = (view) => {
    if (currentPage === "scope") {
      setIsNavigating(true);
      navigate(`/workspace/${workspaceId}/${view}`);
    } else {
      setActiveView(view);
    }
  };

  return (
    <>
      <div className="flex">
        {/* Narrow sidebar */}
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={`${
            isHovering ? "w-48" : "w-16"
          } transition-all duration-500 z-10 shadow hidden md:block fixed left-0`}
        >
          <NarrowSidebar
            isHovering={isHovering}
            userInfo={userInfo}
            visible={visible}
            hasWorkspace={workspaceId !== false}
            scopes={scopes}
          />
          <WideSidebar
            isHovering={isHovering}
            userInfo={userInfo}
            projects={projects}
            scopes={scopes}
            clients={clients}
            visible={visible}
            handleSelectProject={handleSelectProject}
            handleSelectScope={handleSelectScope}
            setActiveView={handleSelectActiveView}
            activeView={activeView}
            selectedProjectId={selectedProjectId}
            handleGoToHome={handleGoToHome}
            hasWorkspace={workspaceId !== false}
          />
        </div>
        {/* Mobile menu */}
        <Transition.Root show={mobileMenuOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-20 md:hidden"
            onClose={setMobileMenuOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel
                  className={
                    "relative flex w-full max-w-full flex-1 flex-col pt-5 pb-4 bg-accent"
                  }
                >
                  <WideSidebar
                    isHovering={true}
                    userInfo={userInfo}
                    projects={projects}
                    scopes={scopes}
                    visible={visible}
                    handleSelectProject={handleSelectProject}
                    handleSelectScope={handleSelectScope}
                    setActiveView={handleSelectActiveView}
                    activeView={activeView}
                    selectedProjectId={selectedProjectId}
                    handleGoToHome={handleGoToHome}
                    hasWorkspace={workspaceId !== false}
                    isMobile={true}
                    setMobileMenuOpen={setMobileMenuOpen}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Content area */}
        <div
          key={`search-${type}`}
          style={{ paddingLeft: visible ? 0 : ".5em", transition: "all .5s" }}
          className="flex flex-1 flex-col"
        >
          <MobileTopNavbar
            setMobileMenuOpen={setMobileMenuOpen}
            activeView={activeView}
          />
          <header className="w-full">
            <div className="relative z-10 flex flex-shrink-0">
              <div className="justify-between px-4 sm:px-6">
                <div className="ml-2 flex items-center space-x-4   + color7sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <div
                          style={{
                            opacity: visible ? 1 : 0,
                            marginRight: visible ? 0 : ".5em",
                            transition: "all .5s",
                          }}
                        >
                          {/* 
                          <Avatar name={userInfo.name} />
                        */}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => {
                              const colors =
                                item.type === "warning"
                                  ? "bg-red-50"
                                  : "bg-slate-100";
                              const role = item.role ?? "button";
                              const href = item.href ?? "#";
                              return (
                                <button
                                  role={role}
                                  data-href={href}
                                  onClick={item.handleClick}
                                  className={classNames(
                                    active ? colors : "",
                                    item.type === "warning"
                                      ? "text-red-700"
                                      : "text-slate-900",
                                    "block px-4 py-2 text-sm w-full text-left"
                                  )}
                                >
                                  {item.name}
                                </button>
                              );
                            }}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex justify-center overflow-hidden flex-1 md:pl-10">
            {secondaryView && (
              <aside className="hidden w-96 overflow-auto border-r border-gray-200 lg:block bg-slate-50">
                {secondaryView}
              </aside>
            )}
            <main className="overflow-y-auto flex-1">
              {/* Primary column */}
              <section aria-labelledby="primary-heading" className="">
                {mainView}
              </section>
            </main>
            {/*  Secondary column (hidden on smaller screens) */}
          </div>
        </div>
      </div>
    </>
  );
}

const ProjectsSection = (props) => {
  const { handleSelect, activeView, handleGoToHome } = props;
  const projects = props.projects ?? [];
  return (
    <div className="ml-1">
      <button
        className="w-full"
        aria-label="Go to home"
        onClick={() => handleGoToHome()}
      >
        <div className="flex items-start justify-start w-full hover:text-lightAccent">
          <BriefcaseIcon
            className={`h-5 w-5 mr-1 text-white text-md ${
              activeView === "Projects"
                ? "font-bold text-lightAccent"
                : "font-normal text-white"
            }`}
            aria-hidden="true"
          />
          <span className="text-white text-sm font-bold">Projects</span>
        </div>
      </button>
      <ul className={`space-y-2 mt-2 ml-7 text-sm transition-all text-left`}>
        {projects.map((project) => (
          <li
            onClick={() => handleSelect(project.id)}
            key={project.id}
            className={`hover:text-white ${
              activeView === project.id
                ? "text-white font-bold"
                : "text-lightAccent font-normal"
            }`}
          >
            <p className="">{project.title}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ScopesSection = (props) => {
  const { handleSelect, activeView, handleGoToHome } = props;
  const scopes = props.scopes ?? [];
  return (
    <div className="ml-1">
      <button
        className="w-full"
        aria-label="Go to home"
        onClick={() => handleGoToHome()}
      >
        <div className="flex items-start justify-start w-full hover:text-lightAccent">
          <DocumentArrowUpIcon
            className={`h-5 w-5 mr-1 text-white text-md ${
              activeView === "Projects"
                ? "font-bold text-lightAccent"
                : "font-normal text-white"
            }`}
            aria-hidden="true"
          />
          <span className="text-white text-sm font-bold">Scopes In</span>
        </div>
      </button>
      <ul className={`space-y-2 mt-2 ml-7 text-sm transition-all text-left`}>
        {scopes.map((scope) => {
          const isActive = scope.id === activeView;
          return (
            <li
              onClick={() =>
                handleSelect({
                  workspaceId: scope.workspaceId,
                  projectId: scope.projectId,
                })
              }
              className={`hover:text-white ${
                isActive
                  ? "text-white font-bold"
                  : "text-lightAccent font-normal"
              }`}
            >
              <p className="">{scope.title}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const LibrarySection = (props) => {
  const { handleSelect, activeView } = props;
  const settingsOptions = ["Service Library"];
  return (
    <div className="ml-1">
      <div className="flex items-start justify-start w-full ">
        <BookmarkSquareIcon
          className="h-5 w-5 mr-2 text-white text-md"
          aria-hidden="true"
        />
        <span className="text-white text-sm font-bold">Services</span>
      </div>
      <ul className={`space-y-2 mt-2 ml-7 text-sm transition-all text-left`}>
        {settingsOptions.map((option) => (
          <li>
            <button
              onClick={() => handleSelect(option)}
              className={`hover:text-white ${
                activeView === option
                  ? "text-white font-bold"
                  : "text-lightAccent font-normal"
              }`}
            >
              <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                {option}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const SettingsSection = (props) => {
  const { handleSelect, activeView } = props;
  const settingsOptions = ["Workspace", "Account"];
  return (
    <div className="ml-1">
      <div className="flex items-start justify-start w-full">
        <CogIcon
          className="h-5 w-5 mr-2 text-white text-md"
          aria-hidden="true"
        />
        <span className="text-white text-sm font-bold">Settings</span>
      </div>
      <ul className={`space-y-2 mt-2 ml-7 text-sm transition-all text-left`}>
        {settingsOptions.map((option) => (
          <li>
            <button
              onClick={() => handleSelect(option)}
              className={`hover:text-white ${
                activeView === option
                  ? "text-white font-bold"
                  : "text-lightAccent font-normal"
              }`}
            >
              <span className="text-ellipsis whitespace-nowrap overflow-hidden">
                {option}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

const NarrowSidebar = ({
  isHovering,
  visible,
  userInfo,
  scopes,
  hasWorkspace,
}) => {
  return (
    <div
      className={`overflow-y-auto md:block hidden bg-accent w-16 z-10 absolute top-0 h-screen`}
    >
      <div
        className={`flex w-full flex-col items-center py-6 ${
          isHovering ? "opacity-0" : "opacity-100"
        } transition-all delay-[350ms]`}
      >
        <div className="flex flex-shrink-0 items-center px-3">
          <img className="w-auto" src={logo} alt="Scopey" />
        </div>
        <div
          style={{ opacity: visible ? 1 : 0, transition: "all .5s" }}
          className="mt-6 w-full flex-1 space-y-1 px-2"
        >
          {/* Sidebar */}
          <div className={`flex justify-center items-center mt-5`}>
            <Avatar name={userInfo.name} />
          </div>

          {(hasWorkspace || scopes.length > 0) && (
            <div className="flex flex-col justify-center items-center pt-10 space-y-10">
              {hasWorkspace && (
                <>
                  <BriefcaseIcon
                    className="h-6 w-6 text-white hover:text-lightAccent text-md"
                    aria-hidden="true"
                  />
                  <CogIcon
                    className="h-6 w-6 text-white hover:text-lightAccent text-md"
                    aria-hidden="true"
                  />
                  <BookmarkSquareIcon
                    className="h-6 w-6 text-white hover:text-lightAccent text-md"
                    aria-hidden="true"
                  />
                </>
              )}
              {scopes.length > 0 && (
                <DocumentArrowUpIcon
                  className="h-6 w-6 text-white hover:text-lightAccent text-md"
                  aria-hidden="true"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const WideSidebar = ({
  isHovering,
  visible,
  userInfo,
  projects,
  scopes,
  handleSelectProject,
  handleSelectScope,
  setActiveView,
  activeView,
  selectedProjectId,
  handleGoToHome,
  hasWorkspace,
  isMobile,
  setMobileMenuOpen,
}) => {
  return (
    <div
      className={`cursor-pointer h-screen overflow-y-auto md:block bg-accent transition-all duration-500 absolute top-0 z-20 ${
        isMobile ? "w-full" : "w-48"
      } ${!isHovering ? "left-[-14rem]" : "left-0"}`}
    >
      <div className={`flex w-full flex-col flex-1 items-center h-full`}>
        <div
          className={`flex flex-shrink-0 items-center px-3 mt-5 text-left w-full`}
        >
          {/* <button aria-label={"Go to home"} onClick={() => handleGoToHome()}>
            <img className={`w-36`} src={logoFull} alt="Scopey" />
          </button> */}
        </div>
        <div
          style={{ opacity: visible ? 1 : 0, transition: "all .5s" }}
          className={`w-full flex-1 space-y-1 px-2 flex flex-col ${
            !isMobile && "mt-2"
          }`}
        >
          {/* Sidebar */}
          <div className="flex justify-between items-center w-full mt-5">
            <div className="inline-flex items-center mb-14">
              <Avatar
                label={"Go to home"}
                handleClick={() => handleGoToHome()}
                size={"h-6 w-6"}
                name={userInfo.name}
                message={userInfo.name}
              />
            </div>
            {isMobile && (
              <button
                type="button"
                className="flex h-12 w-12 mr-4 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setMobileMenuOpen(false)}
              >
                <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                <span className="sr-only">Close sidebar</span>
              </button>
            )}
          </div>
          <div
            className={`flex flex-col justify-start items-start space-y-10 flex-1 pt-4`}
          >
            <>
              {hasWorkspace && (
                <>
                  <ProjectsSection
                    isActive={isHovering}
                    projects={projects}
                    handleSelect={handleSelectProject}
                    activeView={selectedProjectId}
                    handleGoToHome={handleGoToHome}
                  />
                  <SettingsSection
                    activeView={activeView}
                    handleSelect={setActiveView}
                  />
                  <LibrarySection
                    activeView={activeView}
                    handleSelect={setActiveView}
                  />
                </>
              )}
              {scopes.length > 0 && (
                <ScopesSection
                  isActive={isHovering}
                  scopes={scopes}
                  handleSelect={handleSelectScope}
                  activeView={activeView}
                  handleGoToHome={handleGoToHome}
                />
              )}
              <div className="flex-1 flex items-end pb-5">
                <button
                  onClick={() => logout()}
                  className="text-white text-left w-full hover:text-red-700 text-sm pt-8"
                >
                  Logout
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
      <img
        className={`absolute z-0 ${
          isMobile ? "-bottom-[12%] -right-[30%]" : "-bottom-[6%] -right-[28%]"
        }  `}
        src={logoLight}
        alt=""
      />
    </div>
  );
};
