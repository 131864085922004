import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
//import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import logo from "../../images/icon-light.png";
import Avatar from "../avatars/Avatar";
import { useContext } from "react";
import { UserContext } from "../../store/user-context";

export default function PageShell(props) {
  const { type } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const userInfoCtx = useContext(UserContext);
  const { userInfo } = userInfoCtx;

  if (!visible) {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }

  return (
    <>
      <div className="flex h-full min-h-screen">
        {/* Narrow sidebar */}
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={`${
            isHovering ? "w-48" : "w-16"
          } transition-all duration-500 relative shadow hidden md:block`}
        >
          <NarrowSidebar
            isHovering={isHovering}
            userInfo={userInfo}
            visible={visible}
          />
        </div>

        {/* Content area */}
        <div
          key={`search-${type}`}
          style={{ paddingLeft: visible ? 0 : ".5em", transition: "all .5s" }}
          className="flex flex-1 flex-col h-screen overflow-auto"
        >
          <header className="w-full">
            <div className="relative z-10 flex flex-shrink-0">
              <button
                type="button"
                className="px-4 absolute top-2 right-1 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="justify-between px-4 sm:px-6">
                <div className="ml-2 flex items-center space-x-4   + color7sm:ml-6 sm:space-x-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative flex-shrink-0">
                    <div>
                      <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        <span className="sr-only">Open user menu</span>
                        <div
                          style={{
                            opacity: visible ? 1 : 0,
                            marginRight: visible ? 0 : ".5em",
                            transition: "all .5s",
                          }}
                        >
                          {/* 
                          <Avatar name={userInfo.name} />
                        */}
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          <p>Loading</p>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </header>

          {/* Main content */}
          <div className="flex justify-center overflow-hidden h-full flex-1 bg-primary-100">
            <main className="overflow-y-auto flex-1">
              {/* Primary column */}
              <section
                aria-labelledby="primary-heading"
                className="flex h-full min-w-0 flex-1 flex-col lg:order-last pb-5"
              >
              </section>
            </main>
            {/*  Secondary column (hidden on smaller screens) */}
          </div>
        </div>
      </div>
    </>
  );
}

const NarrowSidebar = ({ isHovering, visible, userInfo }) => {
  return (
    <div
      className={`overflow-y-auto md:block hidden bg-accent w-16 z-10 absolute top-0 h-screen`}
    >
      <div
        className={`flex w-full flex-col items-center py-6 ${
          isHovering ? "opacity-0" : "opacity-100"
        } transition-all delay-[350ms]`}
      >
        <div className="flex flex-shrink-0 items-center px-3">
          <img className="w-auto" src={logo} alt="Scopey" />
        </div>
        <div
          style={{ opacity: visible ? 1 : 0, transition: "all .5s" }}
          className="mt-6 w-full flex-1 space-y-1 px-2"
        >
          {/* Sidebar */}
          <div className={`flex justify-center items-center mt-5`}>
            <Avatar name={userInfo.name} />
          </div>
        </div>
      </div>
    </div>
  );
};
