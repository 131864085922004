import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/alerts/Alert";
import OnboardingOverlay from "../../components/overlays/OverlayOnboarding";
import PageShellLoading from "../../components/page-shells/PageShellLoading";
import Toast from "../../components/toasts/Toast";
import { updateUserForm } from "../../services/UserService";
import {
  acceptWorkspaceInvite,
  completeApproverOnboarding,
  completeWorkspaceOnboarding,
  createWorkspace,
} from "../../services/WorkspaceService";
import { UserContext } from "../../store/user-context";
import { FeedbackContext } from "../../store/feedback-context";

function UserDashboard() {
  const userInfoCtx = useContext(UserContext);
  const { userInfo, invites, scopes, scopesLoading, isUserLoading } =
    userInfoCtx;
  const { toast, setToast, alert, setAlert } = useContext(FeedbackContext);

  const handleFeedback = (message, setClose) => {
    setToast(message);
    if (setClose) {
      setClose();
    }
  };

  const handleCreateWorkspace = (obj) => {
    return createWorkspace(userInfo, obj);
  };

  const handleUpdateUserForm = (obj) => {
    return updateUserForm(userInfo.id, obj).then(() =>
      setToast("Profile updated")
    );
  };

  const handleAcceptInvite = (obj) => {
    return acceptWorkspaceInvite(userInfo, obj);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (scopes.length > 0) {
      handleCompleteApproverOnboarding(scopes[0]);
    }
    //eslint-disable-next-line
  }, [scopesLoading]);

  const handleCompleteOnboarding = (emails) => {
    try {
      return completeWorkspaceOnboarding(userInfo, emails).then(() =>
        handleFeedback("Item Updated")
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  const handleCompleteApproverOnboarding = (scope) => {
    try {
      return completeApproverOnboarding(userInfo).then(() =>
        navigate(`/scope/${scope.projectId}/${scope.workspaceId}`)
      );
    } catch (error) {
      setAlert("There has been an error with this request");
    }
  };

  return isUserLoading ? (
    <PageShellLoading />
  ) : !userInfo.isOnboarded ? (
    <OnboardingOverlay
      handleUpdateUserForm={handleUpdateUserForm}
      handleCreateWorkspace={handleCreateWorkspace}
      handleCompleteOnboarding={handleCompleteOnboarding}
      handleAcceptInvite={handleAcceptInvite}
      invites={invites}
      scopes={scopes}
    />
  ) : (
    <div className="w-full min-h-screen">
      {toast && <Toast message={toast} />}
      {alert && <Alert isTop={false} message={alert} />}
      <p>An error has occured...</p>
    </div>
  );
}

export default UserDashboard;
