import React from "react";
import { useNavigate } from "react-router-dom";
import TableProjects from "../../components/tables/TableProjects";
import TableWorkspaces from "../../components/tables/TableWorkspaces";
import { WorkspaceStartOption } from "./WorkspaceStartOption";

function WorkspaceSectionProjects(props) {
  const {
    setActiveView,
    handleSelect,
    items,
    scopes,
    workspaceScopes,
    workspace,
    clients,
  } = props;
  const projects = props.projects ?? [];

  const getScopeInfo = (projectId) => {
    const projectItems = items.filter((item) => item.projectId === projectId);
    const valueAddsRequested = projectItems.filter(
      (item) => item.type === "scopeVA"
    );
    const requests = projectItems.filter((item) => item.type === "request");
    const originalItems = projectItems.filter(
      (item) => item.type === "item" || item.type === "original"
    );
    return { originalItems, valueAddsRequested, requests };
  };

  const projectTableData = projects.map((item) => {
    let quote = 0;
    let budget = 0;
    let budgetMax = 0;
    let max = 0;
    if (workspaceScopes.length > 0) {
      const { originalItems, valueAddsRequested, requests } = getScopeInfo(
        item.id
      );
      console.log(originalItems);
      quote = originalItems.reduce(
        (acc, item) => +acc + +item.cost * (item.quantity ? +item.quantity : 1),
        0
      );
      max = originalItems.reduce(
        (acc, item) =>
          +acc +
          (item.max
            ? +item.max * (item.quantity ? +item.quantity : 1)
            : +item.cost * (item.quantity ? +item.quantity : 1)),
        0
      );
      budget = valueAddsRequested.reduce(
        (acc, item) => +acc + +item.cost * (item.quantity ? +item.quantity : 1),
        quote
      );
      budget = requests.reduce(
        (acc, item) => +acc + +item.cost * (item.quantity ? +item.quantity : 1),
        budget
      );
      budgetMax = valueAddsRequested.reduce(
        (acc, item) =>
          +acc +
          (item.max
            ? +item.max * (item.quantity ? +item.quantity : 1)
            : +item.cost * (item.quantity ? +item.quantity : 1)),
        max
      );
      budgetMax = requests.reduce(
        (acc, item) =>
          +acc +
          (item.max
            ? +item.max * (item.quantity ? +item.quantity : 1)
            : +item.cost * (item.quantity ? +item.quantity : 1)),
        budgetMax
      );
    }

    let hasBudgetChanged = budget !== quote;
    let budgetWithPercentage = ((budget - quote) / quote) * 100;
    let budgetMaxWithPercentage = ((budgetMax - max) / max) * 100;
    budget = (
      <span>
        {budget}
        {budgetMax !== budget && <span> - {budgetMax}</span>}
        {quote > 0 && hasBudgetChanged && (
          <span className="text-xs text-green-700 ml-[.2em]">
            (
            {budgetMax !== budget && (
              <span>{budgetMaxWithPercentage.toFixed(0)}% - </span>
            )}
            {budgetWithPercentage.toFixed(0)}%)
          </span>
        )}
      </span>
    );

    return {
      title: item.title,
      id: item.id,
      clientName: item.clientId
        ? clients?.find((client) => client.id === item.clientId)?.title
        : "",
      //leadName: members?.find((member) => member.userId === item.owner.userRef)?.name,
      quote,
      budget,
      max,
      scopeObj: item.scopeObj,
      workspaceId: item.workspaceId,
      approvalObj:item.approvalObj,
      scopeInvites:item.scopeInvites,
      scopeStatus: item.scopeStatus
    };
  });

  const navigate = useNavigate();
  const handleSelectScope = (entityId, entity) => {
    navigate(`/${entity ?? "workspace"}/${entityId}`);
  };

  return (
    <section className="w-full mx-auto px-2">
      {projects.length <= 0 ? (
        <WorkspaceStartOption handleSelect={setActiveView} />
      ) : (
        <div className="max-w-7xl mx-auto mt-20">
          <TableProjects
            heading={`${workspace.title} Projects`}
            projects={projectTableData}
            handleSelect={handleSelect}
            action={"New Project"}
            handleAdd={() => setActiveView("New Project")}
          />
        </div>
      )}
      {scopes.length > 0 && (
        <div className="max-w-7xl mx-auto">
          <TableWorkspaces
            heading={"My Scopes"}
            handleSelect={handleSelectScope}
            workspaces={scopes}
            isWorkspace={false}
          />
        </div>
      )}
    </section>
  );
}

export default WorkspaceSectionProjects;
