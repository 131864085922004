import { db, firebase } from "../firebase";

export function createRequest(entityObj, userInfo, obj) {
  const { title, description, dueDate, ref } = obj;
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  const { workspaceId, projectId, scopeId, phaseId } = entityObj;
  const attachmentLink = obj.attachmentLink ?? "";
  return db.collection("requests").add({
    title,
    description,
    dueDate,
    workspaceId,
    projectId,
    scopeId,
    phaseId,
    ref,
    attachmentLink,
    cost: 0,
    labels: [],
    isApproved: true,
    isRejected: false,
    isDeleted: false,
    created: firebase.firestore.FieldValue.serverTimestamp(),
    createdTimestamp: Date.now(),
    creator,
  });
}
