import { db, firebase } from "../firebase";
import { URL } from "../utils/constants";
import { getInitialsFromEmail } from "../utils/getUserInfo";

export function createProject(workspaceObj, userInfo, obj) {
  const { title } = obj;
  const { workspaceId, currency } = workspaceObj;
  const description = obj.description ?? "";
  const creator = {
    userId: userInfo.id,
    userUid: userInfo.uid,
  };
  const creatorUser = {
    name: userInfo.name,
    email: userInfo.email,
    userRef: userInfo.id,
    userUid: userInfo.uid,
    type: "Owner",
    projectRole: "Lead",
  };
  let projectId = "";
  let scopeId = "";
  const clientId = obj.clientId ?? "";
  const leadContact = obj.leadContact ?? "";
  const clientContacts = obj.clientContacts ?? [];
  const scopeApprovers = obj.scopeApprovers ?? [];
  const approverIds = obj.approverIds ?? [];
  const scopeInvites = obj.scopeInvites ?? [];

  return db
    .collection("projects")
    .add({
      title,
      description,
      valueAdds: [],
      users: [creatorUser],
      clients: [],
      currency,
      clientId,
      leadContact,
      clientContacts,
      scopeApprovers,
      scopeInvites,
      approverIds,
      workspaceId,
      approvalObj: false,
      isTypeSelected: false,
      isDeleted: false,
      status: "Active",
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      creator,
      owner: creator,
      scopeObj: false,
      scopeStatus: {
        state: 'draft',
        timestamp:'',
      }
    })
    .then((doc) => {
      projectId = doc.id;
      const obj = { projectId, title, leadId: userInfo.id, clientId };
      return db
        .collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .update({
          projects: firebase.firestore.FieldValue.arrayUnion(obj),
        });
    })
    .then(() => {
      return db.collection("scopes").add({
        title,
        description,
        projectId,
        workspaceId,
        approvers: scopeApprovers,
        createdTimestamp: Date.now(),
      });
    })
    .then((scopeDoc) => {
      scopeId = scopeDoc.id;
      return db.collection("projects").doc(projectId).update({
        scopeId,
      });
    })
    .then(() => {
      console.log(scopeId);
      return projectId;
    });
}

export function createProjectScopeInvite(entityObj, email, userInfo) {
  const { projectId, projectTitle, workspaceId } = entityObj;
  //Clients are configured in project, when a user sends scope, each of them get an email
  return db
    .collection("invites")
    .doc(email)
    .collection("scopeInvites")
    .add({
      email,
      projectId,
      projectTitle,
      workspaceId,
      created: firebase.firestore.FieldValue.serverTimestamp(),
      createdTimestamp: Date.now(),
      inviter: {
        name: userInfo.name,
        email: userInfo.email,
      },
      status: "pending",
      isAccepted: false,
    })
    .then((doc) => {
      const inviteObj = {
        invited: email,
        status: "pending",
        createdTimestamp: Date.now(),
        inviter: {
          name: userInfo.name,
          email: userInfo.email,
        },
        inviteId: doc.id,
      };
      return db
        .collection("projects")
        .doc(projectId)
        .collection("private")
        .doc("data")
        .update({
          invites: firebase.firestore.FieldValue.arrayUnion(inviteObj),
        })
        .then(() => {
          return db.collection("mail").add({
            to: email,
            message: {
              subject: `${userInfo.name} would like you to join their workspace in Scopey`,
              html: `<h1>${userInfo.name} would like for you to join Scopey</h1><p><br><br>Exciting things ahead. You've been asked to join a workspace in Scopey. In order to join please follow the link below in and create an account with this address to access the space.</p><p><a href=${URL}>Click me to go to Scopey</a></p>`,
            },
          });
        });
    });
}

export function acceptProjectScopeInvite(userInfo, inviteObj) {
  const { inviteId, type, projectId, projectTitle } = inviteObj;
  const userId = userInfo.id;
  return db
    .collection("invites")
    .doc(userInfo.email)
    .collection("scopeInvites")
    .doc(inviteId)
    .update({
      isAccepted: true,
      status: "accepted",
    })
    .then(() => {
      const userObj = {
        userId,
        userUid: userInfo.userUid,
        email: userInfo.email,
        name: userInfo.name,
        createdTimestamp: Date.now(),
        type,
        tag: getInitialsFromEmail(userInfo.email),
      };
      db.collection("projects")
        .doc(projectId)
        .collection("private")
        .doc("data")
        .update({
          users: firebase.firestore.FieldValue.arrayUnion(userObj),
        });
    })
    .then(() => {
      const projectObj = {
        title: projectTitle,
        projectId,
        createdTimestamp: Date.now(),
      };
      db.collection("users")
        .doc(userId)
        .update({
          projectScopes: firebase.firestore.FieldValue.arrayUnion(projectObj),
        });
    });
}
