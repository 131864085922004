import logo from '../../images/icon-light.png';
import React from "react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { BlackPortal, WhitePortal } from "../portal/PortalProvider";

const NAVBAR_PORTAL_ID = 'navbar-content'

interface IProps {
    setMobileMenuOpen: (v: boolean) => boolean;
    activeView: string;
    logo: string;
}

export const MobileTopNavbar = (props: IProps) => {
    const { setMobileMenuOpen } = props;

    return (
        <header className=" sticky top-0 w-full z-[1] bg-accent">
            <div className=" text-white pt-8 pb-4 font-bold text-lg drop-shadow  w-full px-5 flex justify-between items-center  md:hidden ">
                <img className='w-8 h-8' src={logo} alt='Logo' />
                <WhitePortal id={NAVBAR_PORTAL_ID} />
                <button
                    type="button"
                    className="flex text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => setMobileMenuOpen(true)}
                >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-8 w-8 text-white" aria-hidden="true" />
                </button>
            </div>
        </header>)
}

export const MobileTopNavbarContent = (props: { children: React.ReactNode }) => {
    return (
        <BlackPortal id={NAVBAR_PORTAL_ID}>{props.children}</BlackPortal>
    )
}