import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useContext, useState } from "react";
import { updateCollectionItemMultiple } from "../../services/SharedService";
import Button from "../buttons/Button";
import InputSingle from "../inputs/InputSingle";
import InputTextarea from "../inputs/InputTextarea";
import { LabelSection } from "./CardFull";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";

export const CardVA = ({
  item,
  isContractor,
  cardCollection,
  workspaceId,
  labels,
}) => {
  const [title, setTitle] = useState(item.title);
  const [quantity, setQuantity] = useState(item.quantity ?? false);
  const [cost, setCost] = useState(item.cost);
  const [max, setMax] = useState(item.max ?? 0);
  const [description, setDescription] = useState(item.description);
  const [link, setLink] = useState(item.attachmentLink ?? "");
  const [isRecurring, setIsRecurring] = useState(item.isRecurring ?? false);
  const [itemLabels, setItemLabels] = useState(item.labels ?? []); //labels are needed for combo
  const [labelQuery, setLabelQuery] = useState("");
  const [labelValue, setLabelValue] = useState("");
  const labelStlye = "uppercase text-xs text-gray-600 pl-0 font-normal";

  const isButtonDisabled =
    title === item.title &&
    cost === item.cost &&
    max === item.max &&
    link === item.attachmentLink &&
    description === item.description &&
    isRecurring === item.isRecurring &&
    itemLabels.length === item.labels.length &&
    itemLabels.every((label) => item.labels.includes(label));
  //Needs to consider ones that don't have quantity or phase

  const handleSubmit = (e) => {
    e.preventDefault();
    const itemObj = {
      ...item,
      title,
      cost,
      max,
      description,
      isRecurring,
      attachmentLink: link,
      labels: itemLabels,
    };
    if (quantity) {
      itemObj.quantity = quantity;
    }
    updateCollectionItemMultiple(cardCollection, item.id, itemObj);
  };

  return (
    <div className="mr-4 my-2">
      <form onSubmit={(e) => handleSubmit(e)} className="space-y-2">
        <InputSingle
          value={title}
          setValue={setTitle}
          label={"Task Name"}
          labelTextStyle={labelStlye}
          isDisabled={!isContractor}
        />
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-1">
          <div className="sm:flex-1">
            <InputSingle
              isRequired
              type="number"
              value={cost}
              setValue={setCost}
              label={"Quote low range"}
              labelTextStyle={labelStlye}
              isDisabled={!isContractor}
            />
          </div>
          <div className="sm:flex-1">
            <InputSingle
              type="number"
              value={max}
              setValue={setMax}
              label={"Quote high range"}
              labelTextStyle={labelStlye}
              isDisabled={!isContractor}
            />
          </div>
          {item.quantity && (
            <div className="sm:flex-1">
              <InputSingle
                type="number"
                value={quantity}
                setValue={setQuantity}
                label={"Quantity"}
                labelTextStyle={labelStlye}
                isDisabled={!isContractor}
              />
            </div>
          )}
        </div>
        <LabelSection
          labels={labels}
          confirmedLabels={item.labels}
          itemLabels={itemLabels}
          setItemLabels={setItemLabels}
          setLabelQuery={setLabelQuery}
          labelQuery={labelQuery}
          labelValue={labelValue}
          setLabelValue={setLabelValue}
          workspaceId={workspaceId}
        />
        <InputSingle
          label="Link to shared drive"
          name="link"
          placeholder="Add a link to a shared drive"
          value={link}
          setValue={setLink}
          labelTextStyle={labelStlye}
        />
        <div className="flex justify-end">
          {item.attachmentLink && (
            <div className="flex items-center">
              <a
                rel="noreferrer"
                target="_blank"
                className="flex flex-1 items-center text-accent-500"
                href={item.attachmentLink}
              >
                <span className="text-xs mr-1">Go to link</span>
                <PaperClipIcon className="h-4 w-4" />
              </a>
            </div>
          )}
        </div>
        <InputTextarea
          value={description}
          setValue={setDescription}
          label={"Details"}
          labelTextStyle={labelStlye}
          isDisabled={!isContractor}
        />
        <div className="flex justify-end">
          <Button
            isInvisible={isButtonDisabled}
            disabled={isButtonDisabled}
            message="Update"
            type="submit"
            bg={"tertiary"}
          />
        </div>
      </form>
    </div>
  );
};
