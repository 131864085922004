import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import PageShell from "../../components/page-shells/PageShellLoading";
import { db } from "../../firebase";
import { EmailContext } from "../../store/email-context";
import { FeedbackContext } from "../../store/feedback-context";
import { UserContext } from "../../store/user-context";
import { WorkspaceContext } from "../../store/workspace-context";
import { sortArrayByOrder, sortByDateDesc } from "../../utils/sortArray";
import ScopeDashboardHolder from "./ScopeDashboardHolder";

export default function ScopeDashboardData() {
  let { projectId, workspaceId } = useParams();
  const [scope, setScope] = useState(false);
  const [isScopeLoading, setIsScopeLoading] = useState(true);
  const [project, setProject] = useState(false);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [isPrivateDataLoading, setIsPrivateDataLoading] = useState(true);
  const [workspaceProjects, setWorkspaceProjects] = useState([]);
  const [privateData, setPrivateData] = useState(false);
  const [isApprover, setIsApprover] = useState(false);
  const [client, setClient] = useState(false);
  const [activeRequest, setActiveRequest] = useState(false);
  const [isWorkspaceIdSet, setIsWorkspaceIdSet] = useState(false); //eslint-disable-line
  const userInfoCtx = useContext(UserContext);
  const { userInfo, authLoading } = userInfoCtx;
  const { isNavigating, setIsNavigating } = useContext(FeedbackContext);
  const { workspacePrivateData, isContractor, setWorkspaceId } =
    useContext(WorkspaceContext);
  console.log(isContractor);

  let requests = [];
  let comments = [];
  let activeRequests = [];
  let items = [];
  let phases = [];
  let valueAdds = [];
  let scopeVAs = [];
  let templates = [];
  let labels = [];
  const emailCtx = useContext(EmailContext);
  const { handleSetEmailInfo } = emailCtx;
  console.log(workspacePrivateData);

  useEffect(() => {
    if (workspaceId && !isWorkspaceIdSet) {
      setWorkspaceId(workspaceId);
      setIsWorkspaceIdSet(true);
    }
    //eslint-disable-next-line
  }, [workspaceId]);

  activeRequests = requests.filter(
    (item) => !item.isApproved && !item.isRejected
  );

  useEffect(() => {
    if (activeRequests.length > 0 && !activeRequest) {
      const activeReq = activeRequests[0];
      setActiveRequest(activeReq);
    }
    //eslint-disable-next-line
  }, [activeRequests]);

  const fetchProject = async () => {
    if (!isProjectLoading) {
      return;
    }
    try {
      db.collection("projects")
        .doc(projectId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setProject(data);
          setIsProjectLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const fetchScope = async () => {
    if (!isScopeLoading) {
      return;
    }
    try {
      await fetchWorkspaceProjects();
      setIsScopeLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWorkspaceProjects = async () => {
    if (!isPrivateDataLoading) {
      return;
    }
    try {
      db.collection("workspaces")
        .doc(workspaceId)
        .collection("private")
        .doc("data")
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setWorkspaceProjects(data.projects);
          setIsPrivateDataLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (scope && userInfo && !isApprover) {
      const isApprover = project.scopeApprovers.includes(userInfo.email);
      if (isApprover) {
        setIsApprover(true);
      }
    }
    //eslint-disable-next-line
  }, [scope, userInfo]);

  useEffect(() => {
    fetchScope();
    fetchProject();
    //eslint-disable-next-line
  }, [isScopeLoading, projectId]);

  useEffect(() => {
    setIsScopeLoading(true);
    setIsProjectLoading(true);
    fetchScope();
    fetchProject();
    //eslint-disable-next-line
  }, [projectId]);

  const [requestValue, requestLoading] = useCollection(
    db
      .collection("requests")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (requestValue && !requestLoading) {
    //eslint-disable-next-line
    requestValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "request";
      requests.push(item);
    });
  }

  const [commentValue, commentLoading] = useCollection(
    db
      .collection("comments")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (commentValue && !commentLoading) {
    //eslint-disable-next-line
    commentValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "comment";
      comments.push(item);
    });
  }

  const [itemValue, itemLoading] = useCollection(
    db
      .collection("items")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (itemValue && !itemLoading) {
    //eslint-disable-next-line
    itemValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = item.type ?? "item";
      items.push(item);
    });
  }

  const [phaseValue, phaseLoading] = useCollection(
    db
      .collection("phases")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (phaseValue && !phaseLoading) {
    //eslint-disable-next-line
    phaseValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "phase";
      phases.push(item);
    });
    phases = sortArrayByOrder(phases);
  }

  const [scopeVAsValue, scopeVAsLoading] = useCollection(
    db
      .collection("scopeVAs")
      .where("isDeleted", "==", false)
      .where("projectId", "==", projectId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (scopeVAsValue && !scopeVAsLoading) {
    //eslint-disable-next-line
    scopeVAsValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "scopeVA";
      scopeVAs.push(item);
    });
  }

  const [valueAddsValue, valueAddsLoading] = useCollection(
    db
      .collection("valueAdds")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (valueAddsValue && !valueAddsLoading) {
    //eslint-disable-next-line
    valueAddsValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "valueAdd";
      valueAdds.push(item);
    });
  }

  const [templateValue, templateLoading] = useCollection(
    db
      .collection("templates")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (templateValue && !templateLoading) {
    //eslint-disable-next-line
    templateValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "template";
      templates.push(item);
    });
  }

  const [labelValue, labelLoading] = useCollection(
    db
      .collection("labels")
      .where("isDeleted", "==", false)
      .where("workspaceId", "==", workspaceId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (labelValue && !labelLoading) {
    //eslint-disable-next-line
    labelValue.docs.map((doc) => {
      const item = doc.data();
      item.id = doc.id;
      item.type = "label";
      labels.push(item);
    });
  }

  const isLoading =
    authLoading ||
    isScopeLoading ||
    requestLoading ||
    commentLoading ||
    itemLoading ||
    phaseLoading ||
    valueAddsLoading ||
    scopeVAsLoading ||
    templateLoading ||
    labelLoading;

  useEffect(() => {
    if (!isLoading) {
      handleSetEmailInfo(project, userInfo.name);
    }
    //eslint-disable-next-line
  }, [isLoading]);

  useEffect(() => {
    if (isNavigating && !isLoading) {
      setIsNavigating(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return isLoading || isNavigating ? (
    <PageShell />
  ) : (
    <ScopeDashboardHolder
      scopeId={project.scopeId}
      projectId={projectId}
      project={project}
      workspaceId={workspaceId}
      comments={comments}
      requests={requests}
      items={sortByDateDesc(items)}
      phases={phases}
      valueAdds={valueAdds}
      scopeVAs={scopeVAs}
      templates={templates}
      labels={labels}
      privateData={privateData}
      setActiveRequest={setActiveRequest}
      workspaceProjects={workspaceProjects}
      userInfo={userInfo}
      client={client}
      isContractor={isContractor}
      isApprover={isApprover}
      isLoading={isLoading}
    />
  );
}
