export function getPriorityColor(status) {
  switch (status) {
    case "high":
      return { color: "text-red-700", bg: "bg-red-100" };
    case "medium":
      return { color: "text-indigo-700", bg: "bg-indigo-100" };
    case "low":
      return { color: "text-green-700", bg: "bg-green-100" };
    default:
      return { color: "text-gray-700", bg: "bg-gray-100" };
  }
}

export const getColors = (color) => {
  switch (color) {
    case "red":
      return {
        colorText3: "text-red-300",
        colorText6: "text-red-600",
        color: "bg-red-600",
        color6: "bg-red-600",
        color7: "bg-red-700",
        color8: "bg-red-800",
        colorHover: "hover:bg-red-700",
        colorHover8: "hover:bg-red-800",
        focusColor: "focus:ring-red-500",
      };
    case "indigo":
      return {
        colorText3: "text-indigo-300",
        colorText6: "text-indigo-600",
        color: "bg-indigo-600",
        color6: "bg-indigo-600",
        color7: "bg-indigo-700",
        color8: "bg-indigo-800",
        colorHover: "hover:bg-indigo-700",
        colorHover8: "hover:bg-indigo-800",
        focusColor: "focus:ring-indigo-500",
      };
    case "violet":
      return {
        colorText3: "text-violet-300",
        colorText6: "text-violet-600",
        color: "bg-violet-600",
        color6: "bg-violet-600",
        color7: "bg-violet-700",
        color8: "bg-violet-800",
        colorHover: "hover:bg-violet-700",
        colorHover8: "hover:bg-violet-800",
        focusColor: "focus:ring-violet-500",
      };
    case "blue":
      return {
        colorText3: "text-blue-300",
        colorText6: "text-blue-600",
        color: "bg-blue-600",
        color6: "bg-blue-600",
        color7: "bg-blue-700",
        color8: "bg-blue-800",
        colorHover: "hover:bg-blue-700",
        colorHover8: "hover:bg-blue-800",
        focusColor: "focus:ring-blue-500",
      };
    case "accent":
      return {
        colorText3: "text-accent-300",
        colorText6: "text-accent-600",
        color: "bg-accent-500",
        color6: "bg-accent-600",
        color7: "bg-accent-700",
        color8: "bg-accent-800",
        colorHover: "hover:bg-accent-600",
        colorHover8: "hover:bg-accent-700",
        focusColor: "focus:ring-accent-500",
      };
    case "white":
      return {
        colorText3: "text-gray-500",
        colorText6: "text-gray-800",
        color: "bg-white",
        color6: "bg-white",
        color7: "bg-white",
        color8: "bg-white",
        colorHover: "hover:bg-gray-100",
        colorHover8: "hover:bg-gray-600",
        focusColor: "focus:ring-gray-500",
      };
    case "tertiary":
      return {
        colorText3: "text-tertiary-300",
        colorText6: "text-tertiary-600",
        color: "bg-tertiary-500",
        color6: "bg-tertiary-600",
        color7: "bg-tertiary-700",
        color8: "bg-tertiary-800",
        colorHover: "hover:bg-tertiary-600",
        colorHover8: "hover:bg-tertiary-800",
        focusColor: "focus:ring-tertiary-700",
      };
    default:
      return {
        colorText3: "text-primary-300",
        colorText6: "text-primary-600",
        color: "bg-primary-500",
        color6: "bg-primary-600",
        color7: "bg-primary-700",
        color8: "bg-primary-800",
        colorHover: "hover:bg-primary-600",
        colorHover8: "hover:bg-primary-800",
        focusColor: "focus:ring-tertiary-700",
      };
  }
};
