import ServiceFrame from "../../images/service.png";
import ScratchFrame from "../../images/scratch_crop.png";
import { ActionHeading } from "../../components/tables/TableCreateScope";

export const WorkspaceStartOption = ({ handleSelect }) => {
  return (
    <div className="min-h-screen pt-10 px-20 bg-primary-100">
      <ActionHeading
        title={"Welcome to your project scope dashboard"}
        description={
          "This is where you will see a summary of your project scopes. To get started you can dive straight into creating a project scope or start building up your service library."
        }
      />
      <div className="flex md:space-x-5 flex-col md:flex-row mt-10">
        <StartFromScratchButton
          handleCreate={() => handleSelect("New Project")}
          image={ScratchFrame}
          title={"Start a new project scope"}
        />
        <StartFromScratchButton
          handleCreate={() => handleSelect("Service Library")}
          image={ServiceFrame}
          hover={"hover:bg-tertiary-50"}
          title={"Add to my 'Service Library'"}
        />
      </div>
    </div>
  );
};

const StartFromScratchButton = ({ handleCreate, image, hover, title }) => {
  hover = hover ?? "hover:bg-accent-50";
  return (
    <div
      className={`flex my-2 flex-1 justify-center items-center cursor-pointer rounded-2xl shadow bg-white ${hover}`}
    >
      <div className="text-center flex items-center flex-1 h-full">
        <button onClick={handleCreate} className="flex-1 h-full">
          <p className="text-accent font-medium text-lg">{title}</p>
        </button>
        <button onClick={handleCreate}>
          <img
            className="w-72 max-w-2/3"
            alt="Start from scratch"
            src={image}
          />
        </button>
      </div>
    </div>
  );
};
