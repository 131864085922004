import { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import InputSingle from "../inputs/InputSingle";
import Button from "../buttons/Button";
import ButtonText from "../buttons/ButtonText";
import InputTextarea from "../inputs/InputTextarea";
import {
  ArrowLongLeftIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  ClockIcon,
  PlusCircleIcon,
  ReceiptRefundIcon,
} from "@heroicons/react/20/solid";
import { HoverBanner } from "../inputs/InputCheckboxSingle";
import ButtonIcon from "../buttons/ButtonIcon";
import { CardVA } from "../card/CardVA";
import Fuse from "fuse.js";
import { LabelSection } from "../card/CardFull";
import { FunctionContext } from "../../store/function-context";
import { useMemo } from "react";
import { ModalContext } from "../../store/modal-context";
import SmartScopeSection from "../sections/SmartScopeSection";
import SmartSuggestionsSection from "../sections/SmartSuggestionsSection";
import { formatCurrency } from "../../utils/formatCurrency";

const sections = [
  {
    id: "library",
    title: "My service library",
    description:
      "Build your project scope from your library of services and products (this database can be edited from the purple side nav). You can edit the quote and details once it’s added to the project scope on the left. Your client will not see this slide window of your services.",
  },
  {
    id: "smart",
    title: "Smart suggestions",
    description:
      "Scopey’s smart suggestions give you a helping hand to build a project scope quickly. You can edit the details of any service once it’s added to the right hand scope.",
  },
  {
    id: "smart-scope",
    title: "Smart scope",
    description:
      "Scopey’s smart scope will build a whole project scope for your project based on prompts, so be as detailed as possible. You can edit project scope once generated.",
  },
];

export default function SlideoutLibrary(props) {
  const {
    isOpen,
    setIsOpen,
    handleCreate,
    handleAddValueAdd,
    handleAddItem,
    valueAdds,
    scopeVAs,
    currency,
    workspaceId,
    labels,
    items,
    isScopeApproved,
  } = props;
  const [currentSection, setCurrentSection] = useState(sections[0].id);
  const [query, setQuery] = useState("");
  const [isFormActive, setIsFormActive] = useState(false);
  const title = sections.find((section) => section.id === currentSection).title;
  const { setActiveAddPhase } = useContext(ModalContext);

  const handleSubmitService = (e, obj, resetState) => {
    e.preventDefault();

    const handleReset = () => {
      resetState();
      setIsFormActive(false);
    };

    return handleCreate(obj).then(() => {
      handleReset();
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsFormActive(false);
    setActiveAddPhase(null);
  };

  //Set service list in useMemo
  const filteredItems = useMemo(() => {
    const fuzzy = new Fuse(valueAdds, {
      keys: ["title", "labels"],
      threshold: 0.3,
    });
    const fuzzyResults = fuzzy.search(query);
    const filteredItems = query
      ? fuzzyResults.map((item) => item.item)
      : valueAdds;
    return filteredItems;
  }, [valueAdds, query]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <div className="fixed inset-0" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white px-6 shadow-xl w-full ">
                    <Header title={title} setIsOpen={setIsOpen} />
                    <div className="px-1 sm:p-1">
                      <HeaderMenu
                        options={sections}
                        handleSelect={setCurrentSection}
                        currentSection={currentSection}
                      />

                      {displaySections(currentSection)}

                      {currentSection === "library" && (
                        <>
                          <div>
                            {!isFormActive && (
                              <>
                                <SearchForm query={query} setQuery={setQuery} />
                                <AddButton setIsFormActive={setIsFormActive} />
                              </>
                            )}
                          </div>
                          <div>
                            {isFormActive ? (
                              <AddServiceForm
                                handleSubmit={handleSubmitService}
                                setIsFormActive={setIsFormActive}
                                labels={labels}
                                workspaceId={workspaceId}
                              />
                            ) : (
                              <ServiceList
                                valueAdds={valueAdds}
                                currency={currency}
                                handleAdd={handleAddItem}
                                handleAddValueAdd={handleAddValueAdd}
                                scopeVAs={scopeVAs}
                                filteredItems={filteredItems}
                                items={items}
                                query={query}
                                workspaceId={workspaceId}
                                labels={labels}
                                isScopeApproved={isScopeApproved}
                              />
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const displaySections = (currentSection) => {
  switch (currentSection) {
    case "library":
      console.log("This is the Library Section");
      break;
    case "smart":
      return <ComingSoon />;
    //<SmartSuggestionsSection />
    case "smart-scope":
      return <ComingSoon />;
    //<SmartScopeSection />
  }
};

const ComingSoon = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-20">
      <ClockIcon className="h-16 w-16 text-gray-400" />
      <p className="text-gray-400">Coming soon</p>
    </div>
  );
};

const CloseButton = ({ setIsOpen }) => {
  return (
    <div className="flex w-full justify-end p-2">
      <button
        type="button"
        className="relative text-gray-500 hover:text-gray-600"
        onClick={() => setIsOpen(false)}
      >
        <span className="" />
        <span className="sr-only">Close panel</span>
        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
  );
};

const Header = ({ title, setIsOpen }) => {
  return (
    <div className="w-full">
      <Dialog.Title className="text-base font-semibold leading-6 text-gray-900 sr-only">
        {title}
      </Dialog.Title>
      <CloseButton setIsOpen={setIsOpen} />
    </div>
  );
};

const HeaderMenu = ({ options, handleSelect, currentSection }) => {
  return (
    <div>
      <ul className="w-full flex space-x-4 mb-6">
        {options.map((option) => {
          const isActive = option.id === currentSection;
          return (
            <li key={option.id}>
              <button
                onClick={() => handleSelect(option.id)}
                type="button"
                className={`text-lg ${
                  isActive
                    ? "font-semibold underline decoration-tertiary decoration-2 underline-offset-4"
                    : "no-underline font-normal text-gray-600"
                }`}
              >
                {option.title}
              </button>
            </li>
          );
        })}
      </ul>
      <p className="mt-2 text-sm text-gray-600 mb-6">
        {options.find((option) => option.id === currentSection).description}
      </p>
    </div>
  );
};

const SearchForm = ({ query, setQuery }) => {
  return (
    <form name="Search for service">
      <InputSingle
        value={query}
        setValue={setQuery}
        placeholder={"Filter by name or tag"}
        label={"Filter by name or tag"}
      />
    </form>
  );
};

const AddButton = ({ setIsFormActive }) => {
  return (
    <div className="flex justify-end mt-2">
      <Button
        handleClick={() => setIsFormActive(true)}
        bg={"accent"}
        message={"Add new service to library"}
      />
    </div>
  );
};

const AddServiceForm = ({
  handleSubmit,
  setIsFormActive,
  labels,
  workspaceId,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [cost, setCost] = useState(0);
  const [max, setMax] = useState(0);
  const [itemLabels, setItemLabels] = useState([]); //labels are needed for combo
  const [labelQuery, setLabelQuery] = useState("");
  const [labelValue, setLabelValue] = useState("");
  const obj = { title, description, cost, max, labels: itemLabels };

  const resetState = () => {
    setTitle("");
    setDescription("");
    setCost(0);
    setMax(0);
  };
  return (
    <form
      name="Create new service"
      className="space-y-4"
      onSubmit={(e) => handleSubmit(e, obj, resetState)}
    >
      <InputSingle
        value={title}
        setValue={setTitle}
        placeholder={"Service title"}
        label={"Service title"}
        isRequired={true}
      />
      <InputTextarea
        value={description}
        setValue={setDescription}
        placeholder={"Service description"}
        label={"Service description"}
      />
      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-2">
        <div className="flex-1">
          <InputSingle
            isRequired
            type="number"
            value={cost}
            setValue={setCost}
            placeholder={"Quote low range"}
            label={"Quote low range"}
          />
        </div>
        <div className="flex-1">
          <InputSingle
            type="number"
            value={max}
            setValue={setMax}
            placeholder={"Quote high range"}
            label={"Quote high range"}
          />
        </div>
      </div>
      <LabelSection
        labels={labels}
        confirmedLabels={[]}
        itemLabels={itemLabels}
        setItemLabels={setItemLabels}
        setLabelQuery={setLabelQuery}
        labelQuery={labelQuery}
        labelValue={labelValue}
        setLabelValue={setLabelValue}
        workspaceId={workspaceId}
      />
      <div className="flex justify-end space-x-2">
        <div>
          <ButtonText
            handleClick={() => setIsFormActive(false)}
            message="Go back"
          />
        </div>
        <Button type="submit" message="Submit" />
      </div>
    </form>
  );
};

const ServiceList = (props) => {
  const {
    handleAdd,
    handleAddValueAdd,
    currency,
    scopeVAs,
    filteredItems,
    items,
    workspaceId,
    labels,
    isScopeApproved,
  } = props;

  return (
    <div>
      {filteredItems.length > 0 && (
        <div className="flex mt-5">
          <div className="flex-1 flex justify-start text-xs text-gray-500 font-medium uppercase">
            Add to Project Scope
          </div>
          <div className="flex-1 flex justify-end text-xs text-gray-500 font-medium uppercase">
            Add as Optional Service
          </div>
          <div className="min-w-[6em]">{/* To match table */}</div>
        </div>
      )}
      <figure>
        <figcaption className="sr-only">Service list</figcaption>
        <ul className="space-y-1 mt-1">
          {filteredItems.map((valueAdd) => {
            const isOptionalAdded = scopeVAs.find(
              (va) => va.vaId === valueAdd.id
            );
            const addedCount = items.filter(
              (item) => item.vaId === valueAdd.id
            ).length;
            return (
              <ServiceItem
                key={valueAdd.id}
                valueAdd={valueAdd}
                currency={currency}
                handleAdd={handleAdd}
                handleAddValueAdd={handleAddValueAdd}
                addedCount={addedCount}
                isOptionalAdded={isOptionalAdded}
                workspaceId={workspaceId}
                labels={labels}
                isScopeApproved={isScopeApproved}
              />
            );
          })}
        </ul>
      </figure>
    </div>
  );
};

const ServiceItem = ({
  valueAdd,
  currency,
  handleAdd,
  handleAddValueAdd,
  isOptionalAdded,
  workspaceId,
  labels,
  addedCount,
  isScopeApproved,
}) => {
  //Add useMemo to prevent re-rendering
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { handleUpdateItem } = useContext(FunctionContext);
  const buttonLabel = isExpanded ? "Collapse" : "Expand";
  const buttonIcon = (
    <ChevronRightIcon
      className={`h-6 w-6 text-gray-400 ${
        isExpanded ? "rotate-90" : "rotate-0"
      }`}
    />
  );
  const icon = <ArrowLongLeftIcon className="h-6 w-6" aria-hidden="true" />;

  const handleAddItem = (id) => {
    setIsAnimating(true);
    return handleAdd(id).then(() => {
      //setIsAnimating(false);
    });
  };

  const handleCheckItem = () => {
    if (isOptionalAdded) {
      const itemObj = { collection: "scopeVAs", itemId: isOptionalAdded.id };
      const obj = { prop: "isRecurring", value: true };
      const feedbackObj = {
        message: "Optional Service set as recurring",
      };
      return handleUpdateItem(itemObj, obj, feedbackObj);
    } else {
      return handleAddValueAdd(valueAdd.id);
    }
  };

  useEffect(
    () => {
      if (isAnimating) {
        setTimeout(() => {
          setIsAnimating(false);
        }, 1500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAnimating]
  );

  return (
    <li
      className={`border border-gray-200 pr-5 rounded-md cursor-pointer transition-all duration-1000 ${
        isAnimating ? "opacity-0 ml-10" : "opacity-100"
      }`}
    >
      <div className="flex justify-center items-center">
        <div
          onMouseLeave={() => setIsHovering(false)}
          onMouseEnter={() => setIsHovering(true)}
          className="relative"
        >
          {isScopeApproved && isHovering && (
            <HoverBanner
              left="left-0"
              hoverMessage={
                "You must add from Optional Services once a scope has been approved."
              }
            />
          )}
          <button
            disabled={isScopeApproved}
            onClick={() => handleAddItem(valueAdd.id)}
            type="button"
            className={`${
              isScopeApproved ? "opacity-30" : "opacity-100"
            } bg-tertiary text-white px-3 py-5 rounded-l-md`}
          >
            {icon}
            <span className="sr-only">Add to project dashboard</span>
          </button>
        </div>
        <div className="flex-1 items-center flex">
          <ButtonIcon
            label={buttonLabel}
            icon={buttonIcon}
            handleClick={() => setIsExpanded(!isExpanded)}
            rounded="rounded-lg"
          />
          <div className="ml-1">
            <h3
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-md font-medium"
            >
              {valueAdd.title}{" "}
              <span className="font-normal text-sm text-gray-500">
                {addedCount > 1 && `(${addedCount})`}
              </span>
            </h3>
            <div>
              {valueAdd.description && (
                <p className="text-sm text-gray-500 line-clamp-1">
                  {valueAdd.description}
                </p>
              )}
            </div>
          </div>
        </div>
        <div>
          <AddItemButton
            isAdded={isOptionalAdded}
            handleAdd={() => handleCheckItem(valueAdd.id)}
            isAnimating={isAnimating}
            isRecurring={isOptionalAdded?.isRecurring}
          />
        </div>
        <div className="flex min-w-[10em] justify-end">
          <p className="text-sm text-gray-600">
            {formatCurrency(valueAdd.cost, currency)}

          </p>
          {valueAdd.max > 0 && (
            <p className="text-sm text-gray-600 ml-[.2em]">
              - {formatCurrency(valueAdd.max,currency)}
            </p>
          )}
        </div>
      </div>
      {isExpanded && (
        <div className="px-2">
          <CardVA
            item={valueAdd}
            isContractor={true}
            cardCollection={"valueAdds"}
            workspaceId={workspaceId}
            labels={labels}
          />
        </div>
      )}
    </li>
  );
};

const AddItemButton = ({ handleAdd, isAdded, isAnimating, isRecurring }) => {
  const [isHovering, setIsHovering] = useState(false);
  const label = isAdded
    ? "Remove as Optional Service"
    : "Add as Optional Service";
  const hoverMessage = !isAdded
    ? "Add as Optional Service"
    : isRecurring
    ? "Item is a Recurring Service"
    : "Set as Recurring Service";
  const iconClasses = `${
    isHovering && !isRecurring ? "text-tertiary-600" : "text-tertiary"
  } h-5 w-5"`;
  const icon =
    isAnimating || isRecurring ? (
      <CheckCircleIcon className={iconClasses} />
    ) : isAdded && !isRecurring ? (
      <ReceiptRefundIcon className={iconClasses} />
    ) : (
      <PlusCircleIcon className={iconClasses} />
    );
  return (
    <div
      onMouseLeave={() => setIsHovering(false)}
      onMouseEnter={() => setIsHovering(true)}
      className="relative"
    >
      {isHovering && <HoverBanner hoverMessage={hoverMessage} />}
      <button disabled={isRecurring} onClick={handleAdd} aria-label={label}>
        {icon}
      </button>
    </div>
  );
};

/* }
const ActionButtons = ({ setIsOpen }) => {
  return (
    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
      <div className="flex justify-end space-x-3">
        <button
          type="button"
          className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Create
        </button>
      </div>
    </div>
  );
};
*/
