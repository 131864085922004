import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { WorkspaceContext } from "./workspace-context";

export const ProjectContext = createContext({
  invites: [],
  project: {},
  isProjectLoading: true,
  setProjectId: () => {},
  workspace: {},
  products: [],
});

export function ProjectContextProvider(props) {
  const [projectId, setProjectId] = useState(false);
  const [project, setProject] = useState(false);
  const [loadedProjects, setLoadedProjects] = useState([]);
  const [isProjectLoading, setIsProjectLoading] = useState(true);
  const [invites, setInvites] = useState([]);
  const workspaceCtx = useContext(WorkspaceContext);
  const { workspace } = workspaceCtx;

  const hasProjectLoaded = loadedProjects.some((item) => item.id === projectId);

  const getCurrentProject = async () => {
    if (!projectId) {
      return;
    }
    if (hasProjectLoaded) {
      const loadedProject = loadedProjects.find(
        (item) => item.id === projectId
      );
      setProject(loadedProject);
      setIsProjectLoading(false);
    } else {
      fetchProject();
    }
  };

  const fetchProject = async () => {
    if (!isProjectLoading) {
      return;
    }
    try {
      db.collection("workspaces")
        .doc(workspace.id)
        .collection("projects")
        .doc(projectId)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          const projects = loadedProjects.map((item) => [...item, data]);
          setLoadedProjects(projects);
          setProject(data);
          setIsProjectLoading(false);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getCurrentProject();
    //eslint-disable-next-line
  }, [isProjectLoading, projectId]);

  const contextValue = {
    workspace,
    project,
    isProjectLoading,
    invites,
    setProjectId,
    products: workspaceCtx.products ?? [],
  };

  return (
    <ProjectContext.Provider value={contextValue}>
      {props.children}
    </ProjectContext.Provider>
  );
}
