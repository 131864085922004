export function sortArrayByString(arr) {
  const result = arr.sort(compareTitle);
  return result;
}

export function sortArrayByStringOnly(arr) {
  const result = arr.sort(compare);
  return result;
}

export function sortArrayByValue(arr) {
  const result = arr.sort(compareValue);
  return result;
}

export function sortArrayByLevel(arr) {
  const result = arr.sort(compareLevel);
  return result;
}

export function sortArrayByOrder(arr) {
  const result = arr.sort(compareOrder);
  return result;
}

export function sortyByDate(arr) {
  const result = arr.sort(compareDate);
  return result;
}

export function sortByDateDesc(arr) {
  const result = arr.sort(compareDateDesc);
  return result;
}

function compareDateDesc(a, b) {
  if (a.createdTimestamp > b.createdTimestamp) {
    return 1;
  }
  if (a.createdTimestamp < b.createdTimestamp) {
    return -1;
  }
  return 0;
}

function compareDate(a, b) {
  if (a.createdTimestamp < b.createdTimestamp) {
    return 1;
  }
  if (a.createdTimestamp > b.createdTimestamp) {
    return -1;
  }
  return 0;
}

function compare(a, b) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
}

function compareTitle(a, b) {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
}

function compareValue(a, b) {
  if (a.value < b.value) {
    return -1;
  }
  if (a.value > b.value) {
    return 1;
  }
  return 0;
}

function compareLevel(a, b) {
  if (a.level < b.level) {
    return -1;
  }
  if (a.level > b.level) {
    return 1;
  }
  return 0;
}

function compareOrder(a, b) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function getUniqueValues(arr) {
  return arr.filter(onlyUnique);
}

export function removeNulls(arr) {
  return arr.filter((item) => item !== null);
}
