import React from "react";
import { useState } from "react";
import contractor from "../../images/zoe.png";
import logo from "../../images/logo.png";
import Button from "../buttons/Button";
import InputSelection from "../inputs/InputSelection";
import InputCheckbox from "../inputs/InputCheckbox";
import InputSingle from "../inputs/InputSingle";
import InputCombo from "../inputs/InputCombo";
import Pill from "../pills/Pill";
import { useEffect } from "react";
import Steps from "../steps/Steps";
import ActionPanel from "../action-panels/ActionPanel";

const stepsArr = [
  { label: "Password", value: "password" },
  { label: "Profile", value: "profile" },
  { label: "Workspace", value: "workspace" },
];

/* {label: "Team", value: "team"}, */

export default function OnboardingOverlay(props) {
  const {
    handleUpdateUserForm,
    handleCreateWorkspace,
    handleCompleteOnboarding,
    invites,
    handleAcceptInvite,
  } = props;
  const [activeView, setActiveView] = useState("password");
  const [industry, setIndustry] = useState("");
  const [goals, setGoals] = useState([]);
  const [workspaceTitle, setWorkspaceTitle] = useState("");
  const [currency, setCurrency] = useState(currencyOptions[2]);
  const [doesExcludeTax, setDoesExcludeTax] = useState(true);
  const [members, setMembers] = useState([]);
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (!visible) {
        setVisible(true);
      }
    }, 200);
    //eslint-disable-next-line
  }, []);

  const buttonMessage =
    activeView === "team" ? "Woohoo let's get started" : "Next";

  const handleClick = () => {
    if (activeView === "password" && password === "scopeit20") {
      return setActiveView("profile");
    }
    if (activeView === "profile") {
      return handleUpdateUserForm({ industry, goals }).then(() =>
        setActiveView("workspace")
      );
    }
    //if (activeView === "workspace") {
    //  setActiveView("team");
    //}
    if (activeView === "workspace") {
      return handleCreateWorkspace({
        title: workspaceTitle,
        currency,
        doesExcludeTax,
      }).then(() => handleCompleteOnboarding([]));
    }
    if (activeView === "team") {
      return handleCompleteOnboarding(members);
    }
  };

  const handleSetStep = (view) => {
    if (password !== "scopeit20") {
      return;
    }
    setActiveView(view);
  };

  const handleToggleGoal = (goal) => {
    if (goals.includes(goal)) {
      setGoals(goals.filter((g) => g !== goal));
    } else {
      setGoals([...goals, goal]);
    }
  };

  function getInputContent(type) {
    switch (type) {
      case "password":
        return (
          <UserPasswordSection password={password} setPassword={setPassword} />
        );
      case "profile":
        return (
          <UserInfoSection
            industry={industry}
            setIndustry={setIndustry}
            goals={goals}
            handleToggleGoal={handleToggleGoal}
          />
        );
      case "workspace":
        return (
          <WorkspaceInfoSection
            workspaceTitle={workspaceTitle}
            setWorkspaceTitle={setWorkspaceTitle}
            currency={currency}
            setCurrency={setCurrency}
            doesExcludeTax={doesExcludeTax}
            setDoesExcludeTax={setDoesExcludeTax}
          />
        );
      case "team":
        return <TeamInfoSection members={members} setMembers={setMembers} />;
      default:
        return null;
    }
  }

  /*
  const handleClick = () => {
    const nextStep =
      stepsArr.findIndex((step) => step.value === activeView) + 1;
    if (nextStep < stepsArr.length) {
      setActiveView(stepsArr[nextStep].value);
    } else {
      alert("Done");
    }
  }; */
  return (
    <div
      style={{ left: 0, transition: "all 1s" }}
      className="z-20 w-screen md:h-screen overflow-auto bg-white"
    >
      <div className="md:h-screen flex overflow-auto">
        <div className="hidden md:flex flex-col justify-center items-center w-1/2 bg-[#984FBF]">
          <img className="w-2/3 max-w-xl" src={contractor} alt="contractor" />
          <div className="max-w-xl mt-10">
            <h1 className="text-white text-3xl font-bold">
              The smarter way to scope your work
            </h1>
          </div>
        </div>
        <div className="flex flex-col justify-center w-full md:w-1/2 pb-10 pt-5 px-10 md:my-auto min-h-screen md:max-h-[800px]">
          <div className="flex justify-end">
            {/*
            <div>
              <ButtonText
                handleClick={() => handleCompleteOnboarding([])}
                bg="accent"
                message="Skip onboarding"
              />
            </div>
            */}
          </div>
          {invites.length > 0 ? (
            <InviteView
              invite={invites[0]}
              handleAcceptInvite={handleAcceptInvite}
            />
          ) : (
            <>
              <div className="mt-10">
                <Logo />
              </div>
              <Steps
                steps={stepsArr}
                handleSetStep={handleSetStep}
                currentStep={activeView}
              />
              <Tagline tagline="Let's create your profile" />
              <div className="flex-1">{getInputContent(activeView)}</div>
              <div className="w-full">
                <Button
                  handleClick={() => handleClick()}
                  width="w-full"
                  message={buttonMessage}
                  bg="tertiary"
                  disabled={password !== "scopeit20"}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const InviteView = ({ invite, handleAcceptInvite }) => {
  const acceptObj = {
    inviteId: invite.id,
    workspaceId: invite.workspaceId,
    type: invite.type,
    workspaceTitle: invite.workspaceTitle,
  };
  return (
    <ActionPanel
      key={invite.id}
      heading={`You've been invited to join ${invite.workspaceTitle} 🎉`}
      message={`Amazing news! ${invite.inviter.name} wants you to join ${invite.workspaceTitle}.`}
      action={"Accept Invite"}
      reject={"Reject"}
      handleClick={() => handleAcceptInvite(acceptObj)}
    />
  );
};

const Logo = () => {
  return (
    <div className="flex justify-start w-full items-start">
      <img className="h-12 w-auto" src={logo} alt="Scopey" />
      <span className="sr-only">Scopey</span>
    </div>
  );
};

const Tagline = ({ tagline }) => {
  return (
    <div className="flex justify-start w-full items-start mb-10">
      <h3 className="text-lg text-gray-700 font-bold">{tagline}</h3>
    </div>
  );
};

const UserInfoSection = ({
  industry,
  setIndustry,
  goals,
  handleToggleGoal,
}) => {
  return (
    <div>
      <div>
        <InputSelection
          labelTextStyle="text-left font-bold text-lg text-gray-600"
          options={industryOptions}
          label="What is your industry?"
          width={"w-full"}
          value={industry}
          setValue={setIndustry}
        />
      </div>
      <div className="mt-5">
        <InputCheckbox
          inputs={goalOptions(goals, handleToggleGoal)}
          legend="What are your goals?"
        />
      </div>
    </div>
  );
};

const UserPasswordSection = ({ password, setPassword }) => {
  return (
    <div className="">
      <p className="text-sm text-gray-600 mx-auto mb-10 text-left">
        For the time being, Scopey is password protected. Please enter the
        Scopey password in order to progress creating your account.
      </p>
      <InputSingle
        labelTextStyle="text-left font-bold text-lg text-gray-600"
        options={industryOptions}
        label="Scopey password"
        width={"w-full"}
        value={password}
        setValue={setPassword}
      />
    </div>
  );
};

const WorkspaceInfoSection = ({
  workspaceTitle,
  setWorkspaceTitle,
  currency,
  setCurrency,
}) => {
  const [query, setQuery] = useState("");
  return (
    <div>
      <div>
        <InputSingle
          labelTextStyle="text-left font-bold text-lg text-gray-600"
          options={industryOptions}
          label="Your company name"
          width={"w-full"}
          value={workspaceTitle}
          setValue={setWorkspaceTitle}
        />
      </div>
      <div className="mt-5">
        <InputCombo
          labelTextStyle="text-left font-bold text-lg text-gray-600"
          label={"Start typing your currency"}
          query={query}
          setQuery={setQuery}
          options={currencyOptions}
          legend="Start typing currency"
          isCustomPossible={false}
          value={currency}
          handleSelect={setCurrency}
        />
      </div>
      <div>
        <p className="text-gray-800 text-sm text-left mt-2">
          Currently, we highly reccommend that all prices in Scopey should be
          stated
          <span className="font-bold text-red-500 ml-1">excluding</span> tax.
        </p>
      </div>
    </div>
  );
};

const TeamInfoSection = ({ members, setMembers }) => {
  const [email, setEmail] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (members.includes(email)) {
      alert("Email already added");
    }
    if (email.trim() !== "") {
      setMembers([...members, email]);
      setEmail("");
    }
  };

  const handleRemove = (email) => {
    setMembers(members.filter((member) => member !== email));
  };

  const remainingLicenses = 2 - members.length;
  const remainingMessageNumber =
    remainingLicenses === 0 ? "no" : remainingLicenses;
  const remainingLicenseMessage =
    remainingLicenses === 1 ? "license" : "licenses";
  const message = `You have ${remainingMessageNumber} ${remainingLicenseMessage} remaining`;

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)} className="flex items-end">
        <div className={`flex-1 mr-1`}>
          <InputSingle
            isRequired
            type="email"
            labelTextStyle="text-left font-bold text-lg text-gray-600"
            options={industryOptions}
            label={message}
            width={"w-full"}
            value={email}
            setValue={setEmail}
          />
        </div>
        <div>
          <Button
            disabled={remainingLicenses < 1}
            type="submit"
            message="Add"
            bg="accent"
          />
        </div>
      </form>
      <div className="text-left">
        <span className="text-xs text-left text-gray-500">
          You can add more team members later
        </span>
      </div>
      <ul className="flex mt-2 space-x-2">
        {members.map((member) => (
          <li className="">
            <Pill
              handleClick={() => handleRemove(member)}
              type="members"
              isUpper={false}
              message={member}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const industryOptions = [
  { value: "Graphic Design", label: "Graphic Design" },
  { value: "Branding", label: "Branding" },
  { value: "Web Development", label: "Web Development" },
  { value: "Software Development", label: "Sofware Development" },
  { value: "AR/VR", label: "AR/VR" },
  { value: "UI/UX", label: "UI/UX" },
  { value: "Writing", label: "Writing" },
  { value: "Architecture", label: "Architecture" },
  { value: "Professional Services", label: "Professional Services" },
  { value: "Other", label: "Other" },
];

const goalOptions = (goals, handleToggleGoal) => [
  {
    name: "Increase revenue",
    label: "Increase revenue",
    value: goals.includes("Increase revenue"),
    setValue: () => handleToggleGoal("Increase revenue"),
  },
  {
    name: "Streamline processes",
    label: "Streamline processes",
    value: goals.includes("Streamline processes"),
    setValue: () => handleToggleGoal("Streamline processes"),
  },
  {
    name: "Upsell services & products",
    label: "Upsell services & products",
    value: goals.includes("Upsell services & products"),
    setValue: () => handleToggleGoal("Upsell services & products"),
  },
  {
    name: "Manage change requests",
    label: "Manage change requests",
    value: goals.includes("Manage change requests"),
    setValue: () => handleToggleGoal("Manage change requests"),
  },
  {
    name: "Manage team",
    label: "Manage team",
    value: goals.includes("Manage team"),
    setValue: () => handleToggleGoal("Manage team"),
  },
  {
    name: "Clarity of scope with client",
    label: "Clarity of scope with client",
    value: goals.includes("Clarity of scope with client"),
    setValue: () => handleToggleGoal("Clarity of scope with client"),
  },
  {
    name: "Create detailed scopes of work",
    label: "Create detailed scopes of work",
    value: goals.includes("Create detailed scopes of work"),
    setValue: () => handleToggleGoal("Create detailed scopes of work"),
  },
  {
    name: "Stop losing money on projects",
    label: "Stop losing money on projects",
    value: goals.includes("Stop losing money on projects"),
    setValue: () => handleToggleGoal("Stop losing money on projects"),
  },
  {
    name: "Save time",
    label: "Save time",
    value: goals.includes("Save time"),
    setValue: () => handleToggleGoal("Save time"),
  },
  {
    name: "Other",
    label: "Other",
    value: goals.includes("Other"),
    setValue: () => handleToggleGoal("Other"),
  },
];

export const currencyOptions = [
  { id: "USD", title: "USD ($)", symbol: "$" },
  { id: "CAD", title: "CAD ($)", symbol: "$" },
  { id: "EUR", title: "EUR (€)", symbol: "€" },
  { id: "GBP", title: "GBP (£)", symbol: "£" },
  { id: "AUD", title: "AUD ($)", symbol: "$" },
  { id: "NZD", title: "NZD ($)", symbol: "$" },
  { id: "T&M", title: "T&M (HRS)", symbol: "h"}
];

export const yesNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];
