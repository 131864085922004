import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import InputTextarea from "../inputs/InputTextarea";
import Button from "../buttons/Button";
import InputSingle from "../inputs/InputSingle";
import { getTwoDigitNumber } from "../../utils/getCode";
import { LabelSection } from "../card/CardFull";
import InputCheckboxSingle from "../inputs/InputCheckboxSingle";

export default function ModalValueAdd(props) {
  const { isOpen, setIsOpen, handleCreate, valueAdds, labels, workspaceId } =
    props;
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState(0);
  const [max, setMax] = useState(0);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [itemLabels, setItemLabels] = useState([]); //labels are needed for combo
  const [labelQuery, setLabelQuery] = useState("");
  const [labelValue, setLabelValue] = useState("");
  const [isRecurring, setIsRecurring] = useState(false);

  const clearState = () => {
    setIsOpen(false);
    setTitle("");
    setCost(0);
    setMax(0);
    setDescription("");
    setItemLabels([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const ref = `VA-${getTwoDigitNumber(valueAdds.length + 1)}`;
    const obj = {
      title,
      description,
      cost,
      max,
      ref,
      attachmentLink: link,
      labels: itemLabels,
      isRecurring,
    };
    return handleCreate(obj).then(() => clearState());
  };

  const isDisabled = title.trim() === "";

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={clearState}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center pt-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white text-left shadow-xl transition-all w-full md:max-w-xl sm:my-8 sm:max-w-sm md:p-4">
                <form
                  disabled={isDisabled}
                  onSubmit={(e) => handleSubmit(e)}
                  className="flex h-full flex-col divide-y divide-gray-200 bg-none"
                >
                  <div className="h-0 flex-1 overflow-y-auto">
                    <div className="bg-slate-100 px-4 py-6 sm:px-6 rounded">
                      <div className="flex items-center justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          New Service
                        </Dialog.Title>
                      </div>
                      <div className="">
                        <p className="text-sm text-gray-500">
                          Your new Service will be saved to your library
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-between">
                      <div className="divide-y divide-gray-200 px-4 sm:px-6">
                        <div className="space-y-6 pt-6 pb-5">
                          <InputSingle
                            isRequired
                            label="Name of Optional Service"
                            name="value-add-name"
                            placeholder="Name your Optional Service"
                            value={title}
                            setValue={setTitle}
                          />
                          <InputTextarea
                            label="Description"
                            name="description"
                            placeholder="Your description should make it easy for your client to understand exactly what is included in this Optional Service"
                            value={description}
                            setValue={setDescription}
                          />
                          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-1">
                            <div className="sm:flex-1">
                              <InputSingle
                                isRequired
                                label="Quote"
                                name="value-add-cost"
                                placeholder="Specify the default cost"
                                type="number"
                                value={cost}
                                setValue={setCost}
                              />
                            </div>
                            <div className="sm:flex-1">
                              <InputSingle
                                label="Max Quote"
                                name="value-add-max"
                                placeholder="Specify the default max"
                                type="number"
                                value={max}
                                setValue={setMax}
                              />
                            </div>
                          </div>
                          <LabelSection
                            labels={labels ?? []}
                            confirmedLabels={[]}
                            itemLabels={itemLabels}
                            setItemLabels={setItemLabels}
                            setLabelQuery={setLabelQuery}
                            labelQuery={labelQuery}
                            labelValue={labelValue}
                            setLabelValue={setLabelValue}
                            workspaceId={workspaceId}
                          />
                          <InputSingle
                            label="Link to shared drive"
                            name="link"
                            placeholder="Add a link to an example in a shared drive"
                            value={link}
                            setValue={setLink}
                          />
                          <InputCheckboxSingle
                            value={isRecurring}
                            setValue={() => setIsRecurring(!isRecurring)}
                            label="Make this Optional Service recurring."
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 justify-end px-4 py-4">
                    <button
                      type="button"
                      className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-tertiary-500 focus:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Cancel
                    </button>
                    <div className="flex">
                      <div>
                        <Button
                          disabled={isDisabled}
                          bg="tertiary"
                          message="Submit"
                          type="submit"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
