import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import ScopeDashboardLoading from "./components/loading/LoadingScopeDashboard";
import JoinPage from "./pages/join-page/JoinPage";
import LoginPage from "./pages/login-page/LoginPageSplit";
import ProjectDashboard from "./pages/project-dashboard/ProjectDashboard";
import ScopeDashboard from "./pages/scope-dashboard/ScopeDashboardData";
import CreatedScopePage from "./pages/scope-page/CreatedScopePage";
import UserDashboard from "./pages/user-dashboard/UserDashboard";
import WorkspaceDashboard from "./pages/workspace-dashboard/WorkspaceDashboardData";
import { UserContext } from "./store/user-context";
import ForgotPasswordPage from "./pages/login-page/ForgotPasswordPage";

import { hotjar } from "react-hotjar";
hotjar.initialize("3389072");

function App() {
  const { authLoading, user } = useContext(UserContext);
  console.log(user);
  return authLoading ? (
    getLoadingScreen("scope")
  ) : (
    <div className="App min-full-height font-cabin text-[#434242]">
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user" element={<UserDashboard />} />
        <Route
          path="/workspace/:workspaceId/:view"
          element={user ? <WorkspaceDashboard /> : <LoginPage />}
        />
        <Route
          path="/scope/:projectId/:workspaceId"
          element={user ? <ScopeDashboard /> : <LoginPage />}
        />
        <Route
          path="/created-scope/:scopeId/:projectId/:workspaceId"
          element={user ? <CreatedScopePage /> : <LoginPage />}
        />
        <Route
          path="workspace/:workspaceId/project/:projectId"
          element={user ? <ProjectDashboard /> : <LoginPage />}
        />
        <Route path="join/:projectId" element={<JoinPage />} />
        <Route path="/" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      </Routes>
    </div>
  );
}

export default App;

//<EventListener target="window" onKeyDown={(e) => handleKeyDown(e)} />

const getLoadingScreen = (page) => {
  switch (page) {
    case "scope":
      return <ScopeDashboardLoading />;
    default:
      return <p>Loading</p>;
  }
};
